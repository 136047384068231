import React, { useState, useContext, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext'
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Spinner } from 'react-bootstrap';
import AdminNavigationPanel from '../../components/AdminNavigationPanel';
import { SEARCHALLUSER_URL, USER_URL } from '../../services/Constants';
import { faEdit, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavbarAdmin from '../../components/NavbarAdmin';
import { handleChangeAdminStatus } from '../../services/ApiServices';

export default function AdminHome() {

  let {
    user,
    authTokens,
    userList,
    getUserList,
    notifyError,
    notifyAuthenticateError,
    notifyCustomSuccessMessage
  } = useContext(AuthContext)

  const navigate = useNavigate();

  // State variable used for loading of page

  const [spinnerLoading, setSpinnerLoading] = useState(false);

  // State variable of User Personal Information

  const [userPersonalData, setUserPersonalData] = useState([]);
  const [searchUserInfo, setSearchUserInfo] = useState([]);

  // function responsible for handling navigation with params

  const navigateToSearchDataWithParams = (item) => {
    if (item.account_type === 'user')
      navigate(`/user/viewprofile?user_id=${item.id}`);
    else
      navigate(`/institute/viewprofile?institute_id=${item.id}`);
  };

  // on change function

  const handleOnClickSearchUserInfo = async (e) => {
    // setSearchUserInfo(e.target.value);
    setSpinnerLoading(true);
    let COMPLETE_URL;
    let apiEndPoint;
    if (searchUserInfo !== '') {
      apiEndPoint = 'full_name=' + searchUserInfo;
      COMPLETE_URL = SEARCHALLUSER_URL + apiEndPoint;
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      if (status === 200) {
        const filteredData = await data;
        console.log('list of filtered user:', filteredData);
        setUserPersonalData(filteredData);
      } else {
        setUserPersonalData([]);
      }
    } else {
      setUserPersonalData([]);
    }
    setSpinnerLoading(false);
  }

  // Function to toggle admin status
  const toggleAdminStatus = async (id, adminStatus) => {
    setUserPersonalData((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, is_superuser: !user.is_superuser } : user
      )
    );
    let response;
    response = handleChangeAdminStatus(id, !adminStatus, authTokens);
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Status changed successfully.');
    } else {
      notifyError();
    }
  };

  const allUserList = userPersonalData.map((item) => {
    if (item.account_type === 'user')
      return (
        <tr key={item.id}>
          <td onClick={() => navigateToSearchDataWithParams(item)} className='table-item'>
            {item.first_name + ' ' + item.last_name}
          </td>
          <td>{item.email}</td>
          <td>{item.contact_no}</td>
          <td>{item.dob}</td>
          <td>{item.sport_type}</td>
          <td>
            <Form.Check
              type="switch"
              id={`admin-switch-${item.id}`}
              checked={item.is_superuser}
              onChange={() => toggleAdminStatus(item.id, item.is_superuser)}
              label={item.is_superuser ? 'Admin' : 'Not Admin'}
            />
          </td>
        </tr>
      );
  })

  return (
    <>
      <NavbarAdmin />
      <Container>
        <Row>
          <AdminNavigationPanel currentUserName={user.first_name + ' ' + user.last_name} />

          <Col xs={9}>
            <Card className='mt-1'>
              <Card.Body>
                <h2 className='text-center'>Users</h2>
                {/* <div style={{ height: '400px', overflowY: 'scroll' }}> */}
                <Card>
                  <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>
                    <div className='d-flex justify-content-between'>
                      <div>
                        List of Users
                      </div>
                      <div>
                        <Form.Group as={Col}>
                          <Stack direction="horizontal" gap={2}>
                            <Form.Control
                              className='input'
                              type="text"
                              size='lg'
                              placeholder="Enter the user's name or email"
                              value={searchUserInfo}
                              // onChange={handleOnChangeSearchUserInfo}
                              onChange={(e) => setSearchUserInfo(e.target.value)}
                            />
                            <Button variant="primary" size="lg" onClick={handleOnClickSearchUserInfo}>
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                // color='green'
                                // size = '2x'
                                style={{ cursor: 'pointer', marginRight: 5 }}
                              />
                            </Button>
                          </Stack>
                        </Form.Group>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body style={{ height: '360px', overflowY: 'scroll' }}>
                    {/* <div style={{ height: '400px', overflowY: 'scroll' }}> */}
                    <Table striped responsive bordered hover>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Contact No.</th>
                          <th>Date of Birth</th>
                          <th>Sport Type</th>
                          <th>Is Admin</th>
                        </tr>
                      </thead>
                      {spinnerLoading ?
                        <tbody>
                          <tr>
                            <td colSpan={6} className='text-center'>
                              <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                              />
                            </td>
                          </tr>
                        </tbody> :
                        <tbody>
                          {userPersonalData.length > 0 ?
                            allUserList :
                            <tr>
                              <td colSpan={6} className='text-center'>
                                No data found
                              </td>
                            </tr>
                          }
                        </tbody>
                      }
                    </Table>
                    {/* </div> */}
                  </Card.Body>
                </Card>
                {/* </div> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer autoClose={2000} />
    </>
  )
}

import { createContext, useContext, useState } from "react";
import AuthContext from "./AuthContext";
import { NETWORKCONNECT_URL, NETWORKREQUEST_URL } from "../services/Constants";
import 'react-toastify/dist/ReactToastify.css';

export const NetworkContext = createContext();

export const NetworkContextProvider = ({ children }) => {

    const { currentUser,notifyError } = useContext(AuthContext);

    const [request, setRequest] = useState([]);
    const [pendingRequest, setPendingRequest] = useState([]);
    const [connections, setConnections] = useState([]);
    const [connectedRequest, setConnectedRequest] = useState([]);

    const getNetworkConnectRequest = async () => {
        // e.preventDefault();
        // console.log('Network Request List');
        let response = fetch(`${NETWORKREQUEST_URL}/${currentUser.user_id}/request_list/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        if (status === 200) {
            const networkRequest = (await data)
            // console.log('network request: ',networkRequest)
            setRequest(networkRequest)
            const filteredPendingRequest = networkRequest.filter(item => item.status === 'pending');
            setPendingRequest(filteredPendingRequest);
        } else {
            // alert('something went wrong!')
            notifyError()
        }
    }

    const getNetworkConnections = async () => {
        // e.preventDefault();
        // console.log('Network connections');
        let response = fetch(`${NETWORKCONNECT_URL}/${currentUser.user_id}/request_list/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        if (status === 200) {
            const networkConnections = (await data)
            // console.log('network connections: ',networkConnections)
            setConnections(networkConnections)
            const filteredConnectedRequest = networkConnections.filter(item => item.status === 'connected');
            // console.log('network connections: ',filteredConnectedRequest)
            setConnectedRequest(filteredConnectedRequest);
        } else {
            // alert('something went wrong!')
            notifyError()
        }
    }

    const contextData = {
        getNetworkConnectRequest: getNetworkConnectRequest,
        getNetworkConnections: getNetworkConnections,
        networkRequest: request,
        pendingNetworkRequest: pendingRequest,
        connectedNetworkRequest: connectedRequest,
        networkConnections: connections
    }
  
    return (
      <NetworkContext.Provider value={ contextData }>
        {children}
      </NetworkContext.Provider>
    );
  };
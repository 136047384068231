import React, { useContext, useEffect, useState } from 'react'
import { doc, onSnapshot } from "firebase/firestore";
import AuthContext from "../context/AuthContext";
import { ChatContext } from '../context/ChatContext';
import { db } from '../utils/Firebase';

export const Contact = ({ param1 }) => {

    console.log('Contactar')

    const [chats, setChats] = useState([]);

    const { currentUser } = useContext(AuthContext);
    const { dispatch } = useContext(ChatContext);

    useEffect(() => {
        const getChats = () => {
            const unsub = onSnapshot(doc(db, "userChats", currentUser.username), (doc) => {
                setChats(doc.data());
                console.log('data:', doc.data());
            });

            return () => {
                unsub();
            };
        };

        currentUser.username && getChats();
    }, [currentUser.username]);

    const handleSelect = (u) => {
        console.log(u);
        dispatch({ type: "CHANGE_USER", payload: u });
    };

    // console.log(Object.entries(chats)?.sort((a, b) => b[1].date - a[1].date))

    // console.log('param1:', param1);

    if (param1) {
        Object.entries(chats)?.sort((a, b) => b[1].date - a[1].date).forEach((chat) => (
            param1 === chat[1].userInfo.uid ?
                // console.log('userinfo', chat[1].userInfo)
                // handleSelect(chat[1].userInfo)
                dispatch({ type: "CHANGE_USER", payload: chat[1].userInfo })
                : null
        ))
        return (
            <div id="contacts">
                <ul>
                    {Object.entries(chats)?.sort((a, b) => b[1].date - a[1].date).map((chat) => (
                        param1 === chat[1].userInfo.uid ?
                            // dispatch({ type: "CHANGE_USER", payload: chat[1].userInfo })
                            // handleSelect(chat[1].userInfo.uid)
                            (<li className="contact" key={chat[0]}>
                                <div className="wrap">
                                    <span className="contact-status"></span>
                                    <img src={chat[1].userInfo.photoURL} alt="" />
                                    <div className="meta">
                                        <p className="name">{chat[1].userInfo.name}</p>
                                        <p className="preview">{chat[1].lastMessage?.text}</p>
                                    </div>
                                </div>
                            </li>)
                            : null
                    ))}
                </ul>
            </div>
        )
    } else {
        return (
            <div id="contacts">
                <ul>
                    {chats && Object.entries(chats)?.sort((a, b) => b[1].date - a[1].date).map((chat) => (
                        <li className="contact" key={chat[0]} onClick={() => handleSelect(chat[1].userInfo)}>
                            <div className="wrap">
                                <span className="contact-status"></span>
                                <img src={chat[1].userInfo.photoURL} alt="" />
                                <div className="meta">
                                    <p className="name">{chat[1].userInfo.name}</p>
                                    <p className="preview">{chat[1].lastMessage?.text}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

import React from "react";
import './Style.css';
import SearchContact from "./SearchContacts";
import { Contact } from "./Contact";
import { useLocation } from 'react-router-dom';
// import { collection, query, where, getDocs } from "firebase/firestore";
// import { db } from "../utils/Firebase";
// import { collection, query, where } from "firebase/firestore";

const SidePanel = (props) => {

    // const [username, setUsername] = useState('');
    // const [user, setUser] = useState(null);
    // const [err, setErr] = useState(false);

    // const handleSearch = async () => {
    //     const usersRef = collection(db, "users");
    //     const q = query(usersRef, where("name", "==", username));
    //     try {
    //         const querySnapshot = await getDocs(q);
    //         querySnapshot.forEach((doc) => {
    //             setUser(doc.data())
    //         });
    //     } catch (err) {
    //         setErr(true);
    //     }
    // }

    // const handleKey = (e) => {
    //     e.code === 'Enter' && handleSearch();
    // };

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const param1 = params.get('param1');
    // console.log(param1)

    return (
        <div id="sidepanel">
            <div id="profile">
                <div className="wrap">
                    <img id="profile-img" src={props.userInfo.profile_photo} className="online" alt="" />
                    <p>{props.userInfo.first_name} {props.userInfo.last_name}</p>
                    <i className="fa fa-chevron-down expand-button" aria-hidden="true"></i>
                    <div id="status-options">
                        <ul>
                            <li id="status-online" className="active"><span className="status-circle"></span> <p>Online</p></li>
                            <li id="status-away"><span className="status-circle"></span> <p>Away</p></li>
                            <li id="status-busy"><span className="status-circle"></span> <p>Busy</p></li>
                            <li id="status-offline"><span className="status-circle"></span> <p>Offline</p></li>
                        </ul>
                    </div>
                    <div id="expanded">
                        <label htmlFor="twitter"><i className="fa fa-facebook fa-fw" aria-hidden="true"></i></label>
                        <input name="twitter" type="text" value="mikeross" />
                        <label htmlFor="twitter"><i className="fa fa-twitter fa-fw" aria-hidden="true"></i></label>
                        <input name="twitter" type="text" value="ross81" />
                        <label htmlFor="twitter"><i className="fa fa-instagram fa-fw" aria-hidden="true"></i></label>
                        <input name="twitter" type="text" value="mike.ross" />
                    </div>
                </div>
            </div>
            <SearchContact param1={param1}/>
            <Contact param1={param1}/>
            <div id="bottom-bar">
                <button id="addcontact"><i className="fa fa-user-plus fa-fw" aria-hidden="true"></i> <span>Add contact</span></button>
                <button id="settings"><i className="fa fa-cog fa-fw" aria-hidden="true"></i> <span>Settings</span></button>
            </div>
        </div>
    )
}

export default SidePanel;
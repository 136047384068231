// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// import { getFirestore } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCzlUlruoiN41ou6-dBZt_cmrk41n_vmk8",
  authDomain: "athlete-f772d.firebaseapp.com",
  projectId: "athlete-f772d",
  storageBucket: "athlete-f772d.appspot.com",
  messagingSenderId: "1014960776137",
  appId: "1:1014960776137:web:46e620a1ba3e9bf921fe18"
};

// Initialize Firebase
 export const app = initializeApp(firebaseConfig);
 export const storage = getStorage();
 export const db = getFirestore(app);
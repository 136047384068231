import React, { useContext, useEffect, useRef } from 'react';
import AuthContext from '../context/AuthContext';
import { ChatContext } from '../context/ChatContext';

export const Message = ({ message }) => {
    const { currentUser, userPersonalData, userPersonalInfo } = useContext(AuthContext);
    const { data } = useContext(ChatContext);

    const ref = useRef();

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
        userPersonalInfo();
    }, [message]);

    return (
        <div className="messages"
            ref={ref}
        >
            <ul id="chat-log">
                { }
                <li className={`${message.senderId === currentUser.username ? "sent" : "replies"}`}>
                    <img
                        src={
                            message.senderId === currentUser.username
                                ? userPersonalData.profile_photo_url
                                : data.user.photoURL
                        }
                        alt="" />
                    <p>{message.text}</p>
                    {message.img && <img src={message.img} alt="" style={{ width: '50%', height: '50%', borderRadius: 10, }} />}
                </li>
                {/* <li className="replies">
                    <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
                    <p>When you're backed against the wall, break the god damn thing down.</p>
                </li> */}
            </ul>
        </div>
        // <div
        //     ref={ref}
        //     className={`message ${message.senderId === currentUser.username && "owner"}`}
        // >
        //     <div className="messageInfo">
        //         <img
        //             src={
        //                 message.senderId === currentUser.username
        //                     ? userPersonalData.photoURL
        //                     : data.user.photoURL
        //             }
        //             alt=""
        //         />
        //         <span>just now</span>
        //     </div>
        //     <div className="messageContent">
        //         <p>{message.text}</p>
        //         {message.img && <img src={message.img} alt="" />}
        //     </div>
        // </div>
    )
}

import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEdit, faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import AuthContext from '../../context/AuthContext'
import Navbar from '../../components/Navbar'
import ProfileBuilderInstitutionNavbar from '../../components/ProfileBuilderInstitutionNavbar';
import { CLUB_URL, INSTITUITION_INFO_URL, INSTITUITION_URL, INSTITUITIONHISTORY_URL, INSTITUITIONHISTORYCREATE_URL, INSTITUITIONHISTORYUPDATE_URL, INSTITUITIONLICENSE_URL, INSTITUITIONLICENSECREATE_URL, INSTITUITIONLICENSEUPDATE_URL, INSTITUITIONOFFICEBEARER_URL, LEAGUES_URL, SPORTLICENSE_URL } from '../../services/Constants';

export default function EditInstituitionInfoProfile() {

    let {
        user,
        authTokens,
        notifyRegistrationPersonalInfo,
        notifyCustomSuccessMessage,
        notifyError,
        notifyAuthenticateError,
        notifyCustomErrorMessage
    } = useContext(AuthContext)

    const navigate = useNavigate();

    const [key, setKey] = useState('general');

    // State variable of search user profile in Navbar

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    // State variable to manage user data

    const [userPersonalData, setUserPersonalData] = useState('')

    // State variable to manage sport profile type

    const [selectedSwitchOption, setSelectedSwitchOption] = useState(false);

    // State variable for managing lov filteration

    const [isLicenseLOVloading, setIsLicenseLOVLoading] = useState(false);
    const [searchLicenseLOVData, setSearchLicenseLOVData] = useState([]);
    const [isLeagueLOVloading, setIsLeagueLOVLoading] = useState(false);
    const [searchLeagueLOVData, setSearchLeagueLOVData] = useState([]);

    // State variable for managing club info

    const [clubInfo, setClubInfo] = useState('')
    const [clubId, setClubId] = useState(null)
    const [website, setWebsite] = useState('')
    const [clubLicense, setClubLicense] = useState([])
    const [clubLicenseId, setClubLicenseId] = useState(null)
    const [optionsLicense, setOptionsLicense] = useState([]);
    const [licenseName, setLicenseName] = useState('')
    const [licenseId, setLicenseId] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null);
    const [clubHistory, setClubHistory] = useState([])
    const [clubHistoryId, setClubHistoryId] = useState(null)
    const [optionsLeague, setOptionsLeague] = useState([]);

    const [licenseError, setLicenseError] = useState('');
    const [selectedFileError, setSelectedFileError] = useState('');
    const [fileTypeError, setFileTypeError] = useState('');

    const [fromYear, setFromYear] = useState('')
    const [toYear, setToYear] = useState('')
    const [leagueId, setLeagueId] = useState(null)
    const [leagueName, setLeagueName] = useState('')
    const [gamesPlayed, setGamesPlayed] = useState('')
    const [gamesWon, setGamesWon] = useState('')
    const [gamesLost, setGamesLost] = useState('')
    const [gamesTied, setGamesTied] = useState('')
    const [points, setPoints] = useState('')
    const [position, setPosition] = useState('')
    const [tournament, setTournament] = useState('')
    const [keyAchievements, setKeyAchievements] = useState('')

    const [fromYearError, setFromYearError] = useState('');
    const [toYearError, setToYearError] = useState('');
    const [leagueNameError, setLeagueNameError] = useState('');

    // State variable to manage institute officials

    const [personName, setPersonName] = useState('');
    const [personNameError, setPersonNameError] = useState('');
    const [positionOfficial, setPositionOfficial] = useState('');
    const [positionOfficialError, setPositionOfficialError] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [departmentNameError, setDepartmentNameError] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [clubOfficials, setClubOfficials] = useState([])
    const [clubOfficialId, setClubOfficialId] = useState(null)

    // State varialabe to manage modal

    const [showEditGeneralModal, setShowEditGeneralModal] = useState(false)
    const [showAddLicenseModal, setShowAddLicenseModal] = useState(false)
    const [showEditLicenseModal, setShowEditLicenselModal] = useState(false)
    const [showAddCareerHistoryModal, setShowAddCareerHistoryModal] = useState(false)
    const [showEditCareerHistoryModal, setShowEditCareerHistoryModal] = useState(false)
    const [showAddOfficialsModal, setShowAddOfficialsModal] = useState(false)
    const [showEditOfficialsModal, setShowEditOfficialsModal] = useState(false)

    // State variable to manage form validation

    const [validatedClubInfo, setValidatedClubInfo] = useState(false);
    const [validatedLicense, setValidatedLicense] = useState(false);
    const [validatedCareerHistory, setValidatedCareerHistory] = useState(false);
    const [validatedOfficials, setValidatedOfficials] = useState(false);

    // function responsible of search data with navigation

    const navigateToSearchDataWithParams = (item) => {
        console.log('navigateToSearchDataWithParams');
        navigate(`/user/viewprofile?user_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    // GET API ENDPOINTS

    const getClubLicense = async () => {
        // e.preventDefault();
        console.log('get license');
        let response = fetch(`${SPORTLICENSE_URL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        if (status === 200) {
            const licenseData = (await data);
            console.log('licenseData: ', licenseData);
            // setCountryInfo(licenseData);
            const processedOptions = licenseData.map(item => ({
                label: item.license_name,
                value: item.id
            }));
            console.log('processed Options:', processedOptions)
            setOptionsLicense(processedOptions);
        } else {
            notifyError()
        }
    }

    const getLeagues = async () => {
        // e.preventDefault();
        console.log('get leagues');
        let response = fetch(`${LEAGUES_URL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        if (status === 200) {
            const leagueData = (await data);
            //   console.log('leagueData: ', leagueData);
            // setLeaguesInfo(leagueData);
            const processedOptions = leagueData.map(item => ({
                label: item.league_name,
                value: item.id
            }));
            //   console.log('processed Options:', processedOptions)
            setOptionsLeague(processedOptions);
        } else {
            notifyError()
        }
    }

    const getClubInfo = async (id) => {
        let response = fetch(`${INSTITUITION_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        const status = (await response).status
        const club = (await data)
        // console.log('club info:', club)
        if (status === 200) {
            setClubId(id)
            setWebsite(club.website)
        } else {
            notifyError()
        }
    }

    const getClubLicenseInfo = async (id) => {
        setClubLicenseId(id)
        let response = fetch(`${INSTITUITIONLICENSE_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        console.log('response:', response)
        const data = (await response).json()
        console.log('data:', data)
        const club = (await data)
        console.log('club LICENSE info:', club)
        const status = (await response).status
        if (status === 200) {
            setLicenseId(club.license_id)
            setLicenseName(club.document_name)
            // setSelectedFile(coach.certificate)
        } else {
            notifyError()
        }
    }

    const getClubOfficialInfo = async (id) => {
        let response = fetch(`${INSTITUITIONOFFICEBEARER_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const club = (await data)
        // console.log('club Official info:', club)
        const status = (await response).status
        if (status === 200) {
            setClubOfficialId(id)
            setPersonName(club.name)
            setPositionOfficial(club.position)
            setDepartmentName(club.department)
            setEmailAddress(club.email)
            setContactNo(club.phone_number)
        } else {
            notifyError()
        }
    }

    const getClubCareerHistoryInfo = async (id) => {
        let response = fetch(`${INSTITUITIONHISTORY_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const club = (await data)
        // console.log('club Official info:', club)
        const status = (await response).status
        if (status === 200) {
            setClubHistoryId(id)
            setToYear(club.to_year)
            setFromYear(club.from_year)
            setLeagueName(club.league_name)
            setLeagueId(club.league_id)
            setGamesPlayed(club.games_played)
            setGamesWon(club.games_won)
            setGamesLost(club.games_lost)
            setGamesTied(club.games_tied)
            setPoints(club.points)
            setPosition(club.position)
            setTournament(club.tournament)
            setKeyAchievements(club.achievement)
        } else {
            notifyError()
        }
    }

    // functions responsible for adding data in backend

    const handleAddClubVerificationDocument = async () => {
        setValidatedLicense(true);
        // setIsLoading(true);
        const formData = new FormData();
        formData.append("club_id", clubInfo.id);
        if (licenseName !== '') {
            formData.append("license_name", licenseName);
            formData.append("document_name", licenseName);
            setLicenseError('');
        } else {
            setLicenseError('Licence Name cannot be empty.');
            return;
        }
        if (licenseId !== null) {
            formData.append("license_id", licenseId);
        } else {
            formData.append("license_id", '');
        }
        if (selectedFile !== null) {
            // console.log('selectedFile', selectedFile.type);
            if (selectedFile.type === 'application/pdf') {
                // console.log('selectedFile', selectedFile.type);
                formData.append("document_file", selectedFile);
                formData.append("document_type", selectedFile.type);
                setFileTypeError('');
            } else {
                // console.log('selectedFile', selectedFile.type);
                setFileTypeError('Please select a valid PDF file.');
                return;
            }
        } else {
            // console.log('selectedFile', selectedFile.type);
            setSelectedFileError('Certificate cannot be empty.');
            return;
        }
        let response = fetch(`${INSTITUITIONLICENSECREATE_URL}`, {
            method: "POST",
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 201) {
            notifyRegistrationPersonalInfo()
            setLicenseId(null)
            setLicenseName('')
            setSelectedFile(null)
            getClubLicense()
            setValidatedLicense(false)
            setShowAddLicenseModal(false)
        } else {
            notifyError()
        }
    };

    const handleAddOfficials = async () => {
        setValidatedOfficials(true);
        const formData = new FormData();
        formData.append("club_id", clubInfo.id);
        formData.append("email", emailAddress);
        formData.append("phone_number", contactNo);
        if (personName !== '') {
            formData.append("name", personName);
            setPersonNameError('');
        } else {
            setPersonNameError('Name cannot be empty.');
            return;
        }
        if (positionOfficial !== '') {
            formData.append("position", positionOfficial);
            setPositionOfficialError('');
        } else {
            setPositionOfficialError('Position cannot be empty.');
            return;
        }
        if (departmentName !== '') {
            formData.append("department", departmentName);
            setDepartmentNameError('');
        } else {
            setDepartmentNameError('Department cannot be empty.');
            return;
        }
        let response = fetch(`${INSTITUITIONOFFICEBEARER_URL}/`, {
            method: "POST",
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 201) {
            notifyRegistrationPersonalInfo()
            setPersonName('')
            setPositionOfficial('')
            setDepartmentName('')
            setEmailAddress('')
            setContactNo('')
            setValidatedOfficials(false)
            setShowAddOfficialsModal(false)
        } else {
            notifyError()
        }
    }

    const handleAddClubCareerHistory = async (e) => {
        setValidatedCareerHistory(true);
        if (fromYear !== '') {
            setFromYearError('');
        } else {
            setFromYearError('From year cannot be empty.');
            return;
        }
        if (toYear !== '') {
            setToYearError('');
        } else {
            setToYearError('To year cannot be empty.');
            return;
        }
        if (leagueName !== '') {
            setLeagueNameError('');
        } else {
            setLeagueNameError('League name cannot be empty.');
            return;
        }
        if (gamesPlayed !== '') {
            setLeagueNameError('');
        } else {
            setLeagueNameError('League name cannot be empty.');
            return;
        }
        let data;
        if (leagueId === null && leagueName !== null) {
            data = {
                flag: 'createleague',
                from_year: fromYear,
                to_year: toYear,
                league_id: leagueId,
                league_name: leagueName,
                games_played: gamesPlayed,
                games_won: gamesWon !== '' ? gamesWon : 0,
                games_lost: gamesLost !== '' ? gamesLost : 0,
                games_tied: gamesTied !== '' ? gamesTied : 0,
                points: points !== '' ? points : 0,
                position: position,
                tournament: tournament,
                achievement: keyAchievements,
                club_id: clubInfo.id
            }
        } else if (leagueId !== null && leagueName !== null) {
            data = {
                flag: 'league',
                from_year: fromYear,
                to_year: toYear,
                league_id: leagueId,
                league_name: leagueName,
                games_played: gamesPlayed,
                games_won: gamesWon !== '' ? gamesWon : 0,
                games_lost: gamesLost !== '' ? gamesLost : 0,
                games_tied: gamesTied !== '' ? gamesTied : 0,
                points: points !== '' ? points : 0,
                position: position,
                tournament: tournament,
                achievement: keyAchievements,
                club_id: clubInfo.id
            }
        }
        console.log('data: ', data);

        axios.post(`${INSTITUITIONHISTORYCREATE_URL}`, data)
            .then(response => {
                console.log('Data posted successfully:', response.data);
                notifyRegistrationPersonalInfo()
                setFromYear('')
                setToYear('')
                setGamesPlayed('')
                setGamesLost('')
                setGamesTied('')
                setGamesWon('')
                setLeagueName('')
                setLeagueId(null)
                setKeyAchievements('')
                setPoints('')
                setPosition('')
                setTournament('')
                setValidatedCareerHistory(false)
                setShowAddCareerHistoryModal(false)
            })
            .catch(error => {
                console.error('There was an error posting the data!', error);
                notifyCustomErrorMessage('There was an error posting the data!');
            });
    }

    // function responsible for updating data in backend

    const handleUpdateGeneralInfo = async () => {
        setValidatedClubInfo(true);
        const formData = new FormData();
        formData.append("website", website);
        let response = fetch(`${INSTITUITION_URL}/${clubId}/`, {
            method: "PATCH",
            body: formData,
        });
        console.log('response:', response)
        let status = (await response).status
        // console.log('status:', status)
        let data = (await response).json()
        console.log('data:', data)
        if (status === 200) {
            notifyRegistrationPersonalInfo()
            setWebsite('')
            setValidatedClubInfo(false)
            setShowEditGeneralModal(false)
        } else {
            notifyError()
        }
    };

    const handleUpdateInstituteLicense = async () => {
        setValidatedLicense(true);
        const formData = new FormData();
        formData.append("id", clubLicenseId);
        formData.append("coach", clubInfo.id);
        if (licenseName !== '') {
            formData.append("license_name", licenseName);
            setLicenseError('');
        } else {
            setLicenseError('Licence Name cannot be empty.');
            return;
        }
        if (licenseId !== null) {
            formData.append("license_id", licenseId);
        } else {
            formData.append("license_id", '');
        }
        if (selectedFile !== null) {
            // console.log('selectedFile', selectedFile.type);
            if (selectedFile.type === 'application/pdf') {
                // console.log('selectedFile', selectedFile.type);
                formData.append("document_file", selectedFile);
                formData.append("document_type", selectedFile.type);
                setFileTypeError('');
            } else {
                // console.log('selectedFile', selectedFile.type);
                setFileTypeError('Please select a valid PDF file.');
                return;
            }
        } else {
            // console.log('selectedFile', selectedFile.type);
            setSelectedFileError('Certificate cannot be empty.');
            return;
        }
        let response = fetch(`${INSTITUITIONLICENSEUPDATE_URL}`, {
            method: "POST",
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            notifyRegistrationPersonalInfo()
            setLicenseId(null)
            setLicenseName('')
            setSelectedFile(null)
            getClubLicense()
            setValidatedLicense(false)
            setShowEditLicenselModal(false)
        } else {
            notifyError()
        }
    };

    const handleEditClubCareerHistory = async (e) => {
        setValidatedCareerHistory(true);
        if (fromYear !== '') {
            setFromYearError('');
        } else {
            setFromYearError('From year cannot be empty.');
            return;
        }
        if (toYear !== '') {
            setToYearError('');
        } else {
            setToYearError('To year cannot be empty.');
            return;
        }
        if (leagueName !== '') {
            setLeagueNameError('');
        } else {
            setLeagueNameError('League name cannot be empty.');
            return;
        }
        if (gamesPlayed !== '') {
            setLeagueNameError('');
        } else {
            setLeagueNameError('League name cannot be empty.');
            return;
        }
        let data;
        if (leagueId === null && leagueName !== null) {
            data = {
                flag: 'createleague',
                id: clubHistoryId,
                from_year: fromYear,
                to_year: toYear,
                league_id: leagueId,
                league_name: leagueName,
                games_played: gamesPlayed,
                games_won: gamesWon !== '' ? gamesWon : 0,
                games_lost: gamesLost !== '' ? gamesLost : 0,
                games_tied: gamesTied !== '' ? gamesTied : 0,
                points: points !== '' ? points : 0,
                position: position,
                tournament: tournament,
                achievement: keyAchievements
            }
        } else if (leagueId !== null && leagueName !== null) {
            data = {
                flag: 'league',
                id: clubHistoryId,
                from_year: fromYear,
                to_year: toYear,
                league_id: leagueId,
                league_name: leagueName,
                games_played: gamesPlayed,
                games_won: gamesWon !== '' ? gamesWon : 0,
                games_lost: gamesLost !== '' ? gamesLost : 0,
                games_tied: gamesTied !== '' ? gamesTied : 0,
                points: points !== '' ? points : 0,
                position: position,
                tournament: tournament,
                achievement: keyAchievements
            }
        }
        console.log('data: ', data);

        axios.post(`${INSTITUITIONHISTORYUPDATE_URL}`, data)
            .then(response => {
                console.log('Data posted successfully:', response.data);
                notifyRegistrationPersonalInfo()
                setFromYear('')
                setToYear('')
                setGamesPlayed('')
                setGamesLost('')
                setGamesTied('')
                setGamesWon('')
                setLeagueName('')
                setLeagueId(null)
                setKeyAchievements('')
                setPoints('')
                setPosition('')
                setTournament('')
                setValidatedCareerHistory(false)
                setShowEditCareerHistoryModal(false)
            })
            .catch(error => {
                console.error('There was an error posting the data!', error);
                notifyCustomErrorMessage('There was an error posting the data!');
            });
    }

    const handleEditOfficials = async () => {
        setValidatedOfficials(true);
        const formData = new FormData();
        // formData.append("club_id", clubOfficialId);
        formData.append("email", emailAddress);
        formData.append("phone_number", contactNo);
        if (personName !== '') {
            formData.append("name", personName);
            setPersonNameError('');
        } else {
            setPersonNameError('Name cannot be empty.');
            return;
        }
        if (positionOfficial !== '') {
            formData.append("position", positionOfficial);
            setPositionOfficialError('');
        } else {
            setPositionOfficialError('Position cannot be empty.');
            return;
        }
        if (departmentName !== '') {
            formData.append("department", departmentName);
            setDepartmentNameError('');
        } else {
            setDepartmentNameError('Department cannot be empty.');
            return;
        }
        let response = fetch(`${INSTITUITIONOFFICEBEARER_URL}/${clubOfficialId}/`, {
            method: "PATCH",
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            notifyRegistrationPersonalInfo()
            setPersonName('')
            setPositionOfficial('')
            setDepartmentName('')
            setEmailAddress('')
            setContactNo('')
            setValidatedOfficials(false)
            setShowEditOfficialsModal(false)
        } else {
            notifyError()
        }
    }

    // fuctions responsible for deleting data from backend

    const handleClubDocumentDelete = async (id) => {
        console.log('Handle Profile Delete function called');
        let response = fetch(`${INSTITUITIONLICENSE_URL}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // console.log('response:', response)
        let status = (await response).status
        if (status === 204) {
            notifyCustomSuccessMessage('Information deleted successfully.');
            const newList = clubLicense.filter((club) => club.id !== id);
            setClubLicense(newList)
        } else {
            notifyError()
        }
    }

    const handleClubOfficialDelete = async (id) => {
        let response = fetch(`${INSTITUITIONOFFICEBEARER_URL}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // console.log('response:', response)
        let status = (await response).status
        if (status === 204) {
            notifyCustomSuccessMessage('Information deleted successfully.');
            const newList = clubHistory.filter((club) => club.id !== id);
            setClubOfficials(newList)
        } else {
            notifyError()
        }
    }

    const handleClubHistoryDelete = async (id) => {
        let response = fetch(`${INSTITUITIONHISTORY_URL}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // console.log('response:', response)
        let status = (await response).status
        if (status === 204) {
            notifyCustomSuccessMessage('Information deleted successfully.');
            const newList = clubHistory.filter((club) => club.id !== id);
            setClubHistory(newList)
        } else {
            notifyError()
        }
    }

    // handle on Close Modal

    const handleEditGenralModalClose = () => {
        setShowEditGeneralModal(false);
        setValidatedClubInfo(false);
    }

    const handleAddLicenseModalClose = () => {
        setShowAddLicenseModal(false);
        setValidatedLicense(false);
        setLicenseName('')
        setSelectedFile(null)
    }

    const handleEditLicenseModalClose = () => {
        setShowEditLicenselModal(false);
        setValidatedLicense(false);
        setLicenseName('')
        setSelectedFile(null)
    }

    const handleAddCareerHistoryModalClose = () => {
        setShowAddCareerHistoryModal(false);
        setValidatedCareerHistory(false);
        setToYear('');
        setFromYear('');
        setLeagueId(null);
        setLeagueName('');
        setGamesPlayed('')
        setGamesWon('');
        setGamesLost('');
        setGamesTied('');
        setPoints('');
        setTournament('');
        setPosition('');
        setKeyAchievements('');
    }

    const handleEditCareerHistoryModalClose = () => {
        setShowEditCareerHistoryModal(false);
        setValidatedCareerHistory(false);
        setToYear('');
        setFromYear('');
        setLeagueId(null);
        setLeagueName('');
        setGamesPlayed('')
        setGamesWon('');
        setGamesLost('');
        setGamesTied('');
        setPoints('');
        setTournament('');
        setPosition('');
        setKeyAchievements('');
    }

    const handleAddOfficialsModalClose = () => {
        setShowAddOfficialsModal(false);
        setValidatedOfficials(false);
        setPersonName('');
        setPositionOfficial('');
        setDepartmentName('');
        setEmailAddress('');
        setContactNo('');
    }

    const handleEditOfficialsModalClose = () => {
        setShowEditOfficialsModal(false);
        setValidatedOfficials(false);
        setPersonName('');
        setPositionOfficial('');
        setDepartmentName('');
        setEmailAddress('');
        setContactNo('');
    }

    // handle onChange event

    const handleSearchLicenseFilter = (value) => {
        const res = optionsLicense.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
        console.log('res:', res);
        setSearchLicenseLOVData(res);
        setIsLicenseLOVLoading(true);
        setLicenseName(value);
        setLicenseId(null);
        if (value === "") {
            setSearchLicenseLOVData([]);
            setIsLicenseLOVLoading(false);
            setLicenseName('');
            setLicenseId(null);
        }
    }

    const handleSelectLicense = (item) => {
        setLicenseName(item.label);
        setLicenseId(item.value);
        setIsLicenseLOVLoading(false);
    }

    const handleSearchLeagueFilter = (value) => {
        const res = optionsLeague.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
        console.log('res:', res);
        setSearchLeagueLOVData(res);
        setIsLeagueLOVLoading(true);
        setLeagueName(value);
        if (value === "") {
            setSearchLeagueLOVData([]);
            setIsLeagueLOVLoading(false);
            setLeagueName('');
            setLeagueId(null);
        }
    }

    const handleSelectLeague = (item) => {
        setLeagueName(item.label);
        setLeagueId(item.value);
        setIsLeagueLOVLoading(false);
    }

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];

    //     // Check if the file type is PDF
    //     if (file && file.type === 'application/pdf') {
    //         setSelectedFile(file);
    //         setSelectedFileError('');
    //     } else {
    //         setSelectedFile(null);
    //         setValidatedLicense(true);
    //         setSelectedFileError('Please select a valid PDF file.');
    //     }
    //   };

    // function responsible for getting user personal information

    const userPersonalInfo = async () => {
        // e.preventDefault();
        console.log('user Personal Information');
        let response = fetch(`${INSTITUITION_INFO_URL}/${user.user_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const status = (await response).status
        const data = (await response).json()
        // console.log('data:', data)
        if (status === 200) {
            const personalInfo = (await data)
            console.log('personalInfo: ', personalInfo)
            setUserPersonalData(personalInfo)
            setWebsite(personalInfo.website)
            const sportProfileType = (await personalInfo.sport_profile_type)
            sportProfileType.map(item => {
                if (item.profile_type === 'Institution') {
                    if (item.is_active === true)
                        setSelectedSwitchOption(true)
                    else
                        setSelectedSwitchOption(false)
                }
            })
            const club = (await personalInfo.club)
            if (club.length > 0) {
                setClubInfo(club[0])
                const license = (await club[0].verification_document)
                setClubLicense(license)
                const officials = (await club[0].office_bearer)
                setClubOfficials(officials)
                const history = (await club[0].club_history)
                setClubHistory(history)
                getClubLicense()
            }
        } else if (status === 401) {
            notifyAuthenticateError()
        } else {
            notifyError()
        }
    }

    useEffect(() => {
        userPersonalInfo();
        getLeagues();
    }, [showEditGeneralModal, showAddLicenseModal, showEditLicenseModal, showAddOfficialsModal, showEditOfficialsModal, showAddCareerHistoryModal, showEditCareerHistoryModal]);

    const clubLicenseList = clubLicense.map((license) => {
        const words = license.document_file.split("/");
        return (
            <tr key={license.id}>
                <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getClubLicenseInfo(license.id);
                            setShowEditLicenselModal(true);
                        }}
                    />
                </td>
                <td>{license.document_name}</td>
                {/* <td>
                    {license.document_file !== null ?
                        <img src={license.document_file} alt={license.document_name} style={{ maxWidth: '50px' }} />
                        : null
                    }
                </td> */}
                <td>
                    <a href={license.document_file} download="downloadedFile.pdf">
                        {words[5]}
                    </a>
                </td>
                <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClubDocumentDelete(license.id)}
                    />
                </td>
            </tr>
        );
    })

    const clubOfficialsList = clubOfficials.map((official) => {
        return (
            <tr key={official.id}>
                <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getClubOfficialInfo(official.id);
                            setShowEditOfficialsModal(true);
                        }}
                    />
                </td>
                <td>{official.name}</td>
                <td>{official.position}</td>
                <td>{official.department}</td>
                <td>{official.email}</td>
                <td>{official.phone_number}</td>
                <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClubOfficialDelete(official.id)}
                    />
                </td>
            </tr>
        );
    })

    const clubCareerHistoryList = clubHistory.map((history) => {
        return (
            <tr key={history.id}>
                <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getClubCareerHistoryInfo(history.id);
                            setShowEditCareerHistoryModal(true);
                        }}
                    />
                </td>
                <td>{history.from_year}</td>
                <td>{history.to_year}</td>
                <td>{history.league_name}</td>
                <td>{history.games_played}</td>
                <td>{history.points}</td>
                <td>{history.position}</td>
                <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClubHistoryDelete(history.id)}
                    />
                </td>
            </tr>
        );
    })

    const personalAchievementList = clubHistory.map((club) => {
        const parsedData = club.achievement !== null && club.achievement !== '' ? club.achievement.split('\n') : null;
        // console.log('parsedData', parsedData);
        if (club.achievement !== null && club.achievement !== '')
            return (
                <tr key={club.id}>
                    <td>{club.from_year}</td>
                    <td>{club.to_year}</td>
                    {/* <td>{club.achievement}</td> */}
                    <td>
                        {parsedData && parsedData.length > 0 &&
                            <ul>
                                {parsedData.map((item, index) => (
                                    <li key={index} className='card-text'>{item}</li>
                                ))}
                            </ul>
                        }
                    </td>
                </tr>
            );
    })

    return (
        <>

            <Modal show={showEditGeneralModal} onHide={handleEditGenralModalClose} size="xl" scrollable={true}>
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Edit institution informantion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedClubInfo}>
                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Website</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter the URL"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditGenralModalClose}>Close</Button>
                    <Button variant="primary" onClick={handleUpdateGeneralInfo}>Save Changes</Button>
                </Modal.Footer>
            </Modal >

            <Modal show={showAddLicenseModal} onHide={handleAddLicenseModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Add Licence</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedLicense}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Document Name
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Select the name of a license"
                                    value={licenseName}
                                    onChange={e => handleSearchLicenseFilter(e.target.value)}
                                    required
                                />
                                {isLicenseLOVloading &&
                                    <div className='lov-content mt-1'>
                                        <ul className='lov-list' style={{ padding: 5 }}>
                                            {
                                                searchLicenseLOVData.length > 0 ?
                                                    searchLicenseLOVData.map((item) => (
                                                        <li className='lov-list-element' key={item.value} onClick={() => handleSelectLicense(item)}>
                                                            {item.label}
                                                        </li>
                                                    ))

                                                    // : <li className='lov-list-element'>No data found</li>
                                                    : setIsLicenseLOVLoading(false)
                                            }
                                        </ul>
                                    </div>
                                }
                                <Form.Control.Feedback type="invalid">
                                    {licenseError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Certificate
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    type="file"
                                    size='lg'
                                    // value={selectedFile}
                                    onChange={(e) => setSelectedFile(e.target.files[0])}
                                    // onChange={handleFileChange}
                                    required
                                />
                                {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
                                <Form.Control.Feedback type="invalid">
                                    {selectedFileError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAddLicenseModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleAddClubVerificationDocument}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showEditLicenseModal} onHide={handleEditLicenseModalClose} size="xl" scrollable={true}>
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Add Licence</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedLicense}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Document
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Select the name of a license"
                                    value={licenseName}
                                    onChange={e => handleSearchLicenseFilter(e.target.value)}
                                    required
                                />
                                {isLicenseLOVloading &&
                                    <div className='lov-content mt-1'>
                                        <ul className='lov-list' style={{ padding: 5 }}>
                                            {
                                                searchLicenseLOVData.length > 0 ?
                                                    searchLicenseLOVData.map((item) => (
                                                        <li className='lov-list-element' key={item.value} onClick={() => handleSelectLicense(item)}>
                                                            {item.label}
                                                        </li>
                                                    ))

                                                    // : <li className='lov-list-element'>No data found</li>
                                                    : setIsLicenseLOVLoading(false)
                                            }
                                        </ul>
                                    </div>
                                }
                                <Form.Control.Feedback type="invalid">
                                    {licenseError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Certificate
                                    <span className='text-danger'> </span>
                                </Form.Label>
                                <Form.Control
                                    type="file"
                                    size='lg'
                                    // value={selectedFile}
                                    onChange={(e) => setSelectedFile(e.target.files[0])}
                                    required
                                />
                                {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
                                <Form.Control.Feedback type="invalid">
                                    {selectedFileError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditLicenseModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleUpdateInstituteLicense}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showAddCareerHistoryModal} onHide={handleAddCareerHistoryModalClose} size="xl" scrollable={true}>
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Add Career History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedCareerHistory}>
                        <Row className='mb-3'>
                            <Form.Group as={Col} sm='4'>
                                <Form.Label className='custom-label'>From Year
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="number"
                                    size='lg'
                                    placeholder="Enter the year e.g. 2012"
                                    value={fromYear}
                                    onChange={(e) => setFromYear(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {fromYearError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm='4'>
                                <Form.Label className='custom-label'>To Year
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="number"
                                    size='lg'
                                    placeholder="Enter the year e.g. 2022"
                                    value={toYear}
                                    onChange={(e) => setToYear(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {toYearError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>League Name
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Select the name of a league"
                                    value={leagueName}
                                    onChange={e => handleSearchLeagueFilter(e.target.value)}
                                    required
                                />
                                {isLeagueLOVloading &&
                                    <div className='lov-content mt-1'>
                                        <ul className='lov-list' style={{ padding: 5 }}>
                                            {
                                                searchLeagueLOVData.length > 0 ?
                                                    searchLeagueLOVData.map((item) => (
                                                        <li className='lov-list-element' key={item.id} onClick={() => handleSelectLeague(item)}>
                                                            {item.label}
                                                        </li>
                                                    ))

                                                    // : <li className='lov-list-element'>No data found</li>
                                                    : setIsLeagueLOVLoading(false)
                                            }
                                        </ul>
                                    </div>
                                }
                                <Form.Control.Feedback type="invalid">
                                    {leagueNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Games Played
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the games played"
                                    value={gamesPlayed}
                                    onChange={(e) => setGamesPlayed(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Games Won</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the games won"
                                    value={gamesWon}
                                    onChange={(e) => setGamesWon(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Games Lost</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the games lost"
                                    value={gamesLost}
                                    onChange={(e) => setGamesLost(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Games Tied</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the games tied"
                                    value={gamesTied}
                                    onChange={(e) => setGamesTied(e.target.value)}
                                />
                            </Form.Group>
                        </Row>


                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Points</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the points"
                                    value={points}
                                    onChange={(e) => setPoints(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Position</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the position"
                                    value={position}
                                    onChange={(e) => setPosition(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Tournament</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='text'
                                    size='lg'
                                    placeholder="Enter the tournament"
                                    value={tournament}
                                    onChange={(e) => setTournament(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Key Achievement</Form.Label>
                                <Form.Control
                                    className='input'
                                    as="textarea"
                                    rows={5}
                                    placeholder="Enter the key achievements"
                                    value={keyAchievements}
                                    onChange={(e) => setKeyAchievements(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleAddCareerHistoryModalClose}>Close</Button>
                    <Button variant="primary" onClick={handleAddClubCareerHistory}>Save Changes</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditCareerHistoryModal} onHide={handleEditCareerHistoryModalClose} size="xl" scrollable={true}>
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Edit Career History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedCareerHistory}>
                        <Row className='mb-3'>
                            <Form.Group as={Col} sm='4'>
                                <Form.Label className='custom-label'>From Year
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="number"
                                    size='lg'
                                    placeholder="Enter the year e.g. 2012"
                                    value={fromYear}
                                    onChange={(e) => setFromYear(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {fromYearError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm='4'>
                                <Form.Label className='custom-label'>To Year
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="number"
                                    size='lg'
                                    placeholder="Enter the year e.g. 2022"
                                    value={toYear}
                                    onChange={(e) => setToYear(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {toYearError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>League Name
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Select the name of a league"
                                    value={leagueName}
                                    onChange={e => handleSearchLeagueFilter(e.target.value)}
                                    required
                                />
                                {isLeagueLOVloading &&
                                    <div className='lov-content mt-1'>
                                        <ul className='lov-list' style={{ padding: 5 }}>
                                            {
                                                searchLeagueLOVData.length > 0 ?
                                                    searchLeagueLOVData.map((item) => (
                                                        <li className='lov-list-element' key={item.id} onClick={() => handleSelectLeague(item)}>
                                                            {item.label}
                                                        </li>
                                                    ))

                                                    // : <li className='lov-list-element'>No data found</li>
                                                    : setIsLeagueLOVLoading(false)
                                            }
                                        </ul>
                                    </div>
                                }
                                <Form.Control.Feedback type="invalid">
                                    {leagueNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Games Played
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the games played"
                                    value={gamesPlayed}
                                    onChange={(e) => setGamesPlayed(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Games Won</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the games won"
                                    value={gamesWon}
                                    onChange={(e) => setGamesWon(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Games Lost</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the games lost"
                                    value={gamesLost}
                                    onChange={(e) => setGamesLost(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Games Tied</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the games tied"
                                    value={gamesTied}
                                    onChange={(e) => setGamesTied(e.target.value)}
                                />
                            </Form.Group>
                        </Row>


                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Points</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the points"
                                    value={points}
                                    onChange={(e) => setPoints(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Position</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='number'
                                    size='lg'
                                    placeholder="Enter the position"
                                    value={position}
                                    onChange={(e) => setPosition(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Tournament</Form.Label>
                                <Form.Control
                                    className='input'
                                    type='text'
                                    size='lg'
                                    placeholder="Enter the tournament"
                                    value={tournament}
                                    onChange={(e) => setTournament(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Key Achievement</Form.Label>
                                <Form.Control
                                    className='input'
                                    as="textarea"
                                    rows={5}
                                    placeholder="Enter the key achievements"
                                    value={keyAchievements}
                                    onChange={(e) => setKeyAchievements(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditCareerHistoryModalClose}>Close</Button>
                    <Button variant="primary" onClick={handleEditClubCareerHistory}>Save Changes</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddOfficialsModal} onHide={handleAddOfficialsModalClose} size="xl" scrollable={true}>
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Add Institute Officials</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedOfficials}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Name
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Enter the name of the person"
                                    value={personName}
                                    onChange={(e) => setPersonName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {personNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Position
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    className='input'
                                    size='lg'
                                    value={positionOfficial}
                                    onChange={(e) => setPositionOfficial(e.target.value)}
                                    required
                                >
                                    <option value=''>Open this select menu</option>
                                    <option value="President">President</option>
                                    <option value="Vice-President">Vice-President</option>
                                    <option value="Assistant Treasurer">Assistant Treasurer</option>
                                    <option value="Assistant Secretary">Assistant Secretary</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {positionOfficialError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Department
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Enter the name of the department"
                                    value={departmentName}
                                    onChange={(e) => setDepartmentName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {departmentNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    size="lg"
                                    placeholder="Enter the email address of contact person"
                                    value={emailAddress}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Contact Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    size="lg"
                                    placeholder="Enter the conatct number of contact person"
                                    value={contactNo}
                                    onChange={(e) => setContactNo(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleAddOfficialsModalClose}>Close</Button>
                    <Button variant="primary" onClick={handleAddOfficials}>Save Changes</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditOfficialsModal} onHide={handleEditOfficialsModalClose} size="xl" scrollable={true}>
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Add Institute Officials</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedOfficials}>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Name
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Enter the name of the person"
                                    value={personName}
                                    onChange={(e) => setPersonName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {personNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Position
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    className='input'
                                    size='lg'
                                    value={positionOfficial}
                                    onChange={(e) => setPositionOfficial(e.target.value)}
                                    required
                                >
                                    <option value=''>Open this select menu</option>
                                    <option value="President">President</option>
                                    <option value="Vice-President">Vice-President</option>
                                    <option value="Assistant Treasurer">Assistant Treasurer</option>
                                    <option value="Assistant Secretary">Assistant Secretary</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {positionOfficialError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Department
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Enter the name of the department"
                                    value={departmentName}
                                    onChange={(e) => setDepartmentName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {departmentNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    size="lg"
                                    placeholder="Enter the email address of contact person"
                                    value={emailAddress}
                                    onChange={(e) => setEmailAddress(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Contact Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    size="lg"
                                    placeholder="Enter the conatct number of contact person"
                                    value={contactNo}
                                    onChange={(e) => setContactNo(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleEditOfficialsModalClose}>Close</Button>
                    <Button variant="primary" onClick={handleEditOfficials}>Save Changes</Button>
                </Modal.Footer>
            </Modal>

            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={user.is_flag} profileType={'institute'} />
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, marginLeft: 40, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.first_name} {item.last_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null}
                            </ul>
                        </div>
                    </div>
                </div>
            }

            <Container>
                <Row>

                    <ProfileBuilderInstitutionNavbar instituitionIsActive={selectedSwitchOption} />

                    <Col xs={9}>
                        {!user.is_flag &&
                            <Alert variant="success" className='mt-3'>
                                Please wait for the activation of your account. We will reach out to you as soon as possible.
                            </Alert>
                        }
                        <Card className='mt-3'>
                            <Card.Body>
                                <h2 className='text-center'>Institution</h2>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                    justify
                                    variant='underline'
                                >
                                    <Tab eventKey="general" title="GENERAL">
                                        <Card>
                                            <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Institute Information</Card.Header>
                                            <Card.Body>
                                                <Table striped responsive bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Edit</th>
                                                            <th>Institution Name</th>
                                                            <th>Website</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {clubInfo !== '' ?
                                                            <tr>
                                                                <td>
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        color='#2222e785'
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getClubInfo(clubInfo.id);
                                                                            setShowEditGeneralModal(true);
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>{userPersonalData.club_name}</td>
                                                                <td>{clubInfo.website}</td>
                                                            </tr>
                                                            :
                                                            <tr>
                                                                <th colSpan={3} className='text-center'>
                                                                    No rows found
                                                                </th>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Tab>

                                    <Tab eventKey="license" title="VERIFICATION DOCUMENT">
                                        <Stack direction="horizontal" gap={2}>
                                            <div className="p-2 ms-auto">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => setShowAddLicenseModal(true)}
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </Stack>
                                        <div style={{ height: '350px', overflowY: 'scroll' }}>
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Documents</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Edit</th>
                                                                <th>Document Name</th>
                                                                <th>Certificate</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clubLicense && clubLicense.length > 0 ? clubLicenseList :
                                                                <tr>
                                                                    <th colSpan={5} className='text-center'>
                                                                        No rows found
                                                                    </th>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="officials" title="OFFICIALS">
                                        <Stack direction="horizontal" gap={2}>
                                            <div className="p-2 ms-auto">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => setShowAddOfficialsModal(true)}
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </Stack>
                                        <div style={{ height: '350px', overflowY: 'scroll' }}>
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Institute Officials</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Edit</th>
                                                                <th>Person Name</th>
                                                                <th>Position</th>
                                                                <th>Department</th>
                                                                <th>Email</th>
                                                                <th>Contact Number</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clubOfficials && clubOfficials.length > 0 ? clubOfficialsList :
                                                                <tr>
                                                                    <th colSpan={7} className='text-center'>
                                                                        No rows found
                                                                    </th>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="careerhistory" title="CAREER HISTORY">
                                        <Stack direction="horizontal" gap={2}>
                                            <div className="p-2 ms-auto">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => setShowAddCareerHistoryModal(true)}
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </Stack>
                                        <div style={{ height: '350px', overflowY: 'scroll' }}>
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Career Histories</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Edit</th>
                                                                <th>From Year</th>
                                                                <th>To Year</th>
                                                                <th>League Name</th>
                                                                <th>Games Played</th>
                                                                <th>Points</th>
                                                                <th>Position</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clubHistory && clubHistory.length > 0 ? clubCareerHistoryList :
                                                                <tr>
                                                                    <th colSpan={8} className='text-center'>
                                                                        No rows found
                                                                    </th>
                                                                </tr>}
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>

                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Achievements</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>From Year</th>
                                                                <th>To Year</th>
                                                                <th>Achievement Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clubHistory && clubHistory.length > 0 ? personalAchievementList :
                                                                <tr>
                                                                    <th colSpan={3} className='text-center'>
                                                                        No rows found
                                                                    </th>
                                                                </tr>}
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>

                                </Tabs>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container >
            <ToastContainer />
        </>
    )
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClock, faPaperPlane, faUserPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import blankPhoto from '../assets/blank-profile-picture.png'
import './css/CustomComponents.css'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ReactPlayer from 'react-player';
import { faThumbsUp, faMessage } from '@fortawesome/free-regular-svg-icons';
import BlankProfilePicture from '../assets/blank-profile-picture.png';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink, Link } from 'react-router-dom';
import { BASE_MEDIA_URL } from '../services/Constants';

export const NetworkConnectCard = ({ item, onClose, profileType, onConnect, onClickUser, currentUser }) => {

    console.log('currentUser:', currentUser.user_id);

    // console.log('item:', item);

    let networkRequestStatus = '';

    if (item.connected_users.length > 0) {
        item.connected_users.map(userConnected => {
            if (userConnected.connect_to_user.id === currentUser.user_id && userConnected.status === 'connected') {
                networkRequestStatus = 'connected';
                console.log('connected request found:', item);
            } else if (userConnected.network_request_send_by === currentUser.user_id && userConnected.status === 'pending') {
                networkRequestStatus = 'pending';
                console.log('pending:', item);
            } else if (userConnected.connect_to_user.id === currentUser.user_id && userConnected.status === 'pending') {
                networkRequestStatus = 'pending request found';
                console.log('pending request found:', item);
            }
        })
    }

    const userSportProfileType = item.sport_profile_type.map((profile) => {
        if (profile.is_active && profile.status === 'Current') {
            return (
                <>
                    {
                        item.sport_type !== null && item.sport_type !== '' ?
                            <p className="card-text">{item.sport_type} - {profile.profile_type}</p> :
                            <p className="card-text">{profile.profile_type}</p>
                    }
                </>
            );
        }
    })

    // const userSportProfileDescription = (desc) => {
    //     // console.log('Desc: ', desc);
    //     const words = desc.split(" ");
    //     const profileInfo = words.slice(0, 10).join(" ");
    //     return (
    //         <p className="card-text">{profileInfo}</p>
    //     );
    // }

    // console.log('Profile Description: ', item.profile_description[0]);

    const userCardInfo = item.sport_profile_type.map((profile) => {
        if (profile.is_active && profile.profile_type === profileType && profile.status === 'Current' && item.is_verified && item.is_flag) {
            return (
                <div className="col" key={item.id}>
                    <div className="card h-100 shadow" style={{ width: '16rem' }}>
                        <div className="card-body">
                            <div className='custom-background' style={{ backgroundColor: '#D1D1F2', borderBottomLeftRadius: '20%', borderBottomRightRadius: '20%', paddingBottom: 10 }}>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    style={{ float: 'right', margin: 10, backgroundColor: 'white', borderRadius: '10%', padding: 10 }}
                                    onClick={onClose}
                                >
                                </button>
                                <div className='d-flex align-items-center justify-content-center'>
                                    {
                                        item.profile_photo !== null
                                            ? <img src={item.profile_photo} className="card-img-top shadow" alt="..." style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%', marginTop: 10, marginLeft: 50 }} />
                                            : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%', marginTop: 10, marginLeft: 50 }} />
                                    }
                                </div>
                            </div>
                            {item.account_type !== 'institute' ?
                                <h5 className="card-title" onClick={onClickUser}>{item.first_name} {item.last_name}</h5> :
                                <h5 className="card-title" onClick={onClickUser}>{item.club_name}</h5>
                            }
                            {userSportProfileType}
                            {/* {
                                item.profile_description && item.profile_description.length > 0 &&
                                <p className="card-text">{userSportProfileDescription(item.profile_description[0].description)}</p>
                            } */}

                        </div>
                        {/* {item.connected_users.length > 0 ?
                            item.connected_users.map(connectedUser => (
                                <div key={connectedUser.connect_to_user.id}>
                                    {connectedUser.connect_to_user.id === currentUser.user_id ?
                                        <div className="card-footer">
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary"
                                                style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                                disabled={true}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faClock}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Pending
                                            </button>
                                        </div> :
                                        <div className="card-footer">
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary"
                                                style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                                onClick={onConnect}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faUserPlus}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Connect
                                            </button>
                                        </div>
                                    }
                                </div>
                            ))
                            :
                            <div className="card-footer">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                    onClick={onConnect}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserPlus}
                                        style={{ cursor: 'pointer', marginRight: 10 }}
                                    />
                                    Connect
                                </button>
                            </div>
                        } */}
                        <div className="card-footer">
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                onClick={onConnect}
                            >
                                <FontAwesomeIcon
                                    icon={faUserPlus}
                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                />
                                Connect
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    });

    const pendingUserCardInfo = item.sport_profile_type.map((profile) => {
        if (profile.is_active && profile.profile_type === profileType && profile.status === 'Current' && item.is_verified && item.is_flag) {
            return (
                <div className="col" key={item.id}>
                    <div className="card h-100 shadow" style={{ width: '16rem' }}>
                        <div className="card-body">
                            <div className='custom-background' style={{ backgroundColor: '#D1D1F2', borderBottomLeftRadius: '20%', borderBottomRightRadius: '20%', paddingBottom: 10 }}>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    style={{ float: 'right', margin: 10, backgroundColor: 'white', borderRadius: '10%', padding: 10 }}
                                    onClick={onClose}
                                >
                                </button>
                                <div className='d-flex align-items-center justify-content-center'>
                                    {
                                        item.profile_photo !== null
                                            ? <img src={item.profile_photo} className="card-img-top shadow" alt="..." style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%', marginTop: 10, marginLeft: 50 }} />
                                            : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%', marginTop: 10, marginLeft: 50 }} />
                                    }
                                </div>
                            </div>
                            {item.account_type !== 'institute' ?
                                <h5 className="card-title" onClick={onClickUser}>{item.first_name} {item.last_name}</h5> :
                                <h5 className="card-title" onClick={onClickUser}>{item.club_name}</h5>
                            }
                            {userSportProfileType}
                            {/* {
                                item.profile_description && item.profile_description.length > 0 &&
                                <p className="card-text">{userSportProfileDescription(item.profile_description[0].description)}</p>
                            } */}

                        </div>
                        <div className="card-footer">
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                disabled={true}
                            >
                                <FontAwesomeIcon
                                    icon={faClock}
                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                />
                                Pending
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    });

    return (
        <>
            {/* {currentUserConnected.connected_users.map((connectedUser) =>
                connectedUser.connect_to_user.id !== item.id &&
                <>
                    {userCardInfo}
                </>
            )} */}
            {networkRequestStatus === '' ?
                userCardInfo :
                networkRequestStatus === 'pending' &&
                pendingUserCardInfo
            }
            {/* {networkRequestStatus === '' &&
                userCardInfo
            } */}
        </>
    );
}

export const PendingNetworkConnectCard = ({ item, onReject, onAccept, onClickUser }) => {
    // console.log('Item:', item)

    const userSportProfileType = item.from_user.sport_profile_type.map((profile) => {
        if (profile.is_active && profile.status === 'Current') {
            return (
                <p className="card-text">{item.from_user.sport_type} - {profile.profile_type}</p>
            );
        } else {
            return (
                null
            );
        }
    })

    // const userSportProfileDescription = (desc) => {
    //     const words = desc.split(" ");
    //     const profileInfo = words.slice(0, 10).join(" ");
    //     return (
    //         <p className="card-text">{profileInfo}</p>
    //     );
    // }

    // console.log('Profile Description: ', item.profile_description[0]);
    if (item.status === 'pending') {
        return (
            <div className="col" key={item.id}>
                <div className="card h-100 shadow" style={{ width: '16rem' }}>
                    <div className="card-body">
                        <div className='custom-background' style={{ backgroundColor: '#D1D1F2', borderBottomLeftRadius: '20%', borderBottomRightRadius: '20%', paddingBottom: 10 }}>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                style={{ float: 'right', margin: 10, backgroundColor: 'white', borderRadius: '10%', padding: 10 }}
                                onClick={onReject}
                            >
                            </button>
                            <div className='d-flex align-items-center justify-content-center'>
                                {
                                    item.from_user.profile_photo !== null
                                        ? <img src={item.from_user.profile_photo_url} className="card-img-top shadow" alt="..." style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%', marginTop: 10, marginLeft: 50 }} />
                                        : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%', marginTop: 10, marginLeft: 50 }} />
                                }
                            </div>
                        </div>
                        <h5 className="card-title" onClick={onClickUser}>{item.from_user.first_name} {item.from_user.last_name}</h5>
                        {userSportProfileType}
                        {/* {
                            item.from_user.profile_description && item.from_user.profile_description.length > 0
                                ? <p className="card-text">{userSportProfileDescription(item.from_user.profile_description[0].description)}</p>
                                : <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        } */}
                    </div>
                    <div className="card-footer d-flex justify-content-between">
                        <button
                            type="button"
                            className="btn btn-outline-success mx-1"
                            style={{ paddingLeft: 10, paddingRight: 10, width: '50%', fontSize: 14, fontWeight: 600, }}
                            onClick={onAccept}
                        >
                            <FontAwesomeIcon
                                icon={faCheck}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            Accept
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-danger mx-1"
                            style={{ paddingLeft: 10, paddingRight: 10, width: '50%', fontSize: 14, fontWeight: 600, }}
                            onClick={onReject}
                        >
                            <FontAwesomeIcon
                                icon={faXmark}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            Reject
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return null
    }
}

export const UserConnections = ({ item, onClickMessage, onClickUser }) => {
    // console.log(item);

    const userSportProfileType = item.connect_to_user.sport_profile_type.map((profile) => {
        if (profile.is_active && profile.status === 'Current') {
            return (
                <>
                    {item.connect_to_user.account_type === 'institute' ?
                        <h5 className="card-text">{profile.profile_type}</h5> :
                        <h5 className="card-text">{item.connect_to_user.sport_type} - {profile.profile_type}</h5>
                    }
                </>
            );
        } else {
            return (
                null
            );
        }
    })

    return (
        <div className="card shadow" key={item.id}>
            <div className="card-body">
                <div className='d-flex row'>
                    <div className='col'>
                        {
                            item.connect_to_user.profile_photo !== null
                                ? <img src={item.connect_to_user.profile_photo_url} className="card-img-top shadow" alt="..." style={{ height: 80, width: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                                : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ height: 80, width: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                        }
                    </div>
                    <div className='col-10'>
                        {item.connect_to_user.account_type === 'institute' ?
                            <h5 className="card-title" onClick={onClickUser}>{item.connect_to_user.club_name}</h5> :
                            <h5 className="card-title" onClick={onClickUser}>{item.connect_to_user.first_name} {item.connect_to_user.last_name}</h5>
                        }
                        {userSportProfileType}
                        <div className="d-flex justify-content-between">
                            <p className="card-text"><small className="text-body-secondary">We are Connected</small></p>
                            <button className="btn btn-primary ms-2" type="button" onClick={onClickMessage}>Message</button>
                        </div>
                    </div>
                </div>
                {/* <div className='d-flex justify-content-between'>
                    <div>
                        {
                            item.connect_to_user.profile_photo !== null
                                ? <img src={item.connect_to_user.profile_photo_url} className="card-img-top shadow" alt="..." style={{ width: 100, height: 90, objectFit: 'cover', borderRadius: '50%' }} />
                                : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 100, height: 90, objectFit: 'cover', borderRadius: '50%' }} />
                        }
                    </div>
                    <div>
                        <h5 className="card-title">{item.connect_to_user.first_name} {item.connect_to_user.last_name}</h5>
                        {userSportProfileType}
                        <div className="d-grid gap-2 d-md-flex justify-content-between">
                            <p className="card-text"><small className="text-body-secondary">We are Connected</small></p>
                            <button className="btn btn-primary" type="button" onClick={onClickMessage}>Message</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export const UserSearch = ({ item, currentUser, onClickUser, onClickMessage, onClickConnect }) => {
    // console.log(item);

    let connectedStatus;
    let pendingStatus;

    const userSportProfileType = item.sport_profile_type.map((profile) => {
        // console.log(profile);
        if (profile.is_active && profile.status === 'Current') {
            return (
                <>
                    {item.account_type === 'user' ?
                        <div>{item.sport_type} - {profile.profile_type}</div> :
                        <div>{profile.profile_type}</div>
                    }
                </>
            );
        }
    })

    const userConnectedOrNot = item.connected_users.map((connectedUser) => {
        // if (connectedUser.connect_to_user.id === currentUser.user_id) {
        if ((connectedUser.connect_to_user.id === currentUser.user_id) && (connectedUser.status === 'connected')) {
            connectedStatus = 'connected';
            return (
                <>
                    <p className="card-text"><small className="text-body-secondary">We are Connected.</small></p>
                    <button className="btn btn-primary" type="button" onClick={onClickMessage}>Message</button>
                </>
            );
        } else if ((connectedUser.connect_to_user.id === currentUser.user_id) && (connectedUser.status === 'pending')) {
            pendingStatus = 'pending';
            return (
                <>
                    <p className="card-text"><small className="text-body-secondary">We are not Connected.</small></p>
                    <button className="btn btn-primary" type="button" disabled>Pending...</button>
                </>
            );
        }
    })

    // console.log('connected_users:', item.connected_users.length);

    return (
        <div className="card shadow">
            <div className="card-body">
                <div className='d-flex flex-row'>
                    <div className='col'>
                        {
                            item.profile_photo !== null
                                ? <img src={item.profile_photo_url} className="card-img-top shadow" alt="..." style={{ objectFit: 'cover', borderRadius: '50%', width: 50, height: 50 }} />
                                : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ objectFit: 'cover', borderRadius: '50%', width: 50, height: 50 }} />
                        }
                    </div>
                    <div className='col-11 ms-5'>
                        {item.account_type === 'user' ?
                            <h5 className="card-title user-search-my-card-title" onClick={onClickUser} style={{ cursor: 'pointer' }}>{item.first_name} {item.last_name}</h5> :
                            <h5 className="card-title user-search-my-card-title" onClick={onClickUser} style={{ cursor: 'pointer' }}>{item.club_name}</h5>
                        }
                        {userSportProfileType}
                        <div className="d-grid gap-2 d-md-flex justify-content-between">
                            {item.connected_users.length > 0
                                ? userConnectedOrNot
                                :
                                <>
                                    <p className="card-text"><small className="text-body-secondary">We are not Connected</small></p>
                                    <button className="btn btn-primary" type="button" onClick={onClickConnect}>Connect</button>
                                </>
                            }
                            {
                                item.connected_users && item.connected_users.length > 0 && connectedStatus !== 'connected' && pendingStatus !== 'pending' &&
                                <>
                                    <p className="card-text"><small className="text-body-secondary">We are not Connected</small></p>
                                    <button className="btn btn-primary" type="button" onClick={onClickConnect}>Connect</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const UserNotifications = ({ item, onClickNotifications }) => {
    return (
        <div className="card shadow" key={item.id}>
            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        {
                            item.from_user.profile_photo !== null
                                ? <img src={item.from_user.profile_photo_url} className="card-img-top shadow" alt="..." style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%' }} />
                                : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%' }} />
                        }
                    </div>
                    <div className='col-10'>
                        <h5 className="card-title">{item.from_user.first_name} {item.from_user.last_name}</h5>
                        {/* {userSportProfileType} */}
                        {/* <p className="card-text">{}</p> */}
                        {/* <blockquote className="blockquote">
                                            <footer className="blockquote-footer">We are <cite title="Source Title">Connected</cite></footer>
                                        </blockquote> */}
                        <div className="d-grid gap-2 d-md-flex justify-content-between">
                            {/* <p className="card-text"><small className="text-body-secondary">{item.profile_type.charAt(0).toUpperCase() + item.profile_type.slice(1)}</small></p> */}
                            <p className="card-text"><small className="text-body-secondary">{item.profile_type}</small></p>
                            <button className="btn btn-primary" type="button" onClick={onClickNotifications}>Open</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const SearchCard = ({ item, currentUser, onClickUser, onClickMessage, onClickConnect }) => {
    // console.log('SPort profile Type:', item.sport_profile_type)

    let connectedStatus;
    let pendingStatus;

    const userSportProfileType = item.user.sport_profile_type.map((profile) => {
        if (profile.profile_type.toLowerCase() === 'player') {
            return (
                <h5 className="card-text">{item.user.sport_type} - {profile.profile_type}</h5>
            );
        } else {
            return (
                null
            );
        }
    })

    const userSportProfileDescription = (desc) => {
        // console.log('Desc: ', desc);
        const words = desc.split(" ");
        const profileInfo = words.slice(0, 10).join(" ");
        return (
            <p className="card-text">{profileInfo}</p>
        );
    }

    const userConnectedOrNot = item.user.connected_users.map((connectedUser) => {
        if ((connectedUser.connect_to_user === currentUser.user_id) && (connectedUser.status === 'connected')) {
            connectedStatus = 'connected';
            return (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                        onClick={onClickMessage}
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Message
                    </button>
                </>
            );
        } else if ((connectedUser.connect_to_user === currentUser.user_id) && (connectedUser.status === 'pending')) {
            pendingStatus = 'pending';
            return (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                        // onClick={onClickMessage}
                        disabled
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Connect
                    </button>
                </>
            );
        }
    })

    const userCardInfo = item.user.sport_profile_type.map((profile) => {
        if (profile.profile_type.toLowerCase() === 'player') {
            return (
                <div className="col" key={item.id}>
                    <div className="card h-100 shadow">
                        <div className='custom-background' style={{ backgroundColor: '#D1D1F2', borderBottomLeftRadius: '20%', borderBottomRightRadius: '20%', }}>
                            {
                                item.user.profile_photo !== null
                                    ? <img src={item.user.profile_photo} className="card-img-top shadow" alt="..." style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%', marginTop: 10, }} />
                                    : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%', marginTop: 10, }} />
                            }
                        </div>
                        <div className="card-body">
                            <h4 className="card-title user-search-my-card-title" onClick={onClickUser} style={{ cursor: 'pointer' }}>{item.user.first_name} {item.user.last_name}</h4>
                            {userSportProfileType}
                            {
                                item.user.profile_description && item.user.profile_description.length > 0
                                    ? <p className="card-text">{userSportProfileDescription(item.user.profile_description[0].description)}</p>
                                    : <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                            }
                        </div>
                        <div className="card-footer">
                            {
                                item.user.connected_users.length > 0 ?
                                    userConnectedOrNot
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                        onClick={onClickConnect}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUserPlus}
                                            style={{ cursor: 'pointer', marginRight: 10 }}
                                        />
                                        Connect
                                    </button>
                            }
                            {
                                item.user.connected_users && item.user.connected_users.length > 0 && connectedStatus !== 'connected' && pendingStatus !== 'pending' &&
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                    onClick={onClickConnect}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserPlus}
                                        style={{ cursor: 'pointer', marginRight: 10 }}
                                    />
                                    Connect
                                </button>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                null
            );
        }
    });

    return (
        <>
            {userCardInfo}
        </>
    );
}

export const SearchCoachCard = ({ item, currentUser, onClickUser, onClickMessage, onClickConnect }) => {
    // console.log('SPort profile Type:', item.sport_profile_type)

    let connectedStatus;
    let pendingStatus;

    const userSportProfileType = item.user.sport_profile_type.map((profile) => {
        if (profile.profile_type.toLowerCase() === 'coach') {
            return (
                <h5 className="card-text">{item.user.sport_type} - {profile.profile_type}</h5>
            );
        } else {
            return (
                null
            );
        }
    })

    const userSportProfileDescription = (desc) => {
        // console.log('Desc: ', desc);
        const words = desc.split(" ");
        const profileInfo = words.slice(0, 10).join(" ");
        return (
            <p className="card-text">{profileInfo}</p>
        );
    }

    const userConnectedOrNot = item.user.connected_users.map((connectedUser) => {
        if ((connectedUser.connect_to_user === currentUser.user_id) && (connectedUser.status === 'connected')) {
            connectedStatus = 'connected';
            return (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                        onClick={onClickMessage}
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Message
                    </button>
                </>
            );
        } else if ((connectedUser.connect_to_user === currentUser.user_id) && (connectedUser.status === 'pending')) {
            pendingStatus = 'pending';
            return (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                        // onClick={onClickMessage}
                        disabled
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Connect
                    </button>
                </>
            );
        }
    })

    const userCardInfo = item.user.sport_profile_type.map((profile) => {
        if (profile.profile_type.toLowerCase() === 'coach') {
            return (
                <div className="col" key={item.id}>
                    <div className="card h-100 shadow">
                        <div className='custom-background' style={{ backgroundColor: '#D1D1F2', borderBottomLeftRadius: '20%', borderBottomRightRadius: '20%', }}>
                            {
                                item.user.profile_photo !== null
                                    ? <img src={item.user.profile_photo} className="card-img-top shadow" alt="..." style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%', marginTop: 10, }} />
                                    : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%', marginTop: 10, }} />
                            }
                        </div>
                        <div className="card-body">
                            <h4 className="card-title user-search-my-card-title" onClick={onClickUser} style={{ cursor: 'pointer' }}>{item.user.first_name} {item.user.last_name}</h4>
                            {userSportProfileType}
                            {
                                item.user.profile_description && item.user.profile_description.length > 0
                                    ? <p className="card-text">{userSportProfileDescription(item.user.profile_description[0].description)}</p>
                                    : <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                            }
                        </div>
                        <div className="card-footer">
                            {
                                item.user.connected_users.length > 0 ?
                                    userConnectedOrNot
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                        onClick={onClickConnect}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUserPlus}
                                            style={{ cursor: 'pointer', marginRight: 10 }}
                                        />
                                        Connect
                                    </button>
                            }
                            {
                                item.user.connected_users && item.user.connected_users.length > 0 && connectedStatus !== 'connected' && pendingStatus !== 'pending' &&
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                    onClick={onClickConnect}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserPlus}
                                        style={{ cursor: 'pointer', marginRight: 10 }}
                                    />
                                    Connect
                                </button>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                null
            );
        }
    });

    return (
        <>
            {userCardInfo}
        </>
    );
}

export const SearchAgentCard = ({ item, currentUser, onClickUser, onClickMessage, onClickConnect }) => {
    // console.log('SPort profile Type:', item.sport_profile_type)

    let connectedStatus;
    let pendingStatus;

    const userSportProfileType = item.sport_profile_type.map((profile) => {
        if (profile.profile_type.toLowerCase() === 'agent') {
            return (
                <h5 className="card-text">{item.sport_type} - {profile.profile_type}</h5>
            );
        } else {
            return (
                null
            );
        }
    })

    const userSportProfileDescription = (desc) => {
        // console.log('Desc: ', desc);
        let profileInfo;
        const description = desc.filter(item => item.profile_type === 'agent');
        if (description && description.length > 0) {
            // const words = desc.split(" ");
            // const profileInfo = words.slice(0, 10).join(" ");
            const words = description[0].description.split(" ");
            profileInfo = words.slice(0, 10).join(" ");
        } else {
            profileInfo = 'No description found'
        }
        return (
            <p className="card-text">{profileInfo}</p>
        );
    }

    const userConnectedOrNot = item.connected_users.map((connectedUser) => {
        if ((connectedUser.connect_to_user === currentUser.user_id) && (connectedUser.status === 'connected')) {
            connectedStatus = 'connected';
            return (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                        onClick={onClickMessage}
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Message
                    </button>
                </>
            );
        } else if ((connectedUser.connect_to_user === currentUser.user_id) && (connectedUser.status === 'pending')) {
            pendingStatus = 'pending';
            return (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                        // onClick={onClickMessage}
                        disabled
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Connect
                    </button>
                </>
            );
        }
    })

    const userCardInfo = item.sport_profile_type.map((profile) => {
        if (profile.profile_type.toLowerCase() === 'agent') {
            return (
                <div className="col" key={item.id}>
                    <div className="card h-100 shadow">
                        <div className='custom-background' style={{ backgroundColor: '#D1D1F2', borderBottomLeftRadius: '20%', borderBottomRightRadius: '20%', }}>
                            {
                                item.profile_photo !== null
                                    ? <img src={item.profile_photo} className="card-img-top shadow" alt="..." style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%', marginTop: 10, }} />
                                    : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%', marginTop: 10, }} />
                            }
                        </div>
                        <div className="card-body">
                            <h4 className="card-title user-search-my-card-title" onClick={onClickUser} style={{ cursor: 'pointer' }}>{item.first_name} {item.last_name}</h4>
                            {userSportProfileType}
                            {
                                item.profile_description && item.profile_description.length > 0
                                    // ? <p className="card-text">{userSportProfileDescription(item.profile_description[0].description)}</p>
                                    ? <p className="card-text">{userSportProfileDescription(item.profile_description)}</p>
                                    : <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                            }
                        </div>
                        <div className="card-footer">
                            {
                                item.connected_users.length > 0 ?
                                    userConnectedOrNot
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                        onClick={onClickConnect}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUserPlus}
                                            style={{ cursor: 'pointer', marginRight: 10 }}
                                        />
                                        Connect
                                    </button>
                            }
                            {
                                item.connected_users && item.connected_users.length > 0 && connectedStatus !== 'connected' && pendingStatus !== 'pending' &&
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                    onClick={onClickConnect}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserPlus}
                                        style={{ cursor: 'pointer', marginRight: 10 }}
                                    />
                                    Connect
                                </button>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                null
            );
        }
    });

    return (
        <>
            {userCardInfo}
        </>
    );
}


export const CustomSearchCard = ({ item, currentUser, onClickUser, onClickMessage, onClickConnect, type }) => {
    // console.log('SPort profile Type:', item.sport_profile_type)

    let connectedStatus;
    let pendingStatus;

    const userSportProfileType = item.user.sport_profile_type.map((profile) => {
        if (profile.profile_type.toLowerCase() === type) {
            return (
                <h5 className="card-text">{item.user.sport_type} - {profile.profile_type}</h5>
            );
        } else {
            return (
                null
            );
        }
    })

    const userSportProfileDescription = (desc) => {
        // console.log('Desc: ', desc);
        const words = desc.split(" ");
        const profileInfo = words.slice(0, 10).join(" ");
        return (
            <p className="card-text">{profileInfo}</p>
        );
    }

    const userConnectedOrNot = item.user.connected_users.map((connectedUser) => {
        if ((connectedUser.connect_to_user === currentUser.user_id) && (connectedUser.status === 'connected')) {
            connectedStatus = 'connected';
            return (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                        onClick={onClickMessage}
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Message
                    </button>
                </>
            );
        } else if ((connectedUser.connect_to_user === currentUser.user_id) && (connectedUser.status === 'pending')) {
            pendingStatus = 'pending';
            return (
                <>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                        // onClick={onClickMessage}
                        disabled
                    >
                        <FontAwesomeIcon
                            icon={faUserPlus}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Connect
                    </button>
                </>
            );
        }
    })

    const userCardInfo = item.user.sport_profile_type.map((profile) => {
        if (profile.profile_type.toLowerCase() === type) {
            return (
                <div className="col" key={item.id}>
                    <div className="card h-100 shadow">
                        <div className='custom-background' style={{ backgroundColor: '#D1D1F2', borderBottomLeftRadius: '20%', borderBottomRightRadius: '20%', }}>
                            {
                                item.user.profile_photo !== null
                                    ? <img src={item.user.profile_photo} className="card-img-top shadow" alt="..." style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%', marginTop: 10, }} />
                                    : <img src={blankPhoto} className="card-img-top shadow" alt="..." style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%', marginTop: 10, }} />
                            }
                        </div>
                        <div className="card-body">
                            <h4 className="card-title user-search-my-card-title" onClick={onClickUser} style={{ cursor: 'pointer' }}>{item.user.first_name} {item.user.last_name}</h4>
                            {userSportProfileType}
                            {
                                item.user.profile_description && item.user.profile_description.length > 0
                                    ? <p className="card-text">{userSportProfileDescription(item.user.profile_description[0].description)}</p>
                                    : <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                            }
                        </div>
                        <div className="card-footer">
                            {
                                item.user.connected_users.length > 0 ?
                                    userConnectedOrNot
                                    :
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                        onClick={onClickConnect}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUserPlus}
                                            style={{ cursor: 'pointer', marginRight: 10 }}
                                        />
                                        Connect
                                    </button>
                            }
                            {
                                item.user.connected_users && item.user.connected_users.length > 0 && connectedStatus !== 'connected' && pendingStatus !== 'pending' &&
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    style={{ width: '100%', fontSize: 14, fontWeight: 600, }}
                                    onClick={onClickConnect}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserPlus}
                                        style={{ cursor: 'pointer', marginRight: 10 }}
                                    />
                                    Connect
                                </button>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                null
            );
        }
    });

    return (
        <>
            {userCardInfo}
        </>
    );
}

export const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <Button
            variant="light"
            className='mt-2'
            onClick={decoratedOnClick}
        >
            {children}
        </Button>
    );
}

export const CustomToggleComment = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <Button
            variant="light"
            onClick={decoratedOnClick}
            style={{ width: '100%' }}
        >
            <FontAwesomeIcon
                icon={faMessage}
                style={{ cursor: 'pointer', marginRight: 10 }}
            // onClick={openComment}
            />
            {children}
        </Button>
    );
}

export const PostCard = ({
    post,
    currentUser,
    isButtonDisabled,
    handleSubmit,
    handleLikes,
    handleDisLikes,
    handleShowLikesModal,
    handleShowCommentsModal,
    onClickMessage,
    onClickConnect,
    onClickUser
}) => {

    // console.log('post ', post);

    let userLikeStatus = '';
    let currentProfile = '';
    // let currentClub = '';
    let connectedUserStatus = '';

    const isoDateString = post.posted;
    const date = new Date(isoDateString);
    const localDateString = date.toLocaleDateString();
    const localTimeString = date.toLocaleTimeString();

    // Function to split text by URLs and render as links
    const renderTextWithLinks = (text) => {
        // text="Visit our site www.example.com or https://another-site.com for more info."
        // Updated regular expression to match URLs starting with http, https, or www
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        // const urlRegex = /(https?:\/\/[^\s]+)/g;

        // Split the text by URLs
        const parts = text.split(urlRegex);

        console.log('text: ', text);

        console.log('parts: ', parts);

        // Map through the parts and render as link if it's a URL
        return parts.map((part, index) => {
            if (urlRegex.test(part)) {
                // If URL does not have http or https, add https as default
                const formattedUrl = part.startsWith('http') ? part : `https://${part}`;
                return (
                    <a key={index} href={formattedUrl} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            }
            return part; // Otherwise, render as plain text
        });
    }


    post.user.sport_profile_type.forEach((type) => {
        if (type.is_active) {
            // currentProfile = '- ' + type.profile_type;
            currentProfile = type.profile_type;
            // if (type.profile_type.toLowerCase() === 'player') {
            //     post.user.player.forEach((player) => {
            //         currentClub = 'at ' + player.current_club_inside_name;
            //     });
            // } else if (type.profile_type.toLowerCase() === 'coach') {
            //     post.user.coach.forEach((coach) => {
            //         currentClub = 'at ' + coach.current_team;
            //     });
            // }
        }
    });

    const loadMoreComments = post.comments.slice(2, 4).map((postedComments) => {

        const isoDateString = postedComments.posted;
        const date = new Date(isoDateString);
        const localDateString = date.toLocaleDateString();
        const localTimeString = date.toLocaleTimeString();

        let currentProfile = '';
        // let currentClub = '';

        postedComments.user.sport_profile_type.forEach((type) => {
            if (type.is_active) {
                currentProfile = type.profile_type;
                // if (currentProfile.toLowerCase() === 'player') {
                //     postedComments.user.player.forEach((player) => {
                //         currentClub = 'at ' + player.current_club_inside_name;
                //     });
                // } else if (currentProfile.toLowerCase() === 'coach') {
                //     postedComments.user.coach.forEach((coach) => {
                //         currentClub = 'at ' + coach.current_team;
                //     });
                // }
            }
        });

        return (
            <Stack direction="horizontal" gap={2} className='mt-2'>
                <Image src={postedComments.user.profile_photo} roundedCircle style={{ height: 35, width: 35 }} />
                <div style={{ backgroundColor: 'whitesmoke', width: '100%', padding: 5, borderRadius: 5 }}>
                    <div className='justify-content-between d-flex' style={{ color: 'black' }}>
                        {postedComments.user.account_type === 'institute' ?
                            <span>{postedComments.user.club_name}</span> :
                            <span>{postedComments.user.first_name} {postedComments.user.last_name}</span>
                        }
                        <span>{localDateString} {localTimeString}</span>
                    </div>
                    <div className='mb-2 text-muted'>
                        {/* <span>Player at FC barcelona</span> */}
                        {/* <span>{currentProfile} {currentClub}</span> */}
                        <span>{currentProfile}</span>
                    </div>
                    <span style={{ color: 'black' }}>{postedComments.comment}</span>
                </div>
            </Stack>
        )
    })

    const likesPosted = post.likes.map((postedlikes) => {
        if (postedlikes.user.id === currentUser.user_id) {
            userLikeStatus = 'found';
            return (
                <Button variant="light" style={{ width: '100%' }} onClick={() => handleDisLikes(postedlikes.id)} disabled={isButtonDisabled}>
                    <FontAwesomeIcon
                        icon={faThumbsUp}
                        style={{ cursor: 'pointer', marginRight: 10, color: '#0d6efd' }}
                    />
                    Like
                </Button>
            )
        }
    })

    const commentsPosted = post.comments.slice(0, 2).map((postedComments) => {

        const isoDateString = postedComments.posted;
        const date = new Date(isoDateString);
        const localDateString = date.toLocaleDateString();
        const localTimeString = date.toLocaleTimeString();

        let currentProfile = '';
        // let currentClub = '';

        postedComments.user.sport_profile_type.forEach((type) => {
            if (type.is_active) {
                currentProfile = type.profile_type;
                // if (currentProfile.toLowerCase() === 'player') {
                //     postedComments.user.player.forEach((player) => {
                //         currentClub = 'at ' + player.current_club_inside_name;
                //     });
                // } else if (currentProfile.toLowerCase() === 'coach') {
                //     postedComments.user.coach.forEach((coach) => {
                //         currentClub = 'at ' + coach.current_team;
                //     });
                // }
            }
        });

        return (
            <Stack direction="horizontal" gap={2} className='mt-2'>
                <Image src={postedComments.user.profile_photo} roundedCircle style={{ height: 35, width: 35 }} />
                <div style={{ backgroundColor: 'whitesmoke', width: '100%', padding: 5, borderRadius: 5 }}>
                    <div className='justify-content-between d-flex' style={{ color: 'black' }}>
                        {postedComments.user.account_type === 'institute' ?
                            <span>{postedComments.user.club_name}</span> :
                            <span>{postedComments.user.first_name} {postedComments.user.last_name}</span>
                        }
                        <span>{localDateString} {localTimeString}</span>
                    </div>
                    <div className='mb-2 text-muted'>
                        {/* <span>Player at FC barcelona</span> */}
                        {/* <span>{currentProfile} {currentClub}</span> */}
                        <span>{currentProfile}</span>
                    </div>
                    <span style={{ color: 'black' }}>{postedComments.comment}</span>
                </div>
            </Stack>
        )
    })

    const privatePost = post.user.connected_users.map((connectedUser) => {
        if ((connectedUser.connect_to_user.id === currentUser.user_id) && (connectedUser.status === 'connected')) {
            return (
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Body>
                            <div className='d-flex row-flex justify-content-between'>
                                <div className='d-flex row-flex'>
                                    <div>
                                        <Card.Img variant="top" src={post.user.profile_photo} style={{ borderRadius: '50%', width: 40, height: 40 }} />
                                    </div>
                                    <div className='ms-2'>
                                        {post.user.account_type === 'institute' ?
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.club_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle>
                                            </>
                                            :
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.first_name} {post.user.last_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                            </>
                                        }
                                        {/* {post.user.account_type === 'institute' ?
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle> :
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                        } */}
                                        <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{localDateString} {localTimeString}</Card.Subtitle>
                                    </div>
                                </div>
                                <div>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.type}</Card.Subtitle>
                                </div>
                            </div>
                            <Card.Text>
                                {/* {post.description} */}
                                {renderTextWithLinks(post.description)}
                            </Card.Text>
                            {post.picture &&
                                <Card.Img variant="bottom" src={post.picture} />
                            }
                            <div className='d-flex justify-content-between'>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowLikesModal}>
                                    <FontAwesomeIcon
                                        icon={faThumbsUp}
                                        style={{ cursor: 'pointer', marginRight: 10, color: '#0d6efd' }}
                                    />
                                    {post.likes.length} likes
                                </Card.Text>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowCommentsModal}>
                                    {post.comments.length} comments
                                </Card.Text>
                            </div>
                            <Card.Footer className="text-muted" style={{ backgroundColor: '#fff' }}>
                                <Row>
                                    <Col>
                                        {post.likes.length > 0 ?
                                            likesPosted
                                            : <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                        {userLikeStatus !== 'found' && post.likes.length > 0 &&
                                            <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                    </Col>
                                    <Col>
                                        <CustomToggleComment eventKey="0">Comments</CustomToggleComment>
                                    </Col>
                                </Row>
                                <Accordion.Collapse eventKey="0">
                                    <>
                                        <Stack direction="horizontal" gap={2} className='mt-3'>
                                            <Image src={BlankProfilePicture} roundedCircle style={{ height: 35 }} />
                                            <div style={{ width: '100%' }}>
                                                <Form onSubmit={handleSubmit}>
                                                    <div className='d-flex'>
                                                        <Form.Control className="me-auto" placeholder="Add your comment here..." name='comment' required />
                                                        <Button variant="primary" className='ms-1' type='submit'>
                                                            <FontAwesomeIcon
                                                                icon={faPaperPlane}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Stack>

                                        {commentsPosted}

                                        {post.comments.length > 2 &&
                                            <Accordion>
                                                <CustomToggle eventKey="0">Load more comments</CustomToggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        {loadMoreComments}
                                                        <Button variant="light" className='mt-2' onClick={handleShowCommentsModal}>
                                                            See All
                                                        </Button>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        }
                                    </>
                                </Accordion.Collapse>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Accordion>
            );
        } else {
            return null
        }
    })

    const userConnectedOrNot = post.user.connected_users.map((connectedUser) => {
        if ((connectedUser.connect_to_user.id === currentUser.user_id) && (connectedUser.status === 'pending')) {
            connectedUserStatus = 'pending';
            return (
                <>
                    <Button
                        onClick={onClickConnect}
                        disabled
                    >
                        Request Pending
                    </Button>
                </>
            );
        }
        else if ((connectedUser.connect_to_user.id === currentUser.user_id) && (connectedUser.status === 'connected')) {
            connectedUserStatus = 'connected';
            return (
                <>
                    <Button
                        onClick={onClickConnect}
                        disabled
                    >
                        Connected
                    </Button>
                </>
            );
        }
    })

    if (post.type === 'public') {
        if (post.user.id !== currentUser.user_id) {
            return (
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Body>
                            <div className='d-flex row-flex justify-content-between'>
                                <div className='d-flex row-flex'>
                                    <div>
                                        <Card.Img variant="top" src={post.user.profile_photo} style={{ borderRadius: '50%', width: 40, height: 40 }} />
                                    </div>
                                    <div className='ms-2'>
                                        {post.user.account_type === 'institute' ?
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.club_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle>
                                            </>
                                            :
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.first_name} {post.user.last_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                            </>
                                        }
                                        {/* {post.user.account_type === 'institute' ?
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle> :
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                        } */}
                                        <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{localDateString} {localTimeString}</Card.Subtitle>
                                    </div>
                                </div>
                                <div>
                                    <Stack direction="horizontal" gap={3}>
                                        <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.type}</Card.Subtitle>
                                        <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.post_type !== '' ? post.post_type : ''}</Card.Subtitle>
                                        {post.user.connected_users && post.user.connected_users.length > 0 ?
                                            userConnectedOrNot :
                                            <Button
                                                onClick={onClickConnect}
                                            >
                                                <span style={{ fontSize: 14, fontWeight: 'bold' }}>+</span> Connect
                                            </Button>
                                        }
                                        {connectedUserStatus !== 'pending' && connectedUserStatus !== 'connected' && post.user.connected_users && post.user.connected_users.length > 0 &&
                                            <Button
                                                onClick={onClickConnect}
                                            >
                                                <span style={{ fontSize: 14, fontWeight: 'bold' }}>+</span> Connect
                                            </Button>
                                        }
                                    </Stack>
                                </div>
                            </div>
                            <Card.Text>
                                {/* {post.description} */}
                                {renderTextWithLinks(post.description)}
                            </Card.Text>
                            {post.picture &&
                                <Card.Img variant="bottom" src={post.picture} />
                            }
                            {post.video_link && (
                                <ReactPlayer width={"100%"} url={post.video_link} />
                            )
                            }
                            <div className='d-flex justify-content-between'>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowLikesModal}>
                                    <FontAwesomeIcon
                                        icon={faThumbsUp}
                                        style={{ cursor: 'pointer', marginRight: 10, color: '#0d6efd' }}
                                    />
                                    {post.likes.length} likes
                                </Card.Text>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowCommentsModal}>
                                    {post.comments.length} comments
                                </Card.Text>
                            </div>
                            <Card.Footer className="text-muted" style={{ backgroundColor: '#fff' }}>
                                <Row>
                                    <Col>
                                        {post.likes.length > 0 ?
                                            likesPosted
                                            : <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                        {userLikeStatus !== 'found' && post.likes.length > 0 &&
                                            <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                    </Col>
                                    <Col>
                                        <CustomToggleComment eventKey="0">Comments</CustomToggleComment>
                                    </Col>
                                </Row>
                                <Accordion.Collapse eventKey="0">
                                    <>
                                        <Stack direction="horizontal" gap={2} className='mt-3'>
                                            <Image src={BlankProfilePicture} roundedCircle style={{ height: 35 }} />
                                            <div style={{ width: '100%' }}>
                                                <Form onSubmit={handleSubmit}>
                                                    <div className='d-flex'>
                                                        <Form.Control className="me-auto" placeholder="Add your comment here..." name='comment' required />
                                                        <Button variant="primary" className='ms-1' type='submit'>
                                                            <FontAwesomeIcon
                                                                icon={faPaperPlane}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Stack>

                                        {commentsPosted}

                                        {post.comments.length > 2 &&
                                            <Accordion>
                                                <CustomToggle eventKey="0">Load more comments</CustomToggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        {loadMoreComments}
                                                        <Button variant="light" className='mt-2' onClick={handleShowCommentsModal}>
                                                            See All
                                                        </Button>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        }
                                    </>
                                </Accordion.Collapse>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Accordion>
            );
        }
        else {
            return (
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Body>
                            <div className='d-flex row-flex justify-content-between'>
                                <div className='d-flex row-flex'>
                                    <div>
                                        <Card.Img variant="top" src={post.user.profile_photo} style={{ borderRadius: '50%', width: 40, height: 40 }} />
                                    </div>
                                    <div className='ms-2'>
                                        {post.user.account_type === 'institute' ?
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.club_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle>
                                            </> :
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.first_name} {post.user.last_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                            </>
                                        }
                                        {/* {post.user.account_type === 'institute' ?
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle> :
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                        } */}
                                        <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{localDateString} {localTimeString}</Card.Subtitle>
                                    </div>
                                </div>
                                <Stack direction="horizontal" gap={2}>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.type}</Card.Subtitle>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.post_type !== '' ? post.post_type : ''}</Card.Subtitle>
                                </Stack>
                            </div>
                            <Card.Text>
                                {/* {post.description} */}
                                {renderTextWithLinks(post.description)}
                            </Card.Text>
                            {post.picture &&
                                <Card.Img variant="bottom" src={post.picture} />
                            }
                            {post.video_link && (
                                <ReactPlayer width={"100%"} url={post.video_link} />
                            )
                            }
                            <div className='d-flex justify-content-between'>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowLikesModal}>
                                    <FontAwesomeIcon
                                        icon={faThumbsUp}
                                        style={{ cursor: 'pointer', marginRight: 10, color: '#0d6efd' }}
                                    />
                                    {post.likes.length} likes
                                </Card.Text>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowCommentsModal}>
                                    {post.comments.length} comments
                                </Card.Text>
                            </div>
                            <Card.Footer className="text-muted" style={{ backgroundColor: '#fff' }}>
                                <Row>
                                    <Col>
                                        {post.likes.length > 0 ?
                                            likesPosted
                                            : <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                        {userLikeStatus !== 'found' && post.likes.length > 0 &&
                                            <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                    </Col>
                                    <Col>
                                        <CustomToggleComment eventKey="0">Comments</CustomToggleComment>
                                    </Col>
                                </Row>
                                <Accordion.Collapse eventKey="0">
                                    <>
                                        <Stack direction="horizontal" gap={2} className='mt-3'>
                                            <Image src={BlankProfilePicture} roundedCircle style={{ height: 35 }} />
                                            <div style={{ width: '100%' }}>
                                                <Form onSubmit={handleSubmit}>
                                                    <div className='d-flex'>
                                                        <Form.Control className="me-auto" placeholder="Add your comment here..." name='comment' required />
                                                        <Button variant="primary" className='ms-1' type='submit'>
                                                            <FontAwesomeIcon
                                                                icon={faPaperPlane}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Stack>

                                        {commentsPosted}

                                        {post.comments.length > 2 &&
                                            <Accordion>
                                                <CustomToggle eventKey="0">Load more comments</CustomToggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        {loadMoreComments}
                                                        <Button variant="light" className='mt-2' onClick={handleShowCommentsModal}>
                                                            See All
                                                        </Button>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        }
                                    </>
                                </Accordion.Collapse>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Accordion>
            );
        }
    } else {
        if (post.user.id === currentUser.user_id)
            return (
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Body>
                            <div className='d-flex row-flex justify-content-between'>
                                <div className='d-flex row-flex'>
                                    <div>
                                        <Card.Img variant="top" src={post.user.profile_photo} style={{ borderRadius: '50%', width: 40, height: 40 }} />
                                    </div>
                                    <div className='ms-2'>
                                        {post.user.account_type === 'institute' ?
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.club_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle>
                                            </>
                                            :
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.first_name} {post.user.last_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                            </>
                                        }
                                        {/* {post.user.account_type === 'institute' ?
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle> :
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                        } */}
                                        <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{localDateString} {localTimeString}</Card.Subtitle>
                                    </div>
                                </div>
                                <Stack direction="horizontal" gap={2}>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.type}</Card.Subtitle>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.post_type !== '' ? post.post_type : ''}</Card.Subtitle>
                                </Stack>
                            </div>
                            <Card.Text>
                                {/* {post.description} */}
                                {renderTextWithLinks(post.description)}
                            </Card.Text>
                            {post.picture &&
                                <Card.Img variant="bottom" src={post.picture} />
                            }
                            <div className='d-flex justify-content-between'>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowLikesModal}>
                                    <FontAwesomeIcon
                                        icon={faThumbsUp}
                                        style={{ cursor: 'pointer', marginRight: 10, color: '#0d6efd' }}
                                    />
                                    {post.likes.length} likes
                                </Card.Text>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowCommentsModal}>
                                    {post.comments.length} comments
                                </Card.Text>
                            </div>
                            <Card.Footer className="text-muted" style={{ backgroundColor: '#fff' }}>
                                <Row>
                                    <Col>
                                        {post.likes.length > 0 ?
                                            likesPosted
                                            : <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                        {userLikeStatus !== 'found' && post.likes.length > 0 &&
                                            <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                    </Col>
                                    <Col>
                                        <CustomToggleComment eventKey="0">Comments</CustomToggleComment>
                                    </Col>
                                </Row>
                                <Accordion.Collapse eventKey="0">
                                    <>
                                        <Stack direction="horizontal" gap={2} className='mt-3'>
                                            <Image src={BlankProfilePicture} roundedCircle style={{ height: 35 }} />
                                            <div style={{ width: '100%' }}>
                                                <Form onSubmit={handleSubmit}>
                                                    <div className='d-flex'>
                                                        <Form.Control className="me-auto" placeholder="Add your comment here..." name='comment' required />
                                                        <Button variant="primary" className='ms-1' type='submit'>
                                                            <FontAwesomeIcon
                                                                icon={faPaperPlane}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Stack>

                                        {commentsPosted}

                                        {post.comments.length > 2 &&
                                            <Accordion>
                                                <CustomToggle eventKey="0">Load more comments</CustomToggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        {loadMoreComments}
                                                        <Button variant="light" className='mt-2' onClick={handleShowCommentsModal}>
                                                            See All
                                                        </Button>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        }
                                    </>
                                </Accordion.Collapse>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Accordion>
            );
        else
            return (
                <>
                    {privatePost}
                </>
            );
    }

}

export const CustomBottomNavbar = ({ title, subdomain, menuItem }) => {
    const home = `/${subdomain}/home`;
    const profile = `/${subdomain}/profile`;
    return (
        <Navbar expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand as={Link} to={home}>{title}</Navbar.Brand>
                {menuItem &&
                    <Nav className="justify-content-end" variant="underline">
                        <Nav.Link as={NavLink} to={home}>View Profile</Nav.Link>
                        <Nav.Link as={NavLink} to={profile} state={{ login: 'random' }}>Edit Profile</Nav.Link>
                    </Nav>
                }
            </Container>
        </Navbar>
    )
}

export const CustomEventCard = ({ item, onClickEvent }) => {

    const words = item.content.split(" ");
    const desc = words.slice(0, 15).join(" ");

    const isoDateString = item.posted;
    const date = new Date(isoDateString);
    const localDateString = date.toLocaleDateString();
    const localTimeString = date.toLocaleTimeString();

    return (
        <div className="col" key={item.id}>
            {/* <div className="card h-100 shadow">
                <div className="card-body">
                    
                    <div className="card-title">{item.title}</div>

                </div>
            </div> */}
            <Card className='shadow h-100' style={{ width: '18rem' }}>
                {item.picture !== null ?
                    <Card.Img as={Image} variant="top" src={`${BASE_MEDIA_URL}${item.picture}`} style={{ height: 130, objectFit: 'cover' }} thumbnail />
                    : <Card.Img as={Image} variant="top" src={BlankProfilePicture} style={{ height: 130, objectFit: 'cover' }} thumbnail />
                }
                <Card.Body>
                    <Card.Title onClick={onClickEvent}>{item.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Posted: {localDateString} at {localTimeString}</Card.Subtitle>
                    <Card.Text>{desc}...</Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        {/* <Button className='float-left'>Export to csv</Button> */}
        <Form.Control
            id="search"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            className='w-25 mx-1'
            value={filterText}
            onChange={onFilter}
        />
        <Button variant="primary" onClick={onClear}>
            Reset
        </Button>
    </>
);

export const MyPostCard = ({
    post,
    currentUser,
    isButtonDisabled,
    handleSubmit,
    handleLikes,
    handleDisLikes,
    handleShowLikesModal,
    handleShowCommentsModal,
    onClickUser
}) => {

    let userLikeStatus = '';
    let currentProfile = '';
    // let currentClub = '';
    let connectedUserStatus = '';

    const isoDateString = post.posted;
    const date = new Date(isoDateString);
    const localDateString = date.toLocaleDateString();
    const localTimeString = date.toLocaleTimeString();

    // Function to split text by URLs and render as links
    const renderTextWithLinks = (text) => {
        // text="Visit our site www.example.com or https://another-site.com for more info."
        // Updated regular expression to match URLs starting with http, https, or www
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        // const urlRegex = /(https?:\/\/[^\s]+)/g;

        // Split the text by URLs
        const parts = text.split(urlRegex);

        console.log('text: ', text);

        console.log('parts: ', parts);

        // Map through the parts and render as link if it's a URL
        return parts.map((part, index) => {
            if (urlRegex.test(part)) {
                // If URL does not have http or https, add https as default
                const formattedUrl = part.startsWith('http') ? part : `https://${part}`;
                return (
                    <a key={index} href={formattedUrl} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            }
            return part; // Otherwise, render as plain text
        });
    }

    post.user.sport_profile_type.forEach((type) => {
        if (type.is_active) {
            // currentProfile = '- ' + type.profile_type;
            currentProfile = type.profile_type;
            // if (type.profile_type.toLowerCase() === 'player') {
            //     post.user.player.forEach((player) => {
            //         currentClub = 'at ' + player.current_club_inside_name;
            //     });
            // } else if (type.profile_type.toLowerCase() === 'coach') {
            //     post.user.coach.forEach((coach) => {
            //         currentClub = 'at ' + coach.current_team;
            //     });
            // }
        }
    });

    const loadMoreComments = post.comments.slice(2, 4).map((postedComments) => {

        const isoDateString = postedComments.posted;
        const date = new Date(isoDateString);
        const localDateString = date.toLocaleDateString();
        const localTimeString = date.toLocaleTimeString();

        let currentProfile = '';
        // let currentClub = '';

        postedComments.user.sport_profile_type.forEach((type) => {
            if (type.is_active) {
                currentProfile = type.profile_type;
                // if (currentProfile.toLowerCase() === 'player') {
                //     postedComments.user.player.forEach((player) => {
                //         currentClub = 'at ' + player.current_club_inside_name;
                //     });
                // } else if (currentProfile.toLowerCase() === 'coach') {
                //     postedComments.user.coach.forEach((coach) => {
                //         currentClub = 'at ' + coach.current_team;
                //     });
                // }
            }
        });

        return (
            <Stack direction="horizontal" gap={2} className='mt-2'>
                <Image src={postedComments.user.profile_photo} roundedCircle style={{ height: 35, width: 35 }} />
                <div style={{ backgroundColor: 'whitesmoke', width: '100%', padding: 5, borderRadius: 5 }}>
                    <div className='justify-content-between d-flex' style={{ color: 'black' }}>
                        {postedComments.user.account_type === 'institute' ?
                            <span>{postedComments.user.club_name}</span> :
                            <span>{postedComments.user.first_name} {postedComments.user.last_name}</span>
                        }
                        <span>{localDateString} {localTimeString}</span>
                    </div>
                    <div className='mb-2 text-muted'>
                        {/* <span>Player at FC barcelona</span> */}
                        {/* <span>{currentProfile} {currentClub}</span> */}
                        <span>{currentProfile}</span>
                    </div>
                    <span style={{ color: 'black' }}>{postedComments.comment}</span>
                </div>
            </Stack>
        )
    })

    const likesPosted = post.likes.map((postedlikes) => {
        if (postedlikes.user.id === currentUser.user_id) {
            userLikeStatus = 'found';
            return (
                <Button variant="light" style={{ width: '100%' }} onClick={() => handleDisLikes(postedlikes.id)} disabled={isButtonDisabled}>
                    <FontAwesomeIcon
                        icon={faThumbsUp}
                        style={{ cursor: 'pointer', marginRight: 10, color: '#0d6efd' }}
                    />
                    Like
                </Button>
            )
        }
    })

    const commentsPosted = post.comments.slice(0, 2).map((postedComments) => {

        const isoDateString = postedComments.posted;
        const date = new Date(isoDateString);
        const localDateString = date.toLocaleDateString();
        const localTimeString = date.toLocaleTimeString();

        let currentProfile = '';
        // let currentClub = '';

        postedComments.user.sport_profile_type.forEach((type) => {
            if (type.is_active) {
                currentProfile = type.profile_type;
                // if (currentProfile.toLowerCase() === 'player') {
                //     postedComments.user.player.forEach((player) => {
                //         currentClub = 'at ' + player.current_club_inside_name;
                //     });
                // } else if (currentProfile.toLowerCase() === 'coach') {
                //     postedComments.user.coach.forEach((coach) => {
                //         currentClub = 'at ' + coach.current_team;
                //     });
                // }
            }
        });

        return (
            <Stack direction="horizontal" gap={2} className='mt-2'>
                <Image src={postedComments.user.profile_photo} roundedCircle style={{ height: 35, width: 35 }} />
                <div style={{ backgroundColor: 'whitesmoke', width: '100%', padding: 5, borderRadius: 5 }}>
                    <div className='justify-content-between d-flex' style={{ color: 'black' }}>
                        {postedComments.user.account_type === 'institute' ?
                            <span>{postedComments.user.club_name}</span> :
                            <span>{postedComments.user.first_name} {postedComments.user.last_name}</span>
                        }
                        <span>{localDateString} {localTimeString}</span>
                    </div>
                    <div className='mb-2 text-muted'>
                        {/* <span>Player at FC barcelona</span> */}
                        {/* <span>{currentProfile} {currentClub}</span> */}
                        <span>{currentProfile}</span>
                    </div>
                    <span style={{ color: 'black' }}>{postedComments.comment}</span>
                </div>
            </Stack>
        )
    })

    if (post.type === 'public') {
        if (post.user.id === currentUser.user_id) {
            return (
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Body>
                            <div className='d-flex row-flex justify-content-between'>
                                <div className='d-flex row-flex'>
                                    <div>
                                        <Card.Img variant="top" src={post.user.profile_photo} style={{ borderRadius: '50%', width: 40, height: 40 }} />
                                    </div>
                                    <div className='ms-2'>
                                        {post.user.account_type === 'institute' ?
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.club_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle>
                                            </>
                                            :
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.first_name} {post.user.last_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                            </>
                                        }
                                        {/* {post.user.account_type === 'institute' ?
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle> :
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                        } */}
                                        <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{localDateString} {localTimeString}</Card.Subtitle>
                                    </div>
                                </div>
                                <Stack direction="horizontal" gap={2}>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.type}</Card.Subtitle>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.post_type !== '' ? post.post_type : ''}</Card.Subtitle>
                                </Stack>
                            </div>
                            <Card.Text>
                                {renderTextWithLinks(post.description)}
                            </Card.Text>
                            {post.picture &&
                                <Card.Img variant="bottom" src={post.picture} />
                            }
                            {post.video_link && (
                                <ReactPlayer width={"100%"} url={post.video_link} />
                            )
                            }
                            <div className='d-flex justify-content-between'>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowLikesModal}>
                                    <FontAwesomeIcon
                                        icon={faThumbsUp}
                                        style={{ cursor: 'pointer', marginRight: 10, color: '#0d6efd' }}
                                    />
                                    {post.likes.length} likes
                                </Card.Text>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowCommentsModal}>
                                    {post.comments.length} comments
                                </Card.Text>
                            </div>
                            <Card.Footer className="text-muted" style={{ backgroundColor: '#fff' }}>
                                <Row>
                                    <Col>
                                        {post.likes.length > 0 ?
                                            likesPosted
                                            : <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                        {userLikeStatus !== 'found' && post.likes.length > 0 &&
                                            <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                    </Col>
                                    <Col>
                                        <CustomToggleComment eventKey="0">Comments</CustomToggleComment>
                                    </Col>
                                </Row>
                                <Accordion.Collapse eventKey="0">
                                    <>
                                        <Stack direction="horizontal" gap={2} className='mt-3'>
                                            <Image src={BlankProfilePicture} roundedCircle style={{ height: 35 }} />
                                            <div style={{ width: '100%' }}>
                                                <Form onSubmit={handleSubmit}>
                                                    <div className='d-flex'>
                                                        <Form.Control className="me-auto" placeholder="Add your comment here..." name='comment' required />
                                                        <Button variant="primary" className='ms-1' type='submit'>
                                                            <FontAwesomeIcon
                                                                icon={faPaperPlane}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Stack>

                                        {commentsPosted}

                                        {post.comments.length > 2 &&
                                            <Accordion>
                                                <CustomToggle eventKey="0">Load more comments</CustomToggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        {loadMoreComments}
                                                        <Button variant="light" className='mt-2' onClick={handleShowCommentsModal}>
                                                            See All
                                                        </Button>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        }
                                    </>
                                </Accordion.Collapse>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Accordion>
            );
        }
    } else {
        if (post.user.id === currentUser.user_id)
            return (
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Body>
                            <div className='d-flex row-flex justify-content-between'>
                                <div className='d-flex row-flex'>
                                    <div>
                                        <Card.Img variant="top" src={post.user.profile_photo} style={{ borderRadius: '50%', width: 40, height: 40 }} />
                                    </div>
                                    <div className='ms-2'>
                                        {post.user.account_type === 'institute' ?
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.club_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle>
                                            </>
                                            :
                                            <>
                                                <Card.Title className='card-title' onClick={onClickUser}>{post.user.first_name} {post.user.last_name}</Card.Title>
                                                <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                            </>
                                        }
                                        {/* {post.user.account_type === 'institute' ?
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{currentProfile}</Card.Subtitle> :
                                            <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{post.user.sport_type + ' - ' + currentProfile}</Card.Subtitle>
                                        } */}
                                        <Card.Subtitle className="mb-1 text-muted" style={{ fontSize: 11 }}>{localDateString} {localTimeString}</Card.Subtitle>
                                    </div>
                                </div>
                                <Stack direction="horizontal" gap={2}>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.type}</Card.Subtitle>
                                    <Card.Subtitle className="text-muted" style={{ fontSize: 12, fontWeight: 'bold' }}>{post.post_type !== '' ? post.post_type : ''}</Card.Subtitle>
                                </Stack>
                            </div>
                            <Card.Text>
                                {renderTextWithLinks(post.description)}
                            </Card.Text>
                            {post.picture &&
                                <Card.Img variant="bottom" src={post.picture} />
                            }
                            <div className='d-flex justify-content-between'>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowLikesModal}>
                                    <FontAwesomeIcon
                                        icon={faThumbsUp}
                                        style={{ cursor: 'pointer', marginRight: 10, color: '#0d6efd' }}
                                    />
                                    {post.likes.length} likes
                                </Card.Text>
                                <Card.Text className="mb-2 mt-2 card-like-comments" onClick={handleShowCommentsModal}>
                                    {post.comments.length} comments
                                </Card.Text>
                            </div>
                            <Card.Footer className="text-muted" style={{ backgroundColor: '#fff' }}>
                                <Row>
                                    <Col>
                                        {post.likes.length > 0 ?
                                            likesPosted
                                            : <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                        {userLikeStatus !== 'found' && post.likes.length > 0 &&
                                            <Button variant="light" style={{ width: '100%' }} onClick={handleLikes} disabled={isButtonDisabled}>
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                                />
                                                Like
                                            </Button>
                                        }
                                    </Col>
                                    <Col>
                                        <CustomToggleComment eventKey="0">Comments</CustomToggleComment>
                                    </Col>
                                </Row>
                                <Accordion.Collapse eventKey="0">
                                    <>
                                        <Stack direction="horizontal" gap={2} className='mt-3'>
                                            <Image src={BlankProfilePicture} roundedCircle style={{ height: 35 }} />
                                            <div style={{ width: '100%' }}>
                                                <Form onSubmit={handleSubmit}>
                                                    <div className='d-flex'>
                                                        <Form.Control className="me-auto" placeholder="Add your comment here..." name='comment' required />
                                                        <Button variant="primary" className='ms-1' type='submit'>
                                                            <FontAwesomeIcon
                                                                icon={faPaperPlane}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Stack>

                                        {commentsPosted}

                                        {post.comments.length > 2 &&
                                            <Accordion>
                                                <CustomToggle eventKey="0">Load more comments</CustomToggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        {loadMoreComments}
                                                        <Button variant="light" className='mt-2' onClick={handleShowCommentsModal}>
                                                            See All
                                                        </Button>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        }
                                    </>
                                </Accordion.Collapse>
                            </Card.Footer>
                        </Card.Body>
                    </Card>
                </Accordion>
            );
    }

}

export const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;
// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Login from './pages/Login';
import Home from './pages/Home';
import Network from './pages/Network';
import Messaging from './pages/Messaging';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import EditProfile from './pages/EditProfile';
import EditCoachProfile from './pages/coach/EditProfile';
import EditPlayerProfile from './pages/player/EditProfile';
import { ChatContextProvider } from './context/ChatContext';
import { NetworkContextProvider } from './context/NetworkContext';
import Events from './components/Events';
import NetworkConnected from './components/NetworkConnected';
import SearchAll from './pages/SearchAll';
import News from './pages/News';
import EditAgentProfile from './pages/agent/EditProfile';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import AdvanceFilter from './pages/AdvanceFilter';
import Opportunaties from './pages/Opportunaties';
import Help from './pages/Help';
import Wellness from './pages/Wellness';
import Conditioning from './pages/Conditioning';
import ViewProfile from './pages/ViewProfile';
import Endosements from './pages/Endosements';
import MyPost from './pages/MyPost';
import { EndorsementsApproved } from './pages/EndorsementsApproved';
import { EndorsementsRejected } from './pages/EndorsementsRejected';
import ChooseProfile from './pages/ChooseProfile';
import EditProfileInstituition from './pages/EditProfileInstituition';
import EditInstituitionInfoProfile from './pages/instituition/EditProfile';
import { Register } from './pages/Register';
import { ViewProfileInstitution } from './pages/ViewProfileInstitution';
import { ViewUserProfile } from './pages/ViewUserProfile';
import { ViewInstituteProfile } from './pages/ViewInstituteProfile';
import AdminHome from './pages/admin/AdminHome';
import AdminInstitute from './pages/admin/AdminInstitute';
import AdminLogin from './pages/admin/AdminLogin';
import PrivateRouteAdmin from './utils/PrivateRouteAdmin';

function App() {
  return (
    <Router>
      <AuthProvider>
        <NetworkContextProvider>
          <ChatContextProvider>
            <Routes>
              <Route index path="/" element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
              />
              <Route path="/wellness" element={
                <PrivateRoute>
                  <Wellness />
                </PrivateRoute>
              }
              />
              <Route path="/conditioning" element={
                <PrivateRoute>
                  <Conditioning />
                </PrivateRoute>
              }
              />
              <Route path="/opportunaties" element={
                <PrivateRoute>
                  <Opportunaties />
                </PrivateRoute>
              }
              />
              <Route path="/help" element={
                <PrivateRoute>
                  <Help />
                </PrivateRoute>
              }
              />
              <Route path="/endorsements" element={
                <PrivateRoute>
                  <Endosements />
                </PrivateRoute>
              }
              />
              <Route path="/endorsements">
                <Route path="pending" element={
                  <PrivateRoute>
                    <Endosements />
                  </PrivateRoute>
                }
                />
                <Route path="approved" element={
                  <PrivateRoute>
                    <EndorsementsApproved />
                  </PrivateRoute>
                }
                />
                <Route path="rejected" element={
                  <PrivateRoute>
                    <EndorsementsRejected />
                  </PrivateRoute>
                }
                />
              </Route>
              <Route path="/mypost" element={
                <PrivateRoute>
                  <MyPost />
                </PrivateRoute>
              }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/user" element={
                <PrivateRouteAdmin>
                  <AdminHome />
                </PrivateRouteAdmin>
              }
              />
              <Route path="/admin/institute" element={
                <PrivateRouteAdmin>
                  <AdminInstitute />
                </PrivateRouteAdmin>
              }
              />
              <Route path="/chooseprofile" element={
                <PrivateRoute>
                  <ChooseProfile />
                </PrivateRoute>
              }
              />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/changepassword" element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
              />
              <Route path="/network">
                <Route path="connect" element={
                  <PrivateRoute>
                    <Network />
                  </PrivateRoute>
                }
                />
                <Route path="connected" element={
                  <PrivateRoute>
                    <NetworkConnected />
                  </PrivateRoute>
                }
                />
                <Route path="events" element={
                  <PrivateRoute>
                    <Events />
                  </PrivateRoute>
                }
                />
              </Route>
              <Route path="/messaging" element={
                <PrivateRoute>
                  <Messaging />
                </PrivateRoute>
              }
              />
              <Route path="/editprofile" element={
                <PrivateRoute>
                  <EditProfile />
                </PrivateRoute>
              }
              />
              <Route path="/editprofileinstituition" element={
                <PrivateRoute>
                  <EditProfileInstituition />
                </PrivateRoute>
              }
              />
              <Route path="/viewprofile" element={
                <PrivateRoute>
                  <ViewProfile />
                </PrivateRoute>
              }
              />
              <Route path="/viewprofileinstituition" element={
                <PrivateRoute>
                  <ViewProfileInstitution />
                </PrivateRoute>
              }
              />

              <Route path="/user/viewprofile" element={
                <PrivateRoute>
                  <ViewUserProfile />
                </PrivateRoute>
              }
              />
              <Route path="/institute/viewprofile" element={
                <PrivateRoute>
                  <ViewInstituteProfile />
                </PrivateRoute>
              }
              />
              <Route path="/searchall" element={
                <PrivateRoute>
                  <SearchAll />
                </PrivateRoute>
              }
              />
              <Route path="/advancefilter" element={
                <PrivateRoute>
                  <AdvanceFilter />
                </PrivateRoute>
              }
              />
              <Route path="/news" element={
                <PrivateRoute>
                  <News />
                </PrivateRoute>
              }
              />
              <Route path="/player">
                <Route path="editprofile" element={
                  <PrivateRoute>
                    <EditPlayerProfile />
                  </PrivateRoute>
                }
                />
              </Route>
              <Route path="/coach">
                <Route path="editprofile" element={
                  <PrivateRoute>
                    <EditCoachProfile />
                  </PrivateRoute>
                }
                />
              </Route>
              <Route path="/agent">
                <Route path="editprofile" element={
                  <PrivateRoute>
                    <EditAgentProfile />
                  </PrivateRoute>
                }
                />
              </Route>
              <Route path="/instituition">
                <Route path="editprofile" element={
                  <PrivateRoute>
                    <EditInstituitionInfoProfile />
                  </PrivateRoute>
                }
                />
              </Route>
            </Routes>
          </ChatContextProvider>
        </NetworkContextProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faGoogle, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from 'react-router-dom'

export default function Footer() {
    return (
        <>
            <footer className="py-5 mt-5" style={{ backgroundColor: '#99e6ff	' }}>
                <div className='container'>
                    <div className="row">
                        <div className="col-4">
                            <h5>Section</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><NavLink to="/" className="nav-link p-0 text-muted">Home</NavLink></li>
                                <li className="nav-item mb-2"><NavLink to="/network/connect" className="nav-link p-0 text-muted">My Network</NavLink></li>
                                <li className="nav-item mb-2"><NavLink to="/messaging" className="nav-link p-0 text-muted">Messaging</NavLink></li>
                            </ul>
                        </div>

                        {/* <div className="col-2">
                            <h5>Section</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Home</a></li>
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Features</a></li>
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Pricing</a></li>
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">FAQs</a></li>
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">About</a></li>
                            </ul>
                        </div>

                        <div className="col-2">
                            <h5>Section</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Home</a></li>
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Features</a></li>
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Pricing</a></li>
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">FAQs</a></li>
                                <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">About</a></li>
                            </ul>
                        </div> */}

                        <div className="col-6 offset-1">
                            <form>
                                <h5>Subscribe to our newsletter</h5>
                                <p>Monthly digest of whats new and exciting from us.</p>
                                <div className="d-flex w-100 gap-2">
                                    <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                                    <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                                    <button className="btn btn-primary" type="button">Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between py-2 border-top">
                        <p>© 2021 Company, Inc. All rights reserved.</p>
                        <ul className="list-unstyled d-flex">
                            <li className='ms-3'>
                                <a className='link-dark' href='/'>
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                            </li>
                            <li className='ms-3'>
                                <FontAwesomeIcon icon={faTwitter} />
                            </li>
                            <li className='ms-3'>
                                <FontAwesomeIcon icon={faGoogle} />
                            </li>
                            <li className='ms-3'>
                                <FontAwesomeIcon icon={faLinkedin} />
                            </li>
                            <li className='ms-3'>
                                <FontAwesomeIcon icon={faGithub} />
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

import React, { useContext} from 'react';
import { NavLink } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import './css/NavBar.css';

export default function NavbarAdmin() {

    let {
        user,
        logoutUserAdmin
    } = useContext(AuthContext)

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container">
                    <div className="d-flex flex-row">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <NavLink className="navbar-brand" to="/admin/user">
                            {/* <i className="fab fa-linkedin fa-2x"> Admin</i> */}
                            <i className="fas fa-users-cog fa-2x"> Admin Panel</i>
                            {/* <i className="fa-solid fa-user-gear"> Admin</i> */}
                        </NavLink>
                        {/* <form className="input-group mx-3" style={{ width: 300 }}>
                            <input type="search" className="form-control" placeholder="Search..." aria-label="Search" onChange={e => handleSearchFilter(e.target.value)} disabled={!flag} />
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                data-bs-ripple-color="dark"
                                style={{ padding: '.45rem 1.5rem .35rem' }}
                                // onClick={() => setShowSearchModal(true)}
                                onClick={() => navigate('/advancefilter')}
                                disabled={!flag}
                            >
                                Advance Search
                            </button>
                        </form> */}
                    </div>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <div
                                    className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fas fa-user fa-lg"></i>
                                    <span className="small">Profile</span>
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    {/* <li><NavLink className="dropdown-item" to="/editprofile">Edit profile</NavLink></li>
                                    <li><NavLink className="dropdown-item" to="/viewprofile">View Profile</NavLink></li> */}
                                    <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    {user ?
                                        (<li className="dropdown-item" onClick={logoutUserAdmin} style={{ cursor: 'pointer' }}>
                                            Logout
                                        </li>) : (
                                            null
                                        )}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}
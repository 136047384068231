import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPeopleGroup, faPersonWalking, faUser, faUserTie } from '@fortawesome/free-solid-svg-icons';

export const ProfileBuilderNavBar = ({ playerIsActive, coachIsActive, agentIsActive, instituitionIsActive }) => {
    return (
        <div className='d-flex flex-column justify-content-between col bg-dark min-vh-100 my-3'>
            <div className='mt-4'>
                <Link className="text-white d-none d-sm-inline text-decoration-none d-flex align-items-center ms-4">
                    <span className="fs-5">Edit my profile</span>
                </Link>
                <hr className='text-white d-none d-sm-block' />
                <ul className="nav nav-pills flex-column mt-4 mt-sm-0">
                    <li className="nav-item my-sm-1 my-2">
                        <NavLink to="/editprofile" className="nav-link text-white text-center text-sm-start" aria-current="page">
                            <FontAwesomeIcon
                                icon={faUser}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            <span className='ms-2 d-none d-sm-inline'>Personal information</span>
                        </NavLink>
                    </li>
                    {playerIsActive &&
                        <li className="nav-item my-sm-1 my-2">
                            <NavLink to="/player/editprofile" className="nav-link text-white text-center text-sm-start" aria-current="page">
                                <FontAwesomeIcon
                                    icon={faPersonWalking}
                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                />
                                <span className='ms-2 d-none d-sm-inline'>Your profile as player</span>
                            </NavLink>
                        </li>
                    }
                    {coachIsActive &&
                        <li className="nav-item my-sm-1 my-2">
                            <NavLink to="/coach/editprofile" className="nav-link text-white text-center text-sm-start">
                                <FontAwesomeIcon
                                    icon={faUserTie}
                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                />
                                <span className='ms-2 d-none d-sm-inline'>Your profile as coach</span>
                            </NavLink>
                        </li>
                    }
                    {agentIsActive &&
                        <li className="nav-item my-sm-1 my-2">
                            <NavLink to="/agent/editprofile" className="nav-link text-white text-center text-sm-start">
                                <FontAwesomeIcon
                                    icon={faPeopleGroup}
                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                />
                                <span className='ms-2 d-none d-sm-inline'>Your profile as agent</span>
                            </NavLink>
                        </li>
                    }
                    {instituitionIsActive &&
                        <li className="nav-item my-sm-1 my-2">
                            <NavLink to="/agent/editprofileinstituition" className="nav-link text-white text-center text-sm-start">
                                <FontAwesomeIcon
                                    icon={faBuilding}
                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                />
                                <span className='ms-2 d-none d-sm-inline'>Your profile as institution</span>
                            </NavLink>
                        </li>
                    }
                </ul>
            </div>
            <div>
                <hr className='text-white' />
            </div>
        </div>
    )
}

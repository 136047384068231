import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { RESET_PASSWORD_URL } from '../services/Constants';
import AuthContext from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';

export default function ForgotPassword() {

    let { notifyRequestSendSuccessfully, notifyEmailError, notifyError } = useContext(AuthContext);

    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [spinnerLoading, setSpinnerLoading] = useState(false);

    const handleEmailSubmit = async (e) => {
        setSpinnerLoading(true)
        e.preventDefault();
        console.log('Form Submitted');
        // const formData = new FormData();
        // formData.append("email", email);
        let response = fetch(`${RESET_PASSWORD_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'email': email })
        })
        let data = (await response).json()
        console.log('data', data)
        let status = (await response).status
        console.log('status', status)
        if (status === 200) {
            setSpinnerLoading(false);
            notifyRequestSendSuccessfully();
            // navigate('/resetpassword');
            setTimeout(() => {
                navigate('/resetpassword');
            }, 4000);
        }else if(status===400){
            setSpinnerLoading(false);
            notifyEmailError();
        }else{
            notifyError();
        }
    }

    return (
        <>
            {spinnerLoading ?
                <div className='text-center mt-5'>
                    <Spinner animation="border" />
                </div> :
                <Container>
                    <div className="d-flex align-items-center justify-content-center vh-100">
                        <Card style={{ width: '30rem' }}>
                            <Card.Body>
                                <Form onSubmit={handleEmailSubmit}>
                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            id="floatingInputCustom"
                                            type="email"
                                            placeholder="Enter the email"
                                            name='email'
                                            autoFocus
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <label htmlFor="floatingInputCustom">Email</label>
                                    </Form.Floating>
                                    <Row className='mx-1'>
                                        <Button variant="primary" type='submit'>Submit</Button>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            }
            <ToastContainer />
        </>
    )
}

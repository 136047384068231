import React, { useContext, useEffect, useState } from 'react'
import { NetworkContext } from '../context/NetworkContext'
import AuthContext from '../context/AuthContext';
import { PendingNetworkConnectCard } from './CustomComponents';
import { handleNetworkConnectRequestDelete, handleNetworkConnectingUpdate, handleNetworkConnectingRequestAccept } from '../services/ApiServices';
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../utils/Firebase";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

export const PendingRequest = () => {

    const { getNetworkConnectRequest, networkRequest } = useContext(NetworkContext);
    const { currentUser, notifyRegistrationPersonalInfo, notifyError } = useContext(AuthContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [limit, setLimit] = useState(3);

    const handleConnectAccept = async (item) => {
        const response = handleNetworkConnectingUpdate(item.id, 'accepted');
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            const acceptResponse = handleNetworkConnectingRequestAccept(item.id);
            console.log('accept response:', acceptResponse)
            let acceptData = (await acceptResponse).json()
            console.log('accept data:', acceptData)
            let acceptStatus = (await response).status
            console.log('accept status:', acceptStatus)
            if (acceptStatus === 200) {
                // handleNetworkConnectUpdate(requestId);
                const user1Ref = doc(db, "users", currentUser.username);

                // Atomically add a new region to the "regions" array field.
                await updateDoc(user1Ref, {
                    connected: arrayUnion(item.from_user.id)
                });

                const user2Ref = doc(db, "users", item.from_user.username);

                // Atomically add a new region to the "regions" array field.
                await updateDoc(user2Ref, {
                    connected: arrayUnion(currentUser.user_id)
                });

                notifyRegistrationPersonalInfo();
            } else {
                const response = handleNetworkConnectingUpdate(item.id, 'pending');
                notifyError();
            }
        } else {
            notifyError();
        }
    }

    const navigateToProfileWithParams = (item) => {
        console.log('navigateToProfileWithParams');
        console.log('Search Data:', item);
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const networkRequestPendingInfo = networkRequest.slice(0, limit).map((item) => {
        return (
            <PendingNetworkConnectCard
                item={item}
                onReject={() => {
                    handleNetworkConnectRequestDelete(item.id);
                    setLoading(true);
                }}
                onAccept={() => {
                    // handleNetworkConnectRequestAccept(item, currentUser);
                    // handleNetworkConnectingUpdate(item.id);
                    handleConnectAccept(item);
                    setLoading(true);
                }}
                onClickUser={() => navigateToProfileWithParams(item.from_user)}
            />
        );
    })

    useEffect(() => {
        getNetworkConnectRequest();
        setLoading(false);
        setSpinnerLoading(false);
    }, [loading])

    return (
        <div className='col'>
            <div className="card my-3">
                <div class="card-header d-flex justify-content-between" style={{ backgroundColor: '#2222e785', fontWeight: 600, color: '#fff', fontSize: 15, fontFamily: 'monospace' }}>Pending Request
                    <button type="button" class="btn btn-light" onClick={() => setLimit(9)}>Load more</button>
                </div>
                <div className="card-body text-center">
                    {spinnerLoading ?
                        <div className='text-center'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                        :
                        <div className="row row-cols-1 row-cols-md-4 g-4">
                            {networkRequest && networkRequest.length > 0 ?
                                networkRequestPendingInfo
                                : <h5>No pending request present</h5>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

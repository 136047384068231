import React, { useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { BASE_MEDIA_URL, NEWS_URL } from '../services/Constants';
import Form from 'react-bootstrap/Form';
import AuthContext from '../context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
// import picture from '../assets/football-488714_1280.jpg';

export default function News() {

    let {
        currentUser,
        notifyRegistrationPersonalInfo,
        notifyError
    } = useContext(AuthContext)

    const navigate = useNavigate();

    const [isChecked, setIsChecked] = useState(false);

    const location = useLocation();

    const data = location.state.item;

    const dataFrom = location.state.from;

    console.log('news data: ', data);

    const isoDateString = data.posted;
    const date = new Date(isoDateString);
    const localDateString = date.toLocaleDateString();
    const localTimeString = date.toLocaleTimeString();

    const handleChange = async (event) => {
        console.log('handleChange function called')
        setIsChecked(event.target.checked);
        if (event.target.checked) {
            let response = fetch(`${NEWS_URL}${data.id}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'attending_persons': [currentUser.user_id]
                })
            })
            let responseData = (await response).json()
            console.log('responseData:', responseData)
            let status = (await response).status
            console.log('status:', status)
            if (status === 200) {
                notifyRegistrationPersonalInfo();
            } else {
                notifyError();
            }
        } else {
            const filteredAttendingPersons = data.attending_persons.filter(persons => persons !== currentUser.user_id);
            let response = fetch(`${NEWS_URL}${data.id}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'attending_persons': filteredAttendingPersons
                })
            })
            let responseData = (await response).json()
            console.log('responseData:', responseData)
            let status = (await response).status
            console.log('status:', status)
            if (status === 200) {
                notifyRegistrationPersonalInfo();
            } else {
                notifyError();
            }
        }
    };

    useEffect(() => {
        data.attending_persons.forEach(item => {
            if (item === currentUser.user_id) {
                setIsChecked(true);
            }
        })
    }, [])

    return (
        <>
            <div className='container'>
                {/* <h2 className='mt-3' style={{ backgroundColor: '#2222e785', padding: 5, borderRadius: 5, color: 'white' }}>{data.title}</h2>
            <h5>posted on: {localDateString} at {localTimeString}</h5>

            <h5 className='mt-4 shadow' style={{ backgroundColor: '#f4f7f6', padding: 10, borderRadius: 5 }}>{data.content}</h5> */}
                <div className='d-flex justify-content-start my-3'>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        style={{ fontSize: 14, fontWeight: 600, }}
                        onClick={()=>navigate('/')}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ cursor: 'pointer', marginRight: 10 }}
                        />
                        Back
                    </button>
                </div>
                <div className="card mb-3">
                    {data.picture && dataFrom === 'home' &&
                        <img src={data.picture} className="card-img-top img-thumbnail" alt="..." style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                        // <img src={`${BASE_MEDIA_URL}${data.picture}`}  className="card-img-top img-thumbnail" alt="..." style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                    }
                    {data.picture && dataFrom === 'event' &&
                        // <img src={data.picture} className="card-img-top img-thumbnail" alt="..." style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                        <img src={`${BASE_MEDIA_URL}${data.picture}`} className="card-img-top img-thumbnail" alt="..." style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                    }
                    <div className="card-body">
                        <h3 className="card-title">{data.title}</h3>
                        <div className='d-flex justify-content-between'>
                            <h5 className='card-text'>from {data.start_date} to {data.end_date}</h5>
                            <div>
                                <Form>
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="custom-switch"
                                        label="Are you attending?"
                                        checked={isChecked}
                                        onChange={handleChange}
                                    />
                                </Form>
                            </div>
                        </div>
                        <p className="card-text">{data.content}</p>
                        <p className="card-text"><small className="text-body-secondary">posted on: {localDateString} at {localTimeString}</small></p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

import React from 'react'

export default function CoachProfile({
    primaryPosition,
    secondaryPosition,
    preferredFoot,
    topSpeed,
    isPlayerTnternationalStats,
    internationalHistoryList,
    isPlayerDomesticStats,
    clubHistoryList,
    playerSummaryList,
    isPlayerSummary,
    isCoachTnternationalStats,
    careerHistoryListInternational,
    isCoachDomesticStats,
    careerHistoryListRegional,
    coachSummaryList,
    isCoachSummary,
    agentSummaryList,
    isAgentSummary,
    countriesIOperate,
    isAgentCareerHistory,
    agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest,
    sportProfile
}) {
    return (
        <div>
            <h3 className='mx-3 my-3'><b>Coach [ Current ]</b></h3>

            {isCoachSummary &&
                <div className='CoachSummary mx-3 my-3'>
                    <h4><b>Coach Summary</b></h4>
                    {coachSummaryList}
                </div>
            }

            {isCoachTnternationalStats &&
                <div className='CareerHistoryCoachInternational mx-3 my-3'>
                    <h4><b>International Statistics</b></h4>
                    <div className='table-responsive'>
                        <table className="table table-sm table-light table-striped table-hover table-bordered table-fixed mt-3">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Team</th>
                                    <th scope="col">Period</th>
                                    <th scope="col">League</th>
                                    <th scope="col" className='text-center'>Verified</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {careerHistoryListInternational}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {isCoachDomesticStats &&
                <div className='CareerHistoryCoachRegional mx-3 my-3'>
                    <h4><b>Domestic Statistics</b></h4>
                    <div className='table-responsive'>
                        <table className="table table-sm table-light table-striped table-hover table-bordered table-fixed mt-3">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Team</th>
                                    <th scope="col">Period</th>
                                    <th scope="col">League</th>
                                    <th scope="col" className='text-center'>Verified</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {careerHistoryListRegional}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {sportProfile.map(profile => {
                if (profile.profile_type === 'Player' && profile.is_active) {
                    return (
                        <>
                            <h3 className='mx-3 my-3'><b>Player</b></h3>

                            <div className='mx-3 my-3'>
                                <div>
                                    <b>Primary position:</b> {primaryPosition}
                                </div>
                                {secondaryPosition !== '' &&
                                    <div>
                                        <b>Secondary position:</b> {secondaryPosition}
                                    </div>
                                }
                                <div>
                                    <b>Preferred foot:</b> {preferredFoot}
                                </div>
                                {topSpeed !== 0 &&
                                    <div>
                                        <b>Top speed:</b> {topSpeed}
                                    </div>
                                }
                            </div>

                            {isPlayerSummary &&
                                <div className='PlayerSummary mx-3 my-3'>
                                    <h4><b>Player Summary</b></h4>
                                    {playerSummaryList}
                                </div>
                            }

                            {isPlayerTnternationalStats &&
                                <div className='InternationalleagueInfo mx-3 my-3'>
                                    <h4><b>International Statistics</b></h4>
                                    <div className='table-responsive'>
                                        <table className="table table-sm table-light table-striped table-hover table-bordered mt-3">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th scope="col">Team</th>
                                                    <th scope="col">Period</th>
                                                    <th scope="col">League</th>
                                                    <th scope="col">Games Played</th>
                                                    <th scope="col">Goals</th>
                                                    <th scope="col">Assists</th>
                                                    <th scope="col">Passes</th>
                                                    <th scope="col">Goals Saved</th>
                                                    <th scope="col">Tackels</th>
                                                    <th scope="col">Dribbles Completed</th>
                                                    <th scope="col">Interceptions</th>
                                                    <th scope="col">Key Passes</th>
                                                    <th scope="col">Shots</th>
                                                    <th scope="col">Clean sheets</th>
                                                    <th scope="col">Yellow Card</th>
                                                    <th scope="col">Red Card</th>
                                                    <th scope="col">Verified</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-group-divider">
                                                {internationalHistoryList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }

                            {isPlayerDomesticStats &&
                                <div className='DomesticleagueInfo mx-3 my-3'>
                                    <h4><b>Domestic Statistics</b></h4>
                                    <div className='table-responsive'>
                                        <table className="table table-sm table-light table-striped table-hover table-bordered mt-3">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th scope="col">Team</th>
                                                    <th scope="col">Period</th>
                                                    <th scope="col">League</th>
                                                    <th scope="col">Games Played</th>
                                                    <th scope="col">Goals</th>
                                                    <th scope="col">Assists</th>
                                                    <th scope="col">Passes</th>
                                                    <th scope="col">Goals Saved</th>
                                                    <th scope="col">Tackels</th>
                                                    <th scope="col">Dribbles Completed</th>
                                                    <th scope="col">Interceptions</th>
                                                    <th scope="col">Key Passes</th>
                                                    <th scope="col">Shots</th>
                                                    <th scope="col">Clean sheets</th>
                                                    <th scope="col">Yellow Card</th>
                                                    <th scope="col">Red Card</th>
                                                    <th scope="col">Verified</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-group-divider">
                                                {clubHistoryList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </>
                    )
                }
            })}

            {sportProfile.map(profile => {
                if (profile.profile_type === 'Agent' && profile.is_active) {
                    return (
                        <>
                            <h3 className='mx-3 my-3'><b>Agent</b></h3>

                            {countriesIOperate !== null && countriesIOperate.length > 0 &&
                                <div className='AgentCountriesIOperateIn mx-3 my-3'>
                                    <b>Countries I operate in:</b>
                                    {countriesIOperate.map((item, index) => (
                                        <span key={item.id} style={{ marginLeft: 5 }}>
                                            {item.name}
                                            {index < countriesIOperate.length - 1 && ', '}
                                        </span>
                                    ))}
                                </div>
                            }

                            {isAgentSummary &&
                                <div className='AgentSummary mx-3 my-3'>
                                    <h4><b>Agent Summary</b></h4>
                                    {agentSummaryList}
                                </div>
                            }

                            {/* <div className='AgentCareerHistory mx-3 my-3'>
                                <h4><b>Career History</b></h4>
                                <div className='table-responsive'>
                                    <table className="table table-sm table-light table-striped table-hover table-bordered mt-3">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">Company</th>
                                                <th scope="col">Period</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Zip Code</th>
                                                <th scope="col">Country</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Contact No.</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-group-divider">
                                            {agentCareerHistoryList}
                                        </tbody>
                                    </table>
                                </div>
                            </div> */}

{isAgentCareerHistory &&
                            <div className='AgentCareerHistoryPlayersAndCoachesEndorsement mx-3 my-3'>
                                <h4><b>Agent Career History</b></h4>
                                {agentCareerHistoryPlayersAndCoachesUnderMeEndorsementRequest}
                            </div>
                }
                        </>
                    )
                }
            })}

        </div>
    )
}

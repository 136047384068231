import React, {useContext, useEffect} from 'react'
import './Style.css'
import SidePanel from './SidePanel'
import AuthContext from '../context/AuthContext'
import { ChatContext } from '../context/ChatContext'
import { Messages } from './Messages'
import { MessageInput } from './MessageInput'

export default function Chat() {
    
    let {
        userPersonalData,
        userPersonalInfo
      } = useContext(AuthContext)

      const { data } = useContext(ChatContext);

      useEffect(()=>{
        userPersonalInfo();
      },[])

    //   console.log(data);

    return (
        <div id="frame">
            <SidePanel userInfo={userPersonalData}/>
            <div className="content">
                <div className="contact-profile">
                    <img src={data.user?.photoURL} alt="" />
                    <p>{data.user?.name}</p>
                    <div className="social-media">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                    </div>
                </div>
                <Messages/>
                <MessageInput/>
            </div>
        </div>
    )
}

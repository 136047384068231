import React, { useEffect, useState, useContext } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Table from 'react-bootstrap/Table';
import Navbar from '../components/Navbar';
import Select from 'react-select';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { SEARCHCOACH_URL, SEARCHPLAYER_URL, columns, columnsCoach } from '../services/Constants';
import { FilterComponent } from '../components/CustomComponents';
import AuthContext from '../context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function AdvanceFilter() {

    let {
        notifyCustomSuccessMessage,
        notifyCustomErrorMessage,
        currentUser
    } = useContext(AuthContext)

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    const [key, setKey] = useState('general');

    const [primaryPositionStatus, setPrimaryPositionStatus] = useState(false);
    const [secondaryPositionStatus, setSecondaryPositionStatus] = useState(false);
    const [ageStatus, setAgeStatus] = useState(false);
    const [heightStatus, setHeightStatus] = useState(false);
    const [weightStatus, setWeightStatus] = useState(false);
    const [footStatus, setFootStatus] = useState(false);
    const [countryStatus, setCountryStatus] = useState(false);
    const [currentTeamStatus, setCurrentTeamStatus] = useState(false);
    const [leagueNameStatus, setLeagueNameStatus] = useState(false);
    const [gamesPlayedStatus, setGamesPlayedStatus] = useState(false);

    const [fromAndToYearStatus, setFromAndToYearStatus] = useState(false);
    const [playoffsGamesCoachedInStatus, setPlayoffsGamesCoachedInStatus] = useState(false);
    const [playoffsGamesWonStatus, setPlayoffsGamesWonStatus] = useState(false);
    const [playoffsGamesLostStatus, setPlayoffsGamesLostStatus] = useState(false);

    const [profileType, setProfileType] = useState('Player');

    const [primaryPosition, setPrimaryPosition] = useState('');
    const [secondaryPosition, setSecondaryPosition] = useState('');
    const [minDob, setMinDob] = useState('');
    const [maxDob, setMaxDob] = useState('');
    const [height, setHeight] = useState(null);
    const [weight, setWeight] = useState(null);
    const [foot, setFoot] = useState('');
    const [country, setCountry] = useState('');
    const [currentTeam, setCurrentTeam] = useState('');
    const [leagueName, setLeagueName] = useState('');
    const [gamesPlayed, setGamesPlayed] = useState('');

    const [fromToYear, setFromToYear] = useState('');
    const [playoffsGamesCoached, setPlayoffsGamesCoached] = useState('');
    const [playoffsGamesWon, setPlayoffsGamesWon] = useState('');
    const [playoffsGamesLost, setPlayoffsGamesLost] = useState('');

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [isDataFound, setIsDataFound] = useState(false);

    const navigate = useNavigate();

    const optionsPosition = [
        { value: 'Goalkeeper', label: 'Goalkeeper' },
        { value: 'Right Fullback', label: 'Right Fullback' },
        { value: 'Left Fullback', label: 'Left Fullback' },
        { value: 'Center Back', label: 'Center Back' },
        { value: 'Right Midfielder or Winger', label: 'Right Midfielder or Winger' },
        { value: 'Center Midfielder', label: 'Center Midfielder' },
        { value: 'Attacking Midfielder', label: 'Attacking Midfielder' },
        { value: 'Left Midfielder or Winger', label: 'Left Midfielder or Winger' },
        { value: 'Striker', label: 'Striker' },
    ];

    const optionsAge = [
        { value: '01-10', label: '01 - 10' },
        { value: '11-20', label: '11 - 20' },
        { value: '21-30', label: '21 - 30' },
        { value: '31-40', label: '31 - 40' },
        { value: '41-50', label: '41 - 50' },
        { value: '51-60', label: '51 - 60' },
        { value: '61-70', label: '61 - 70' },
    ];

    const optionsHeight = [
        { value: '151-160', label: '151 - 160' },
        { value: '161-170', label: '161 - 170' },
        { value: '171-180', label: '171 - 180' },
        { value: '181-190', label: '181 - 190' },
        { value: '191-200', label: '191 - 200' },
        { value: '201-210', label: '201 - 210' },
        { value: '211-220', label: '211 - 220' },
        { value: '221-230', label: '221 - 230' },
        { value: '231-240', label: '231 - 240' },
        { value: '241-250', label: '241 - 250' },
    ];

    const optionsWeight = [
        { value: '51-60', label: '50 - 60' },
        { value: '61-70', label: '61 - 70' },
        { value: '71-80', label: '71 - 80' },
        { value: '81-90', label: '81 - 90' },
        { value: '91-100', label: '91 - 100' },
        { value: '101-110', label: '101 - 110' },
        { value: '111-120', label: '111 - 120' },
        { value: '121-130', label: '121 - 130' },
        { value: '131-140', label: '131 - 140' },
        { value: '141-150', label: '141 - 150' },
    ];

    const optionsPreferredFoot = [
        { value: 'Left', label: 'Left' },
        { value: 'Right', label: 'Right' },
    ];

    const optionsClubs = [
        { value: 'Acacia Ridge', label: 'Acacia Ridge' },
        { value: 'Across The Waves', label: 'Across The Waves' },
        { value: 'Adamstown Rosebud', label: 'Adamstown Rosebud' },
        { value: 'Adelaide Blue Eagles', label: 'Adelaide City' },
        { value: 'Adelaide Cobras', label: 'Adelaide Comets' },
        { value: 'Adelaide Croatia Raiders', label: 'Adelaide Croatia Raiders' },
        { value: 'Adelaide Hills Hawks', label: 'Adelaide Hills Hawks' },
        { value: 'Adelaide Olympic', label: 'Adelaide Olympic' },
        { value: 'Adelaide United', label: 'Adelaide United' },
        { value: 'Adelaide University', label: 'Adelaide University' },
        { value: 'Adelaide Victory', label: 'Adelaide Victory' },
        { value: 'Adelaide Vipers', label: 'Adelaide Vipers' },
        { value: 'Balgownie Rangers', label: 'Balgownvalueie Rangers' },
        { value: 'Ballarat City', label: 'Ballarat City' },
        { value: 'Bankstown City', label: 'Bankstown City' },
        { value: 'Banyule City', label: 'Banyule City' },
        { value: 'Bardon Latrobe', label: 'Bardon Latrobe' },
    ];

    const optionsCountry = [
        { value: "Afghanistan", label: "Afghanistan" },
        { value: "land Islands", label: "land Islands" },
        { value: "Albania", label: "Albania" },
        { value: "Algeria", label: "Algeria" },
        { value: "American Samoa", label: "American Samoa" },
        { value: "AndorrA", label: "AndorrA" },
        { value: "Angola", label: "Angola" },
        { value: "Anguilla", label: "Anguilla" },
        { value: "Antarctica", label: "Antarctica" },
        { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
        { value: "Argentina", label: "Argentina" },
        { value: "Armenia", label: "Armenia" },
        { value: "Aruba", label: "Aruba" },
        { value: "Australia", label: "Australia" },
        { value: "Austria", label: "Austria" },
        { value: "Azerbaijan", label: "Azerbaijan" },
        { value: "Bahamas", label: "Bahamas" },
        { value: "Bahrain", label: "Bahrain" },
        { value: "Bangladesh", label: "Bangladesh" },
        { value: "Barbados", label: "Barbados" },
        { value: "Belarus", label: "Belarus" },
        { value: "Belgium", label: "Belgium" },
        { value: "Belize", label: "Belize" },
        { value: "Benin", label: "Benin" },
        { value: "Bermuda", label: "Bermuda" },
        { value: "Bhutan", label: "Bhutan" },
        { value: "Bolivia", label: "Bolivia" },
        { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
        { value: "Botswana", label: "Botswana" },
        { value: "Bouvet Island", label: "Bouvet Island" },
        { value: "Brazil", label: "Brazil" },
        { value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
        { value: "Brunei Darussalam", label: "Brunei Darussalam" },
        { value: "Bulgaria", label: "Bulgaria" },
        { value: "Burkina Faso", label: "Burkina Faso" },
        { value: "Burundi", label: "Burundi" },
        { value: "Cambodia", label: "Cambodia" },
        { value: "Cameroon", label: "Cameroon" },
        { value: "Canada", label: "Canada" },
        { value: "Cape Verde", label: "Cape Verde" },
        { value: "Cayman Islands", label: "Cayman Islands" },
        { value: "Central African Republic", label: "Central African Republic" },
        { value: "Chad", label: "Chad" },
        { value: "Chile", label: "Chile" },
        { value: "China", label: "China" },
        { value: "Christmas Island", label: "Christmas Island" },
        { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
        { value: "Colombia", label: "Colombia" },
        { value: "Comoros", label: "Comoros" },
        { value: "Congo", label: "Congo" },
        { value: "Congo, The Democratic Republic of the", label: "Congo, The Democratic Republic of the" },
        { value: "Cook Islands", label: "Cook Islands" },
        { value: "Costa Rica", label: "Costa Rica" },
        { value: "Cote D\"Ivoire", label: "Cote D\"Ivoire" },
        { value: "Croatia", label: "Croatia" },
        { value: "Cuba", label: "Cuba" },
        { value: "Cyprus", label: "Cyprus" },
        { value: "Czech Republic", label: "Czech Republic" },
        { value: "Denmark", label: "Denmark" },
        { value: "Djibouti", label: "Djibouti" },
        { value: "Dominica", label: "Dominica" },
        { value: "Dominican Republic", label: "Dominican Republic" },
        { value: "Ecuador", label: "Ecuador" },
        { value: "Egypt", label: "Egypt" },
        { value: "El Salvador", label: "El Salvador" },
        { value: "Equatorial Guinea", label: "Equatorial Guinea" },
        { value: "Eritrea", label: "Eritrea" },
        { value: "Estonia", label: "Estonia" },
        { value: "Ethiopia", label: "Ethiopia" },
        { value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
        { value: "Faroe Islands", label: "Faroe Islands" },
        { value: "Fiji", label: "Fiji" },
        { value: "Finland", label: "Finland" },
        { value: "France", label: "France" },
        { value: "French Guiana", label: "French Guiana" },
        { value: "French Polynesia", label: "French Polynesia" },
        { value: "French Southern Territories", label: "French Southern Territories" },
        { value: "Gabon", label: "Gabon" },
        { value: "Gambia", label: "Gambia" },
        { value: "Georgia", label: "Georgia" },
        { value: "Germany", label: "Germany" },
        { value: "Ghana", label: "Ghana" },
        { value: "Gibraltar", label: "Gibraltar" },
        { value: "Greece", label: "Greece" },
        { value: "Greenland", label: "Greenland" },
        { value: "Grenada", label: "Grenada" },
        { value: "Guadeloupe", label: "Guadeloupe" },
        { value: "Guam", label: "Guam" },
        { value: "Guatemala", label: "Guatemala" },
        { value: "Guernsey", label: "Guernsey" },
        { value: "Guinea", label: "Guinea" },
        { value: "Guinea-Bissau", label: "Guinea-Bissau" },
        { value: "Guyana", label: "Guyana" },
        { value: "Haiti", label: "Haiti" },
        { value: "Heard Island and Mcdonald Islands", label: "Heard Island and Mcdonald Islands" },
        { value: "Holy See (Vatican City State)", label: "Holy See (Vatican City State)" },
        { value: "Honduras", label: "Honduras" },
        { value: "Hong Kong", label: "Hong Kong" },
        { value: "Hungary", label: "Hungary" },
        { value: "Iceland", label: "Iceland" },
        { value: "India", label: "India" },
        { value: "Indonesia", label: "Indonesia" },
        { value: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
        { value: "Iraq", label: "Iraq" },
        { value: "Ireland", label: "Ireland" },
        { value: "Isle of Man", label: "Isle of Man" },
        { value: "Israel", label: "Israel" },
        { value: "Italy", label: "Italy" },
        { value: "Jamaica", label: "Jamaica" },
        { value: "Japan", label: "Japan" },
        { value: "Jersey", label: "Jersey" },
        { value: "Jordan", label: "Jordan" },
        { value: "Kazakhstan", label: "Kazakhstan" },
        { value: "Kenya", label: "Kenya" },
        { value: "Kiribati", label: "Kiribati" },
        { value: "Korea, Democratic People\"S Republic of", label: "Korea, Democratic People\"S Republic of" },
        { value: "Korea, Republic of", label: "Korea, Republic of" },
        { value: "Kuwait", label: "Kuwait" },
        { value: "Kyrgyzstan", label: "Kyrgyzstan" },
        { value: "Lao People\"S Democratic Republic", label: "Lao People\"S Democratic Republic" },
        { value: "Latvia", label: "Latvia" },
        { value: "Lebanon", label: "Lebanon" },
        { value: "Lesotho", label: "Lesotho" },
        { value: "Liberia", label: "Liberia" },
        { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
        { value: "Liechtenstein", label: "Liechtenstein" },
        { value: "Lithuania", label: "Lithuania" },
        { value: "Luxembourg", label: "Luxembourg" },
        { value: "Macao", label: "Macao" },
        { value: "Macedonia, The Former Yugoslav Republic of", label: "Macedonia, The Former Yugoslav Republic of" },
        { value: "Madagascar", label: "Madagascar" },
        { value: "Malawi", label: "Malawi" },
        { value: "Malaysia", label: "Malaysia" },
        { value: "Maldives", label: "Maldives" },
        { value: "Mali", label: "Mali" },
        { value: "Malta", label: "Malta" },
        { value: "Marshall Islands", label: "Marshall Islands" },
        { value: "Martinique", label: "Martinique" },
        { value: "Mauritania", label: "Mauritania" },
        { value: "Mauritius", label: "Mauritius" },
        { value: "Mayotte", label: "Mayotte" },
        { value: "Mexico", label: "Mexico" },
        { value: "Micronesia, Federated States of", label: "Micronesia, Federated States of" },
        { value: "Moldova, Republic of", label: "Moldova, Republic of" },
        { value: "Monaco", label: "Monaco" },
        { value: "Mongolia", label: "Mongolia" },
        { value: "Montenegro", label: "Montenegro" },
        { value: "Montserrat", label: "Montserrat" },
        { value: "Morocco", label: "Morocco" },
        { value: "Mozambique", label: "Mozambique" },
        { value: "Myanmar", label: "Myanmar" },
        { value: "Namibia", label: "Namibia" },
        { value: "Nauru", label: "Nauru" },
        { value: "Nepal", label: "Nepal" },
        { value: "Netherlands", label: "Netherlands" },
        { value: "Netherlands Antilles", label: "Netherlands Antilles" },
        { value: "New Caledonia", label: "New Caledonia" },
        { value: "New Zealand", label: "New Zealand" },
        { value: "Nicaragua", label: "Nicaragua" },
        { value: "Niger", label: "Niger" },
        { value: "Nigeria", label: "Nigeria" },
        { value: "Niue", label: "Niue" },
        { value: "Norfolk Island", label: "Norfolk Island" },
        { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
        { value: "Norway", label: "Norway" },
        { value: "Oman", label: "Oman" },
        { value: "Pakistan", label: "Pakistan" },
        { value: "Palau", label: "Palau" },
        { value: "Palestinian Territory, Occupied", label: "Palestinian Territory, Occupied" },
        { value: "Panama", label: "Panama" },
        { value: "Papua New Guinea", label: "Papua New Guinea" },
        { value: "Paraguay", label: "Paraguay" },
        { value: "Peru", label: "Peru" },
        { value: "Philippines", label: "Philippines" },
        { value: "Pitcairn", label: "Pitcairn" },
        { value: "Poland", label: "Poland" },
        { value: "Portugal", label: "Portugal" },
        { value: "Puerto Rico", label: "Puerto Rico" },
        { value: "Qatar", label: "Qatar" },
        { value: "Reunion", label: "Reunion" },
        { value: "Romania", label: "Romania" },
        { value: "Russian Federation", label: "Russian Federation" },
        { value: "RWANDA", label: "RWANDA" },
        { value: "Saint Helena", label: "Saint Helena" },
        { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
        { value: "Saint Lucia", label: "Saint Lucia" },
        { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
        { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
        { value: "Samoa", label: "Samoa" },
        { value: "San Marino", label: "San Marino" },
        { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
        { value: "Saudi Arabia", label: "Saudi Arabia" },
        { value: "Senegal", label: "Senegal" },
        { value: "Serbia", label: "Serbia" },
        { value: "Seychelles", label: "Seychelles" },
        { value: "Sierra Leone", label: "Sierra Leone" },
        { value: "Singapore", label: "Singapore" },
        { value: "Slovakia", label: "Slovakia" },
        { value: "Slovenia", label: "Slovenia" },
        { value: "Solomon Islands", label: "Solomon Islands" },
        { value: "Somalia", label: "Somalia" },
        { value: "South Africa", label: "South Africa" },
        { value: "South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands" },
        { value: "Spain", label: "Spain" },
        { value: "Sri Lanka", label: "Sri Lanka" },
        { value: "Sudan", label: "Sudan" },
        { value: "Suriname", label: "Suriname" },
        { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
        { value: "Swaziland", label: "Swaziland" },
        { value: "Sweden", label: "Sweden" },
        { value: "Switzerland", label: "Switzerland" },
        { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
        { value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
        { value: "Tajikistan", label: "Tajikistan" },
        { value: "Tanzania, United Republic of", label: "Tanzania, United Republic of" },
        { value: "Thailand", label: "Thailand" },
        { value: "Timor-Leste", label: "Timor-Leste" },
        { value: "Togo", label: "Togo" },
        { value: "Tokelau", label: "Tokelau" },
        { value: "Tonga", label: "Tonga" },
        { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
        { value: "Tunisia", label: "Tunisia" },
        { value: "Turkey", label: "Turkey" },
        { value: "Turkmenistan", label: "Turkmenistan" },
        { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
        { value: "Tuvalu", label: "Tuvalu" },
        { value: "Uganda", label: "Uganda" },
        { value: "Ukraine", label: "Ukraine" },
        { value: "United Arab Emirates", label: "United Arab Emirates" },
        { value: "United Kingdom", label: "United Kingdom" },
        { value: "United States", label: "United States" },
        { value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
        { value: "Uruguay", label: "Uruguay" },
        { value: "Uzbekistan", label: "Uzbekistan" },
        { value: "Vanuatu", label: "Vanuatu" },
        { value: "Venezuela", label: "Venezuela" },
        { value: "Viet Nam", label: "Viet Nam" },
        { value: "Virgin Islands, British", label: "Virgin Islands, British" },
        { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
        { value: "Wallis and Futuna", label: "Wallis and Futuna" },
        { value: "Western Sahara", label: "Western Sahara" },
        { value: "Yemen", label: "Yemen" },
        { value: "Zambia", label: "Zambia" },
        { value: "Zimbabwe", label: "Zimbabwe" }
    ];

    const optionsLeague = [
        { value: 'A-League', label: 'A-League' },
        { value: 'Australia Cup', label: 'Australia Cup' },
        { value: 'National Premier League', label: 'National Premier League' },
        { value: 'Capital NPL 1', label: 'Capital NPL 1' },
        { value: 'NSW NPL 1', label: 'NSW NPL 1' },
        { value: 'Queensland NPL', label: 'Queensland NPL' },
        { value: 'South Australia NPL', label: 'South Australia NPL' },
        { value: 'Western Australia NPL', label: 'Western Australia NPL' },
        { value: 'Victoria NPL', label: 'Victoria NPL' },
        { value: 'Brisbane Premier League', label: 'Brisbane Premier League' },
        { value: 'Brisbane Cup', label: 'Brisbane Cup' },
    ];

    const optionsGamesPlayed = [
        { value: '1-100', label: '001 - 100' },
        { value: '101-200', label: '101 - 200' },
        { value: '201-300', label: '201 - 300' },
        { value: '301-400', label: '301 - 400' },
        { value: '401-500', label: '401 - 500' },
        { value: '501-600', label: '501 - 600' },
        { value: '601-700', label: '601 - 700' },
        { value: '701-800', label: '701 - 800' },
        { value: '801-900', label: '801 - 900' },
        { value: '901-1000', label: '901 - 1000' },
    ];

    const optionsFromToYear = [
        { value: '1950-1975', label: '1950 - 1975' },
        { value: '1976-2000', label: '1976 - 2000' },
        { value: '2001-2025', label: '2001 - 2025' },
    ];

    const customStyles = {
        header: {
            style: {
                minHeight: '56px',
            },
        },
        headRow: {
            style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
    };

    const navigateToSearchDataWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    const handleAdvanceSearchPlayer = async () => {
        const baseUrl = `${SEARCHPLAYER_URL}`;
        let apiEndPointUrl='';
        // const newBaseUrl = baseUrl.substring(0, baseUrl.length - 1);
        // console.log('complete baseUrl:', newBaseUrl);
        if (primaryPositionStatus) {
            apiEndPointUrl = 'primary_position=' + primaryPosition + '&';
        }
        if (secondaryPositionStatus) {
            apiEndPointUrl = apiEndPointUrl + 'secondary_position=' + secondaryPosition + '&';
        }
        if (footStatus) {
            apiEndPointUrl = apiEndPointUrl + 'preferred_foot=' + foot + '&';
        }
        if (currentTeamStatus) {
            apiEndPointUrl = apiEndPointUrl + 'current_club=' + currentTeam + '&';
        }
        if (countryStatus) {
            apiEndPointUrl = apiEndPointUrl + 'user__citizenship=' + country + '&';
        }
        if (ageStatus) {
            apiEndPointUrl = apiEndPointUrl + 'user__dob_after=' + maxDob + '&user__dob_before=' + minDob + '&';
        }
        if (weightStatus) {
            const splitWeight = weight.split("-");
            const minWeight = splitWeight[0];
            const maxWeight = splitWeight[1];
            apiEndPointUrl = apiEndPointUrl + 'user__min_weight=' + minWeight + '&user__max_weight=' + maxWeight + '&';
        }
        if (heightStatus) {
            const splitHeight = height.split("-");
            const minHeight = splitHeight[0];
            const maxHeight = splitHeight[1];
            apiEndPointUrl = apiEndPointUrl + 'user__min_height=' + minHeight + '&user__max_height=' + maxHeight + '&';
        }
        let newBaseUrl = baseUrl + apiEndPointUrl;
        // console.log('complete url: ', newBaseUrl);
        let finalUrl = newBaseUrl.substring(0, newBaseUrl.length - 1);
        console.log('final url: ', finalUrl);
        let response = fetch(`${finalUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        console.log('response:', response)
        const apiData = (await response).json()
        // console.log('data:', apiData)
        const status = (await response).status
        if (status === 200) {
            const searchInfo = (await apiData)
            console.log('searchInfo: ', searchInfo)
            setSearchResult(searchInfo)
            setFilteredData(searchInfo)
            setIsDataFound(true)
            notifyCustomSuccessMessage("Successfully found data.")
        } else {
            setSearchResult([])
            setFilteredData([])
            setIsDataFound(false)
            notifyCustomErrorMessage("No data found.")
        }
    }

    const handleAdvanceSearchCoach = async () => {
        const baseUrl = `${SEARCHCOACH_URL}`;
        let apiEndPointUrl='';
        // const newBaseUrl = baseUrl.substring(0, baseUrl.length - 1);
        // console.log('complete baseUrl:', newBaseUrl);
        if (fromAndToYearStatus) {
            const splitYear = fromToYear.split("-");
            const fromYear = splitYear[0];
            const toYear = splitYear[1];
            apiEndPointUrl = 'from_date=' + fromYear + '&to_date=' + toYear + '&';
        }
        if (playoffsGamesWonStatus) {
            const splitPlayoffsGamesWon = playoffsGamesWon.split("-");
            const minPlayoffsGamesWon = splitPlayoffsGamesWon[0];
            const maxPlayoffsGamesWon = splitPlayoffsGamesWon[1];
            apiEndPointUrl = apiEndPointUrl + 'min_playoffs_games_won=' + minPlayoffsGamesWon + '&max_playoffs_games_won=' + maxPlayoffsGamesWon + '&';
        }
        if (playoffsGamesLostStatus) {
            const splitPlayoffsGamesLost = playoffsGamesLost.split("-");
            const minPlayoffsGamesLost = splitPlayoffsGamesLost[0];
            const maxPlayoffsGamesLost = splitPlayoffsGamesLost[1];
            apiEndPointUrl = apiEndPointUrl + 'min_playoffs_games_lost=' + minPlayoffsGamesLost + '&max_playoffs_games_lost=' + maxPlayoffsGamesLost + '&';
        }
        if (playoffsGamesCoachedInStatus) {
            const splitPlayoffsGamesCoached = playoffsGamesCoached.split("-");
            const minPlayoffsGamesCoached = splitPlayoffsGamesCoached[0];
            const maxPlayoffsGamesCoached = splitPlayoffsGamesCoached[1];
            apiEndPointUrl = apiEndPointUrl + 'min_playoffs_games_coached_in=' + minPlayoffsGamesCoached + '&max_playoffs_games_coached_in=' + maxPlayoffsGamesCoached + '&';
        }
        if (currentTeamStatus) {
            apiEndPointUrl = apiEndPointUrl + 'current_club=' + currentTeam + '&';
        }
        if (countryStatus) {
            apiEndPointUrl = apiEndPointUrl + 'user__citizenship=' + country + '&';
        }
        if (ageStatus) {
            apiEndPointUrl = apiEndPointUrl + 'user__dob_after=' + maxDob + '&user__dob_before=' + minDob + '&';
        }
        if (weightStatus) {
            const splitWeight = weight.split("-");
            const minWeight = splitWeight[0];
            const maxWeight = splitWeight[1];
            apiEndPointUrl = apiEndPointUrl + 'user__min_weight=' + minWeight + '&user__max_weight=' + maxWeight + '&';
        }
        if (heightStatus) {
            const splitHeight = height.split("-");
            const minHeight = splitHeight[0];
            const maxHeight = splitHeight[1];
            apiEndPointUrl = apiEndPointUrl + 'user__min_height=' + minHeight + '&user__max_height=' + maxHeight + '&';
        }
        let newBaseUrl = baseUrl + apiEndPointUrl;
        let finalUrl = newBaseUrl.substring(0, newBaseUrl.length - 1);
        // console.log('final url: ', finalUrl);
        let response = fetch(`${finalUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        console.log('response:', response)
        const apiData = (await response).json()
        console.log('data:', apiData)
        const status = (await response).status
        if (status === 200) {
            const searchInfo = (await apiData)
            console.log('searchInfo: ', searchInfo)
            setSearchResult(searchInfo)
            setFilteredData(searchInfo)
            setIsDataFound(true)
            notifyCustomSuccessMessage("Successfully found data.")
        } else {
            setSearchResult([])
            setFilteredData([])
            setIsDataFound(false)
            notifyCustomErrorMessage("No data found.")
        }
    }

    const handleChangeAge = (e) => {
        const ageRange = e.value;
        console.log('ageRange:', ageRange);
        const splitAge = ageRange.split("-");
        const minAge = splitAge[0];
        console.log('minAge:', minAge);
        const maxAge = splitAge[1];
        console.log('maxAge:', maxAge);
        const minDOB = calculateDOB(minAge);
        console.log('minDOB:', minDOB);
        setMinDob(minDOB);
        const maxDOB = calculateDOB(maxAge);
        console.log('maxDOB:', maxDOB);
        setMaxDob(maxDOB);
    }

    const calculateDOB = (age) => {
        const today = new Date();
        const birthYear = today.getFullYear() - age;
        // const dob = new Date(birthYear, today.getMonth(), today.getDate());
        // const dob = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
        const dob = `${birthYear}-${today.getMonth() + 1}-${today.getDate()}`;
        return dob;
    };

    // const convertArrayOfObjectsToCSV = (array) => {
    //     let result;

    //     const columnDelimiter = ',';
    //     const lineDelimiter = '\n';
    //     const keys = Object.keys(data[0]);

    //     result = '';
    //     result += keys.join(columnDelimiter);
    //     result += lineDelimiter;

    //     array.forEach(item => {
    //         let ctr = 0;
    //         keys.forEach(key => {
    //             if (ctr > 0) result += columnDelimiter;

    //             result += item[key];

    //             ctr++;
    //         });
    //         result += lineDelimiter;
    //     });

    //     return result;
    // }

    // const downloadCSV = (array) => {
    //     const link = document.createElement('a');
    //     let csv = convertArrayOfObjectsToCSV(array);
    //     if (csv == null) return;

    //     const filename = 'export.csv';

    //     if (!csv.match(/^data:text\/csv/i)) {
    //         csv = `data:text/csv;charset=utf-8,${csv}`;
    //     }

    //     link.setAttribute('href', encodeURI(csv));
    //     link.setAttribute('download', filename);
    //     link.click();
    // }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(filteredData)} />, []);

    // console.log('filtererd data: ', filteredData);

    const onChangeProfileType = (e) => {
        setProfileType(e.target.value);
        setPrimaryPositionStatus(false);
        setSecondaryPositionStatus(false);
        setAgeStatus(false);
        setCountryStatus(false);
        setCurrentTeamStatus(false);
        setFootStatus(false);
        setFromAndToYearStatus(false);
        setGamesPlayedStatus(false);
        setHeightStatus(false);
        setLeagueNameStatus(false);
        setPlayoffsGamesCoachedInStatus(false);
        setPlayoffsGamesLostStatus(false);
        setPlayoffsGamesWonStatus(false);
        setWeightStatus(false);
    }

    useEffect(() => {
        const filteredItems = searchResult.filter(
            item => item.user.first_name && item.user.first_name.toLowerCase().includes(filterText.toLowerCase()) || item.user.last_name && item.user.last_name.toLowerCase().includes(filterText.toLowerCase())
        );
        setFilteredData(filteredItems)
        // setIsDataFound(true)
    }, [filterText])

    return (
        <>
            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} profileType={currentUser.account_type} />
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, marginLeft: 40, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null}
                            </ul>
                        </div>
                    </div>
                </div>
            }
            <Container className='mt-3'>
                <Form>
                    <Row>
                        <Col className="d-grid">
                            <Card>
                                <Card.Header style={{ backgroundColor: '#0d6efd', color: 'white', fontSize: 15 }}>Filter</Card.Header>
                                <Card.Body>
                                    {/* <Button className='mb-3' variant="primary">Set filters...</Button> */}
                                    {primaryPositionStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>Primary position</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setPrimaryPosition(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsPosition}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {secondaryPositionStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>Secondary position</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setSecondaryPosition(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsPosition}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {ageStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>Age</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                // onChange={(e) => setAge(e.value)}
                                                onChange={handleChangeAge}
                                                options={optionsAge}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {weightStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>Weight in kg</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setWeight(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsWeight}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {heightStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>Height in cm</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setHeight(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsHeight}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {footStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>Foot</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setFoot(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsPreferredFoot}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {countryStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>Birth country</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setCountry(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsCountry}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {currentTeamStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>Current Team</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setCurrentTeam(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsClubs}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {leagueNameStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>League Name</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setLeagueName(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsLeague}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {gamesPlayedStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>No. of games played</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setGamesPlayed(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsGamesPlayed}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {fromAndToYearStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>From to To (year)</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setFromToYear(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsFromToYear}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {playoffsGamesCoachedInStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>No. of playoffs games coached</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setPlayoffsGamesCoached(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsGamesPlayed}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {playoffsGamesWonStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>No. of playoffs games won</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setPlayoffsGamesWon(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsGamesPlayed}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                    {playoffsGamesLostStatus &&
                                        <Form.Group as={Col} className='mb-3'>
                                            <Form.Label>No. of playoffs games lost</Form.Label>
                                            <Select
                                                // value={defaultPrimaryPosition}
                                                // defaultValue={defaultPrimaryPosition}
                                                onChange={(e) => setPlayoffsGamesLost(e.value)}
                                                // onChange={handleChangePrimaryPosition}
                                                options={optionsGamesPlayed}
                                                isSearchable
                                            />
                                        </Form.Group>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={9}>
                            <Form.Select size="lg" className='mb-3' onChange={onChangeProfileType}>
                                <option value="Player">Player</option>
                                <option value="Coach">Coach</option>
                                {/* <option value="Agent">Agent</option>
                                <option value="Instituition">Instituition</option> */}
                            </Form.Select>
                            {profileType === 'Player' &&
                                <Card>
                                    <Card.Body>
                                        <h2 className='text-center'>Choose filters for players</h2>
                                        <Form>
                                            <Tabs
                                                id="controlled-tab-example"
                                                activeKey={key}
                                                onSelect={(k) => setKey(k)}
                                                className="mb-3"
                                                justify
                                                variant='underline'
                                            >
                                                <Tab eventKey="general" title="GENERAL">
                                                    <Form.Label>KEY</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Primary position"
                                                                name="group1"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={primaryPositionStatus}
                                                                onChange={(e) => setPrimaryPositionStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Secondary position"
                                                                name="group1"
                                                                type={type}
                                                                id={`inline-${type}-2`}
                                                                checked={secondaryPositionStatus}
                                                                onChange={(e) => setSecondaryPositionStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Age"
                                                                name="group1"
                                                                type={type}
                                                                id={`inline-${type}-3`}
                                                                checked={ageStatus}
                                                                onChange={(e) => setAgeStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                    <Form.Label>PERSONAL</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Weight"
                                                                name="group2"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={weightStatus}
                                                                onChange={(e) => setWeightStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Height"
                                                                name="group2"
                                                                type={type}
                                                                id={`inline-${type}-2`}
                                                                checked={heightStatus}
                                                                onChange={(e) => setHeightStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Foot"
                                                                name="group2"
                                                                type={type}
                                                                id={`inline-${type}-3`}
                                                                checked={footStatus}
                                                                onChange={(e) => setFootStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                    <Form.Label>NATIONALITY</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Birth country"
                                                                name="group3"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={countryStatus}
                                                                onChange={(e) => setCountryStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                    <Form.Label>TEAM</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Current team"
                                                                name="group4"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={currentTeamStatus}
                                                                onChange={(e) => setCurrentTeamStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                </Tab>
                                                <Tab eventKey="matches" title="MATCHES">
                                                    <Form.Label>GAMES PLAYED</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="League Name"
                                                                name="group5"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={leagueNameStatus}
                                                                onChange={(e) => setLeagueNameStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="No. of games played"
                                                                name="group5"
                                                                type={type}
                                                                id={`inline-${type}-2`}
                                                                checked={gamesPlayedStatus}
                                                                onChange={(e) => setGamesPlayedStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                </Tab>
                                                <Tab eventKey="defensive" title="DEFENSIVE">
                                                    Tab content for defensive
                                                </Tab>
                                                <Tab eventKey="attacking" title="ATTACKING">
                                                    Tab content for attacking
                                                </Tab>
                                                <Tab eventKey="finishing" title="FINISHING">
                                                    Tab content for finishing
                                                </Tab>
                                                <Tab eventKey="passing" title="PASSING">
                                                    Tab content for passing
                                                </Tab>
                                                <Tab eventKey="key passing" title="KEY PASSING">
                                                    Tab content for key passing
                                                </Tab>
                                                <Tab eventKey="goalkeeping" title="GOALKEEPING">
                                                    Tab content for goalkeeping
                                                </Tab>
                                            </Tabs>
                                        </Form>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        <Button className='float-right' variant="primary" onClick={handleAdvanceSearchPlayer}>Search</Button>
                                    </Card.Footer>
                                </Card>
                            }
                            {profileType === 'Player' && isDataFound &&
                                <Card>
                                    <Card.Body>
                                        <h2 className='text-center'>Seach Result</h2>

                                        <Tabs
                                            defaultActiveKey="all"
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            variant='underline'
                                        >
                                            <Tab eventKey="all" title={"All results(" + filteredData.length + ")"}>
                                                <DataTable
                                                    title='List of players'
                                                    columns={columns}
                                                    data={filteredData}
                                                    customStyles={customStyles}
                                                    // selectableRows
                                                    selectableRowsHighlight
                                                    highlightOnHover
                                                    fixedHeader
                                                    pagination
                                                    paginationResetDefaultPage={resetPaginationToggle}
                                                    dense
                                                    subHeader
                                                    subHeaderComponent={subHeaderComponentMemo}
                                                    persistTableHead
                                                // actions={actionsMemo}
                                                />
                                            </Tab>
                                        </Tabs>
                                    </Card.Body>
                                </Card>
                            }

                            {profileType === 'Coach' &&
                                <Card>
                                    <Card.Body>
                                        <h2 className='text-center'>Choose filters for coach</h2>
                                        <Form>
                                            <Tabs
                                                id="controlled-tab-example"
                                                activeKey={key}
                                                onSelect={(k) => setKey(k)}
                                                className="mb-3"
                                                justify
                                                variant='underline'
                                            >
                                                <Tab eventKey="general" title="GENERAL">
                                                    <Form.Label>KEY</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="from and to (year)"
                                                                name="group1"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={fromAndToYearStatus}
                                                                onChange={(e) => setFromAndToYearStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Age"
                                                                name="group1"
                                                                type={type}
                                                                id={`inline-${type}-3`}
                                                                checked={ageStatus}
                                                                onChange={(e) => setAgeStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                    <Form.Label>PERSONAL</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Weight"
                                                                name="group2"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={weightStatus}
                                                                onChange={(e) => setWeightStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Height"
                                                                name="group2"
                                                                type={type}
                                                                id={`inline-${type}-2`}
                                                                checked={heightStatus}
                                                                onChange={(e) => setHeightStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                    <Form.Label>NATIONALITY</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Birth country"
                                                                name="group3"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={countryStatus}
                                                                onChange={(e) => setCountryStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                    <Form.Label>TEAM</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Current team"
                                                                name="group4"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={currentTeamStatus}
                                                                onChange={(e) => setCurrentTeamStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                </Tab>
                                                <Tab eventKey="matches" title="MATCHES">
                                                    <Form.Label>GAMES PLAYED</Form.Label>
                                                    {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Playoffs games coached in"
                                                                name="group5"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                checked={playoffsGamesCoachedInStatus}
                                                                onChange={(e) => setPlayoffsGamesCoachedInStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Playoffs games won"
                                                                name="group5"
                                                                type={type}
                                                                id={`inline-${type}-2`}
                                                                checked={playoffsGamesWonStatus}
                                                                onChange={(e) => setPlayoffsGamesWonStatus(e.target.checked)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Playoffs games lost"
                                                                name="group5"
                                                                type={type}
                                                                id={`inline-${type}-3`}
                                                                checked={playoffsGamesLostStatus}
                                                                onChange={(e) => setPlayoffsGamesLostStatus(e.target.checked)}
                                                            />
                                                        </div>
                                                    ))}
                                                </Tab>
                                                <Tab eventKey="defensive" title="DEFENSIVE">
                                                    Tab content for defensive
                                                </Tab>
                                                <Tab eventKey="attacking" title="ATTACKING">
                                                    Tab content for attacking
                                                </Tab>
                                                <Tab eventKey="finishing" title="FINISHING">
                                                    Tab content for finishing
                                                </Tab>
                                                <Tab eventKey="passing" title="PASSING">
                                                    Tab content for passing
                                                </Tab>
                                                <Tab eventKey="key passing" title="KEY PASSING">
                                                    Tab content for key passing
                                                </Tab>
                                                <Tab eventKey="goalkeeping" title="GOALKEEPING">
                                                    Tab content for goalkeeping
                                                </Tab>
                                            </Tabs>
                                        </Form>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        <Button className='float-right' variant="primary" onClick={handleAdvanceSearchCoach}>Search</Button>
                                    </Card.Footer>
                                </Card>
                            }
                            {profileType === 'Coach' && isDataFound &&
                                <Card>
                                    <Card.Body>
                                        <h2 className='text-center'>Seach Result</h2>

                                        <Tabs
                                            defaultActiveKey="all"
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            variant='underline'
                                        >
                                            <Tab eventKey="all" title={"All results(" + filteredData.length + ")"}>
                                                <DataTable
                                                    title='List of coaches'
                                                    columns={columnsCoach}
                                                    data={filteredData}
                                                    customStyles={customStyles}
                                                    // selectableRows
                                                    selectableRowsHighlight
                                                    highlightOnHover
                                                    fixedHeader
                                                    pagination
                                                    paginationResetDefaultPage={resetPaginationToggle}
                                                    dense
                                                    subHeader
                                                    subHeaderComponent={subHeaderComponentMemo}
                                                    persistTableHead
                                                // actions={actionsMemo}
                                                />
                                            </Tab>
                                        </Tabs>
                                    </Card.Body>
                                </Card>
                            }

                        </Col>
                    </Row>
                </Form>
            </Container>
            <ToastContainer />
        </>
    )
}

import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple, faCheck, faEdit, faXmark } from '@fortawesome/free-solid-svg-icons'

export const EndorsementSideNavbar = () => {
    return (
        <div className='d-flex flex-column justify-content-between col bg-dark min-vh-100 my-3'>
            <div className='mt-4'>
                <Link className="text-white d-none d-sm-inline text-decoration-none d-flex align-items-center ms-4">
                    <span className="fs-5">Manage my notifications</span>
                </Link>
                <hr className='text-white d-none d-sm-block' />
                <ul className="nav nav-pills flex-column mt-4 mt-sm-0">
                    <li className="nav-item my-sm-1 my-2">
                        <NavLink to="/endorsements/pending" className="nav-link text-white text-center text-sm-start" aria-current="page">
                            <FontAwesomeIcon
                                icon={faChartSimple}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            <span className='ms-2 d-none d-sm-inline'>Pending Request</span>
                        </NavLink>
                    </li>

                    <li className="nav-item my-sm-1 my-2">
                        <NavLink to="/endorsements/approved" className="nav-link text-white text-center text-sm-start" aria-current="page">
                            <FontAwesomeIcon
                                icon={faCheck}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            <span className='ms-2 d-none d-sm-inline'>Approved Request</span>
                        </NavLink>
                    </li>

                    
                        <li className="nav-item my-sm-1 my-2">
                            <NavLink to="/endorsements/rejected" className="nav-link text-white text-center text-sm-start">
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ cursor: 'pointer', marginRight: 10 }}
                                />
                                <span className='ms-2 d-none d-sm-inline'>Rejected Request</span>
                            </NavLink>
                        </li>
                    
                </ul>
            </div>
            <div>
                <hr className='text-white' />
            </div>
        </div>
    )
}

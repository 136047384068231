import React, { useContext, useEffect, useState } from 'react'
import './Style.css'
import AuthContext from '../context/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFootball, faLock, faPhone } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
// import axios from 'axios'
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { FacebookLoginButton, GoogleLoginButton, createButton } from "react-social-login-buttons";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import jwt_decode from "jwt-decode";

export default function Login() {
  let { loginUser, registerUser, handleLoginWithGoogle, handleLoginWithFacebook, isRegisterLoading } = useContext(AuthContext);

  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const navigate = useNavigate()

  const config = {
    className: "shadow-none",
    text: "Log in with Facebook",
    icon: "facebook",
    iconFormat: (name) => `fa fa-${name}`,
    style: { background: "#3b5998", marginTop: "0px" },
    activeStyle: { background: "#293e69" },
    size: "39px"
  };
  /** My Facebook login button. */
  const MyFacebookLoginButton = createButton(config);

  const onLoginUser = (e) => {
    setSpinnerLoading(true);
    loginUser(e);
    setTimeout(() => {
      setSpinnerLoading(false);
    }, 1000);
  }

  // const handleLoginWithGoogle = async (response) => {
  //   // console.log("id_token", response.credential)
  //   const payload = response.credential
  //   const server_res = await axios.post("http://localhost:8000/api/auth/google/", { "access_token": payload })
  //   console.log(server_res)
  // }

  useEffect(() => {
    const token = localStorage.getItem('authTokens');
    if (token)
      navigate('/');
    else
      navigate('/login');

    /* global google */
    // google.accounts.id.initialize({
    //   client_id: "1014960776137-2htfln1r4dvtoglso2cbmg9p45s0ihn0.apps.googleusercontent.com",
    //   callback: handleLoginWithGoogle
    // });
    // google.accounts.id.renderButton(
    //   document.getElementById("signInDiv"),
    //   { theme: "outline", size: "large", text: "continue_with", shape: "circle", width: "280" }
    // );
  }, [])

  return (
    <>
      {/* Modal */}
      <div className="modal fade modal-lg modal-tall" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            {isRegisterLoading ?
              <div className="text-center">
                <div className="spinner-border my-5" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p style={{fontSize: 13, fontWeight: 600}}>Loading. Please wait...</p>
              </div> :
              <form onSubmit={registerUser}>
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">Register</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div className="modal-body">
                  <div className='container'>
                    <div className="row gx-3 gy-2">
                      <div className="col-sm">
                        <label className="visually-hidden" htmlFor="specificSizeInputGroupEmail">Email</label>
                        <div className="input-group">
                          <div className="input-group-text"><FontAwesomeIcon icon={faEnvelope} /></div>
                          <input type="email" className="form-control" id="specificSizeInputGroupEmail" placeholder="Email" name='email' required />
                        </div>
                      </div>
                      <div className="col-sm">
                        <label className="visually-hidden" htmlFor="specificSizeInputGroupPassword">Password</label>
                        <div className="input-group">
                          <div className="input-group-text"><FontAwesomeIcon icon={faLock} /></div>
                          <input type="password" className="form-control" id="specificSizeInputGroupPassword" placeholder="password" name='pwd' required />
                        </div>
                        <ul style={{ lineHeight: "180%", listStyleType: "disc", color: 'green', fontSize: 9 }}>
                          <li>Can’t be similar to your personal info.</li>
                          <li>Must contain at least 8 characters.</li>
                          <li>Can’t be a commonly used password.</li>
                          <li>Can’t be entirely numeric.</li>
                        </ul>
                      </div>
                      <div className="col-sm">
                        <label className="visually-hidden" htmlFor="specificSizeInputGroupPassword2">Confirm Password</label>
                        <div className="input-group">
                          <div className="input-group-text"><FontAwesomeIcon icon={faLock} /></div>
                          <input type="password" className="form-control" id="specificSizeInputGroupPassword2" placeholder="confirm password" name='pwd2' required />
                        </div>
                        {/* <ul style={{lineHeight:"180%",listStyleType: "disc", color: 'green'}}>
                        <li>Enter the same password as before, for verification.</li>
                      </ul> */}
                      </div>
                      {/* <div className="col-sm">
                      <label className="visually-hidden" htmlFor="specificSizeInputGroupContactNo">ContactNo</label>
                      <div className="input-group">
                        <div className="input-group-text"><FontAwesomeIcon icon={faPhone} /></div>
                        <input type="text" className="form-control" id="specificSizeInputGroupContactNo" placeholder="Contact No" name='phone' required />
                      </div>
                    </div>
                    <div className="col-sm">
                      <label className="visually-hidden" htmlFor="specificSizeInputGroupSportType">Sport Type</label>
                      <div className="input-group">
                        <div className="input-group-text"><FontAwesomeIcon icon={faFootball} /></div>
                        <select className="form-select" aria-label="Default select example" name='sporttype' required>
                          <option value="">Open this select menu</option>
                          <option value="Basketball">Basketball</option>
                          <option value="Cricket">Cricket</option>
                          <option value="Football">Football</option>
                        </select>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button type="submit" className="btn btn-primary">Register</button>
                </div>
              </form>
            }
          </div>
        </div>
      </div>

      {spinnerLoading &&
        <div className='text-center mt-3'>
          <Spinner animation="border" />
        </div>
      }
      <div className="d-flex aligns-items-center justify-content-center card text-center w-75 position-absolute top-50 start-50 translate-middle shadow">
        <div className="row g-0">
          <div className="col-md-4">
            {/* <img src="https://cdn.pixabay.com/photo/2015/11/03/08/54/football-1019776_1280.jpg" className="img-fluid rounded-start" alt="..." /> */}
            <img src="https://cdn.pixabay.com/photo/2014/06/20/12/54/runners-373099_1280.jpg" className="img-fluid rounded-start" alt="..." />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h3 className="card-title">Welcome! Please login to continue</h3>
              <form onSubmit={onLoginUser}>
                <div className="form-floating mt-5">
                  <input type="email" className="form-control" id="floatingInputValue" placeholder="enter the username" name="email" required />
                  <label htmlFor="floatingInputValue">Email</label>
                </div>
                <div className='form-floating mt-3'>
                  <input type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password" required />
                  <label htmlFor="floatingPassword">Password</label>
                </div>
                {!spinnerLoading ?
                  <button type="button" className="btn btn-link btn-lg mt-1" style={{ textDecoration: 'none' }} onClick={() => navigate('/forgotpassword')}>Forgot Password?</button>
                  :
                  <button type="button" className="btn btn-link btn-lg mt-1" style={{ textDecoration: 'none' }} disabled>Forgot Password?</button>
                }
                {/* <div className="d-grid gap-2 d-md-block mt-5"> */}
                <div className="d-grid gap-2 mt-5">
                  {!spinnerLoading ?
                    <>
                      <button className="btn btn-outline-primary btn-lg" type="submit">Login</button>
                      <button className="btn btn-outline-primary btn-lg" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">Register</button>
                    </>
                    :
                    <>
                      <button className="btn btn-outline-primary btn-lg" disabled>Login</button>
                      <button className="btn btn-outline-primary btn-lg" type="button" disabled>Register</button>
                    </>
                  }
                </div>
                <div className="d-grid gap-2 mt-5 d-flex justify-content-center">
                  {/* <button className="btn btn-outline-primary btn-lg" type="submit">Login with google</button> */}
                  {/* <div id="signInDiv" className='gsignIn'></div> */}
                  {/* <button className="btn btn-outline-primary btn-lg" type="submit">Login with facebook</button> */}
                  {/* <div className=''> */}
                  {/* <LoginSocialGoogle
                      client_id={"1014960776137-2htfln1r4dvtoglso2cbmg9p45s0ihn0.apps.googleusercontent.com"}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={({ provider, data }) => {
                        console.log(provider, data);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <GoogleLoginButton />
                    </LoginSocialGoogle> */}
                  <GoogleOAuthProvider clientId="1014960776137-2htfln1r4dvtoglso2cbmg9p45s0ihn0.apps.googleusercontent.com">
                    <GoogleLogin
                      // buttonText="Sign in with Google"
                      // style={{ width: '250px', height: '40px' }}
                      onSuccess={credentialResponse => {
                        // console.log(credentialResponse);
                        handleLoginWithGoogle(credentialResponse);
                      }}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    />
                  </GoogleOAuthProvider>
                  {/* </div>
                  <div> */}
                  <LoginSocialFacebook
                    appId="405373689202915"
                    onResolve={(response) => {
                      // console.log(response);
                      handleLoginWithFacebook(response);
                    }}
                    onReject={(error) => {
                      console.log(error);
                    }}
                  >
                    <MyFacebookLoginButton />
                  </LoginSocialFacebook>
                  {/* </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
      <ToastContainer autoClose={2000} />
    </>
  )
}

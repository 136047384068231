import React, { useState, useContext } from 'react'
import Navbar from '../components/Navbar'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { CHANGE_PASSWORD_URL } from '../services/Constants';
import AuthContext from '../context/AuthContext';
import Spinner from 'react-bootstrap/Spinner';

export default function ChangePassword() {

    let { 
        notifyPasswordChangedSuccessfully, 
        notifyPasswordError, 
        notifyError, 
        authTokens,
        currentUser 
    } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);

    const navigate = useNavigate();

    const navigateToSearchDataWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    const handleChangePasswordSubmit = async (e) => {
        e.preventDefault();
        console.log('Form Submitted');
        setSpinnerLoading(true);
        // const formData = new FormData();
        // formData.append("token", e.target.token.value);
        // formData.append("password", e.target.password.value);
        let response = fetch(`${CHANGE_PASSWORD_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify({ 'old_password': e.target.oldpassword.value, 'new_password': e.target.newpassword.value })
        })
        let data = (await response).json()
        console.log('data', data)
        let status = (await response).status
        console.log('status', status)
        if (status === 200) {
            notifyPasswordChangedSuccessfully();
            setSpinnerLoading(false);
        } else if (status === 400) {
            notifyPasswordError();
            setSpinnerLoading(false);
        } else {
            notifyError();
            setSpinnerLoading(false);
        }
    }

    return (
        <>
            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={currentUser.is_flag} profileType={currentUser.account_type}/>
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null}
                            </ul>
                        </div>
                    </div>
                </div>
            }

            {spinnerLoading ?
                <div className='text-center mt-5'>
                    <Spinner animation="border" />
                </div> :
                <Container>
                    <div className="d-flex align-items-center justify-content-center vh-100">
                        <Card style={{ width: '30rem' }}>
                            <Card.Body>
                                <Form onSubmit={handleChangePasswordSubmit}>
                                    {/* <Row> */}
                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            id="floatingInputCustomOldPassword"
                                            type="password"
                                            placeholder="Enter the old password"
                                            name='oldpassword'
                                            required
                                        />
                                        <label htmlFor="floatingInputCustomOldPassword">Old password</label>
                                    </Form.Floating>
                                    <Form.Floating className="mb-3">
                                        <Form.Control
                                            id="floatingInputCustomNewPassword"
                                            type="password"
                                            placeholder="Enter the new password"
                                            name='newpassword'
                                            required
                                        />
                                        <label htmlFor="floatingInputCustomNewPassword">New Password</label>
                                    </Form.Floating>
                                    {/* </Row> */}
                                    <Row className='mx-1'>
                                        <Button variant="primary" type='submit'>Submit</Button>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            }
            <ToastContainer />
        </>
    )
}

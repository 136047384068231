import React, { useContext, useState } from 'react';
import { Card, Row, Form, Col, Button, Container, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { REGISTER_AS_INSTITUTE_URL } from '../services/Constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Register = () => {

    let { notifyCustomSuccessMessage, notifyCustomErrorMessage } = useContext(AuthContext);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paramEmail = params.get('email');
    // const paramType = params.get('type');

    const navigate = useNavigate();

    const [email, setEmail] = useState(paramEmail);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [validated, setValidated] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);

    const registerUser = async () => {
        // e.preventDefault();
        console.log('Form Register Submitted');
        setValidated(true);
        setSpinnerLoading(true);
        if (email === '') {
            setSpinnerLoading(false);
            return;
        }
        if (password === '') {
            setSpinnerLoading(false);
            return;
        }
        if (confirmPassword === '') {
            setSpinnerLoading(false);
            return;
        }
        if (confirmPassword !== password) {
            setSpinnerLoading(false);
            notifyCustomErrorMessage('Password and confirm password does not match.')
            return;
        }
        let response = fetch(`${REGISTER_AS_INSTITUTE_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email': email,
                'password': password
            })
        })
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            notifyCustomSuccessMessage('Registration successfull');
            setSpinnerLoading(false);
            setTimeout(() => {
                navigate('/login')
            }, 2000);
        }else if(status===400){
            setSpinnerLoading(false);
            notifyCustomErrorMessage((await data).password[0]);
        }else if(status===401){
            setSpinnerLoading(false);
            notifyCustomErrorMessage('Incorrect old password');
        }else if(status===404){
            setSpinnerLoading(false);
            notifyCustomErrorMessage('User does not exist');
        }else{
            notifyCustomErrorMessage('Something went wrong. Try again later.');
        }
    }

    return (
        <>
            {spinnerLoading ?
                <div className='text-center mt-5'>
                    <Spinner animation="border" />
                </div> :
                <Container>
                    <div className="d-flex align-items-center justify-content-center vh-100">
                        {/* <h1 className='text-center'>Create your account</h1> */}
                        <Card style={{ width: '70rem' }}>
                            <Card.Body>
                                <h1 className='text-center mb-5' >Create your account</h1>
                                <Form noValidate validated={validated}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col}>
                                            <Form.Label className='custom-label'>Email
                                                <span className='text-danger'> * </span>
                                            </Form.Label>
                                            <Form.Control
                                                className='input'
                                                type="email"
                                                size='lg'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Email cannot be empty
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label className='custom-label'>Password
                                                <span className='text-danger'> * </span>
                                            </Form.Label>
                                            <Form.Control
                                                className='input'
                                                type="password"
                                                size='lg'
                                                placeholder="Enter the password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <ul style={{ listStyleType: "disc", color: 'green', fontSize: 11 }}>
                                                <li>Can’t be similar to your personal info.</li>
                                                <li>Must contain at least 8 characters.</li>
                                                <li>Can’t be a commonly used password.</li>
                                                <li>Can’t be entirely numeric.</li>
                                            </ul>
                                            <Form.Control.Feedback type="invalid">
                                                Password cannot be empty
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label className='custom-label'>Confirm Password
                                                <span className='text-danger'> * </span>
                                            </Form.Label>
                                            <Form.Control
                                                className='input'
                                                type="password"
                                                size='lg'
                                                placeholder="Enter the password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Confirm Password cannot be empty
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button variant="primary" size='lg' onClick={registerUser}>Register</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            }
            <ToastContainer />
        </>
    )
}

import React, { useState, useEffect, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import { EndorsementSideNavbar } from '../components/EndorsementSideNavbar';
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal, Spinner } from 'react-bootstrap';
import { FOOTBALLCOACHENDORSEMENTREQUEST_URL, FOOTBALLPLAYERENDORSEMENTREQUEST_URL, GET_ALLFOOTBALLCOACHENDORSEMENTREQUESTUNDERPARTICULARUSER_URL, GET_ALLFOOTBALLPLAYERENDORSEMENTREQUESTUNDERPARTICULARUSER_URL, GET_FOOTBALLCOACHENDORSEMENTREQUEST_URL, GET_FOOTBALLPLAYERENDORSEMENTREQUEST_URL, GETFOOTBALLPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL, PLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL } from '../services/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../context/AuthContext';

export default function Endosements() {

  let {
    user,
    notifyCustomSuccessMessage,
    notifyError
  } = useContext(AuthContext)

  const navigate = useNavigate();

  const [key, setKey] = useState('player');

  // State variable of search user profile in Navbar

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  // State variable used to manage loading of page

  const [spinnerLoading, setSpinnerLoading] = useState(true);

  // State variable used for endorsement request from agent

  const [agentEndorsementRequest, setAgentEndorsementRequest] = useState([]);
  const [agentEndorsementId, setAgentEndorsementId] = useState(null);
  const [agentFromEndorser, setAgentFromEndorser] = useState('');
  const [agentFromYear, setAgentFromYear] = useState('');
  const [agentToYear, setAgentToYear] = useState('');
  const [agentUserName, setAgentUserName] = useState('');
  const [agentUserProfileType, setAgentUserProfileType] = useState('');
  const [agentStatus, setAgentStatus] = useState('');
  const [agentComments, setAgentComments] = useState('');
  const [agentRemarks, setAgentRemarks] = useState('');

  const [agentStatusError, setAgentStatusError] = useState('');

  // State variable used for endorsement request from player

  const [playerEndorsementRequest, setPlayerEndorsementRequest] = useState([]);
  const [playerEndorsementId, setPlayerEndorsementId] = useState(null);
  const [playerFromEndorser, setPlayerFromEndorser] = useState('');
  const [playerFromYear, setPlayerFromYear] = useState('');
  const [playerToYear, setPlayerToYear] = useState('');
  const [playerLeagueType, setPlayerLeagueType] = useState('');
  const [playerLeagueName, setPlayerLeagueName] = useState('');
  const [playerCountry, setPlayerCountry] = useState('');
  const [playerClub, setPlayerClub] = useState('');
  const [playerGamesPlayed, setPlayerGamesPlayed] = useState('');
  const [playerGoals, setPlayerGoals] = useState('');
  const [playerAssists, setPlayerAssists] = useState('');
  const [playerPasses, setPlayerPasses] = useState('');
  const [playerGoalSaved, setPlayerGoalSaved] = useState('');
  const [playerDribbles, setPlayerDribbles] = useState('');
  const [playerInterceptions, setPlayerInterceptions] = useState('');
  const [playerTackels, setPlayerTackels] = useState('');
  const [playerShotsOnGoal, setPlayerShotsOnGoal] = useState('');
  const [playerCleanSheets, setPlayerCleanSheets] = useState('');
  const [playerYellowCard, setPlayerYellowCard] = useState('');
  const [playerRedCard, setPlayerRedCard] = useState('');
  const [playerAchievements, setPlayerAchievements] = useState('');
  const [playerStatus, setPlayerStatus] = useState('');
  const [playerComments, setPlayerComments] = useState('');
  const [playerRemarks, setPlayerRemarks] = useState('');

  const [playerStatusError, setPlayerStatusError] = useState('');

  // State variable used for endorsement request from player

  const [coachEndorsementRequest, setCoachEndorsementRequest] = useState([]);
  const [coachEndorsementId, setCoachEndorsementId] = useState(null);
  const [coachFromEndorser, setCoachFromEndorser] = useState('');
  const [coachFromYear, setCoachFromYear] = useState('');
  const [coachToYear, setCoachToYear] = useState('');
  const [coachLeagueType, setCoachLeagueType] = useState('');
  const [coachLeagueName, setCoachLeagueName] = useState('');
  const [coachCountry, setCoachCountry] = useState('');
  const [coachClub, setCoachClub] = useState('');
  const [coachStatus, setCoachStatus] = useState('');
  const [coachComments, setCoachComments] = useState('');
  const [coachRemarks, setCoachRemarks] = useState('');
  const [coachAchievements, setCoachAchievements] = useState('');

  const [coachStatusError, setCoachStatusError] = useState('');

  // State variable to manage modals

  const [showEditAgentEndorsementModal, setShowEditAgentEndorsementModal] = useState(false);
  const [showEditPlayerEndorsementModal, setShowEditPlayerEndorsementModal] = useState(false);
  const [showEditCoachEndorsementModal, setShowEditCoachEndorsementModal] = useState(false);

  // State variable to manage form validation

  const [validatedAgentEndorsementRequest, setValidatedAgentEndorsementRequest] = useState(false);
  const [validatedPlayerEndorsementRequest, setValidatedPlayerEndorsementRequest] = useState(false);
  const [validatedCoachEndorsementRequest, setValidatedCoachEndorsementRequest] = useState(false);

  // function responsible of search data with navigation

  const navigateToSearchDataWithParams = (item) => {
    if (item.account_type === 'user')
      navigate(`/user/viewprofile?user_id=${item.id}`);
    else
      navigate(`/institute/viewprofile?institute_id=${item.id}`);
  };

  const navigateToSearchAllWithParams = (item) => {
    console.log('navigateToSearchAllWithParams');
    console.log('Search Data:', item);
    navigate("/searchall", { state: { item } });
  };

  // functions used to handle modal open close

  const handleEditPlayerEndorsementModalClose = () => {
    setShowEditPlayerEndorsementModal(false)
    setPlayerEndorsementId(null)
    setPlayerFromEndorser('')
    setPlayerToYear('')
    setPlayerFromYear('')
    setPlayerStatus('')
    setPlayerRemarks('')
    setPlayerStatusError('')
    setPlayerAchievements('')
    setPlayerAssists('')
    setPlayerCleanSheets('')
    setPlayerComments('')
    setPlayerCountry('')
    setPlayerDribbles('')
    setPlayerEndorsementId(null)
    setPlayerGamesPlayed('')
    setPlayerGoalSaved('')
    setPlayerGoals('')
    setPlayerInterceptions('')
    setPlayerLeagueName('')
    setPlayerLeagueType('')
    setPlayerPasses('')
    setPlayerRedCard('')
    setPlayerShotsOnGoal('')
    setPlayerTackels('')
    setPlayerYellowCard('')
    setValidatedPlayerEndorsementRequest(false)
  }

  const handleEditCoachEndorsementModalClose = () => {
    setShowEditCoachEndorsementModal(false)
    setCoachEndorsementId(null)
    setCoachFromEndorser('')
    setCoachToYear('')
    setCoachFromYear('')
    setCoachStatus('')
    setCoachRemarks('')
    setCoachStatusError('')
    setCoachAchievements('')
    setCoachClub('')
    setCoachComments('')
    setCoachCountry('')
    setCoachEndorsementId(null)
    setCoachLeagueName('')
    setCoachLeagueType('')
    setValidatedCoachEndorsementRequest(false)
  }

  const handleEditAgentEndorsementModalClose = () => {
    setShowEditAgentEndorsementModal(false)
    setAgentEndorsementId(null)
    setAgentFromEndorser('')
    setAgentToYear('')
    setAgentFromYear('')
    setAgentUserName('')
    setAgentUserProfileType('')
    setAgentStatus('')
    setAgentRemarks('')
    setAgentStatusError('')
    setValidatedAgentEndorsementRequest(false)
  }

  // UPDATE API endpoints

  const handleUpdatePlayerEndorsementRequest = async () => {
    // event.preventDefault();
    setValidatedPlayerEndorsementRequest(true)
    // setIsLoading(true)
    const formData = new FormData();
    if (playerStatus === '') {
      setPlayerStatusError('Status cannot be empty')
      return;
    } else {
      setPlayerStatusError('')
      formData.append("status", playerStatus);
    }
    formData.append("remarks", playerRemarks);
    let response = fetch(`${FOOTBALLPLAYERENDORSEMENTREQUEST_URL}${playerEndorsementId}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    // let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Successfully updated the data!')
      setShowEditPlayerEndorsementModal(false)
      setPlayerEndorsementId(null)
      setPlayerFromEndorser('')
      setPlayerToYear('')
      setPlayerFromYear('')
      setPlayerStatus('')
      setPlayerRemarks('')
      setPlayerStatusError('')
      setPlayerAchievements('')
      setPlayerAssists('')
      setPlayerCleanSheets('')
      setPlayerComments('')
      setPlayerCountry('')
      setPlayerDribbles('')
      setPlayerEndorsementId(null)
      setPlayerGamesPlayed('')
      setPlayerGoalSaved('')
      setPlayerGoals('')
      setPlayerInterceptions('')
      setPlayerLeagueName('')
      setPlayerLeagueType('')
      setPlayerPasses('')
      setPlayerRedCard('')
      setPlayerShotsOnGoal('')
      setPlayerTackels('')
      setPlayerYellowCard('')
      setValidatedPlayerEndorsementRequest(false)
    } else {
      notifyError()
    }
  }

  const handleUpdateCoachEndorsementRequest = async () => {
    // event.preventDefault();
    setValidatedCoachEndorsementRequest(true)
    // setIsLoading(true)
    const formData = new FormData();
    if (coachStatus === '') {
      setCoachStatusError('Status cannot be empty')
      return;
    } else {
      setCoachStatusError('')
      formData.append("status", coachStatus);
    }
    formData.append("remarks", coachRemarks);
    let response = fetch(`${FOOTBALLCOACHENDORSEMENTREQUEST_URL}${coachEndorsementId}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    // let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Successfully updated the data!')
      setShowEditCoachEndorsementModal(false)
      setCoachAchievements('')
      setCoachClub('')
      setCoachComments('')
      setCoachCountry('')
      setCoachEndorsementId(null)
      setCoachFromEndorser('')
      setCoachFromYear('')
      setCoachLeagueName('')
      setCoachLeagueType('')
      setCoachRemarks('')
      setCoachStatus('')
      setCoachStatusError('')
      setCoachToYear('')
      setValidatedCoachEndorsementRequest(false)
    } else {
      notifyError()
    }
  }

  const handleUpdateAgentEndorsementRequest = async (event) => {
    event.preventDefault();
    setValidatedAgentEndorsementRequest(true)
    // setIsLoading(true)
    const formData = new FormData();
    if (agentStatus === '') {
      setAgentStatusError('Status cannot be left empty')
      return;
    } else {
      setAgentStatusError('')
      formData.append("status", agentStatus);
    }
    formData.append("remarks", agentRemarks);
    let response = fetch(`${PLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL}${agentEndorsementId}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    // let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Successfully updated the data!')
      setAgentEndorsementId(null)
      setAgentFromEndorser('')
      setAgentToYear('')
      setAgentFromYear('')
      setAgentUserName('')
      setAgentUserProfileType('')
      setAgentStatus('')
      setAgentRemarks('')
      setAgentStatusError('')
      setValidatedAgentEndorsementRequest(false)
      setShowEditAgentEndorsementModal(false)
    } else {
      notifyError()
    }
  }

  // GET API endpoints

  const getEndorsementRequestInfoFromAgent = async () => {
    let response = fetch(`${PLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL}${user.user_id}/request_list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const endorsementData = (await data)
    console.log('Endorsement request info from agent:', endorsementData)
    const status = (await response).status
    if (status === 200) {
      const endorsementRequestData = (await endorsementData)
      setAgentEndorsementRequest(endorsementRequestData)
      setSpinnerLoading(false)
    } else {
      notifyError()
    }
  }

  const getEndorsementRequestInfoFromPlayer = async () => {
    let response = fetch(`${GET_ALLFOOTBALLPLAYERENDORSEMENTREQUESTUNDERPARTICULARUSER_URL}?user_id=${user.user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const endorsementData = (await data)
    console.log('Endorsement request info player:', endorsementData)
    const status = (await response).status
    if (status === 200) {
      const endorsementRequestData = (await endorsementData)
      setPlayerEndorsementRequest(endorsementRequestData)
      setSpinnerLoading(false)
    } else {
      notifyError()
    }
  }

  const getEndorsementRequestInfoFromCoach = async () => {
    let response = fetch(`${GET_ALLFOOTBALLCOACHENDORSEMENTREQUESTUNDERPARTICULARUSER_URL}?user_id=${user.user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const endorsementData = (await data)
    console.log('Endorsement request info from Coach:', endorsementData)
    const status = (await response).status
    if (status === 200) {
      const endorsementRequestData = (await endorsementData)
      setCoachEndorsementRequest(endorsementRequestData)
      setSpinnerLoading(false)
    } else {
      notifyError()
    }
  }

  const getPlayerEndorsementRequestData = async (id) => {
    let response = fetch(`${GET_FOOTBALLPLAYERENDORSEMENTREQUEST_URL}${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const endorsementData = (await data)
    console.log('Endorsement Request Player:', endorsementData)
    const status = (await response).status
    if (status === 200) {
      const name = endorsementData.from_endorsee.first_name + ' ' + endorsementData.from_endorsee.last_name;
      setPlayerEndorsementId(id)
      setPlayerFromEndorser(name)
      setPlayerFromYear(endorsementData.player_career_history.from_year)
      setPlayerToYear(endorsementData.player_career_history.to_year)
      setPlayerLeagueType(endorsementData.player_career_history.league_type)
      setPlayerLeagueName(endorsementData.player_career_history.league_name)
      setPlayerCountry(endorsementData.player_career_history.country_name)
      setPlayerClub(endorsementData.player_career_history.club_name)
      setPlayerGoalSaved(endorsementData.player_career_history.club_saved_goals)
      setPlayerGoals(endorsementData.player_career_history.club_goals)
      setPlayerAssists(endorsementData.player_career_history.club_assists)
      setPlayerAchievements(endorsementData.player_career_history.achievements)
      setPlayerCleanSheets(endorsementData.player_career_history.clean_sheets_per_game)
      setPlayerPasses(endorsementData.player_career_history.club_passes)
      setPlayerRedCard(endorsementData.player_career_history.club_red_card)
      setPlayerYellowCard(endorsementData.player_career_history.club_yellow_card)
      setPlayerDribbles(endorsementData.player_career_history.dribles_completed_per_game)
      setPlayerGamesPlayed(endorsementData.player_career_history.games_played)
      setPlayerInterceptions(endorsementData.player_career_history.interceptions_per_game)
      setPlayerShotsOnGoal(endorsementData.player_career_history.shots_per_game)
      setPlayerTackels(endorsementData.player_career_history.takles_per_game)
      setPlayerStatus(endorsementData.status)
      setPlayerComments(endorsementData.comments)
    } else {
      notifyError()
    }
  }

  const getCoachEndorsementRequestData = async (id) => {
    let response = fetch(`${GET_FOOTBALLCOACHENDORSEMENTREQUEST_URL}${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const endorsementData = (await data)
    console.log('Endorsement Request from coach:', endorsementData)
    const status = (await response).status
    if (status === 200) {
      const name = endorsementData.from_endorsee.first_name + ' ' + endorsementData.from_endorsee.last_name;
      setCoachEndorsementId(id)
      setCoachFromEndorser(name)
      setCoachFromYear(endorsementData.coach_career_history.from_year)
      setCoachToYear(endorsementData.coach_career_history.to_year)
      setCoachAchievements(endorsementData.coach_career_history.achievements)
      setCoachClub(endorsementData.coach_career_history.club_name)
      setCoachCountry(endorsementData.coach_career_history.country_name)
      setCoachLeagueName(endorsementData.coach_career_history.league_name)
      setCoachLeagueType(endorsementData.coach_career_history.league_type)
      setCoachStatus(endorsementData.status)
      setCoachComments(endorsementData.comments)
    } else {
      notifyError()
    }
  }

  const getEndorsementRequestData = async (id) => {
    // alert('Profile Description Id: ' + id)
    setAgentEndorsementId(id)
    let response = fetch(`${GETFOOTBALLPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL}${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const endorsementData = (await data)
    console.log('Endorsement Request from agent:', endorsementData)
    const status = (await response).status
    if (status === 200) {
      const name = endorsementData.from_endorsee.first_name + ' ' + endorsementData.from_endorsee.last_name
      setAgentFromEndorser(name)
      setAgentFromYear(endorsementData.agent_players_coaches_under_me.agent_career_history.from_year)
      setAgentToYear(endorsementData.agent_players_coaches_under_me.agent_career_history.to_year)
      setAgentUserName(endorsementData.agent_players_coaches_under_me.name)
      setAgentUserProfileType(endorsementData.agent_players_coaches_under_me.type)
      setAgentStatus(endorsementData.status)
      setAgentComments(endorsementData.comments)
    } else {
      notifyError()
    }
  }

  // useEffect event called on page load

  useEffect(() => {
    getEndorsementRequestInfoFromAgent();
    getEndorsementRequestInfoFromPlayer();
    getEndorsementRequestInfoFromCoach();
  }, [showEditPlayerEndorsementModal, showEditAgentEndorsementModal, showEditCoachEndorsementModal]);

  const playerEndorsementRequestList = playerEndorsementRequest.map((item) => {
    if (item.status === 'pending') {
      return (
        <tr key={item.id}>
          <td>
            <FontAwesomeIcon
              icon={faEdit}
              color='#2222e785'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEditPlayerEndorsementModal(true);
                getPlayerEndorsementRequestData(item.id);
              }}
            />
          </td>
          <td>{item.from_endorsee.first_name} {item.from_endorsee.last_name}</td>
          <td>{item.player_career_history.from_year} - {item.player_career_history.to_year}</td>
          <td>{item.status}</td>
          <td>{item.comments}</td>
        </tr>
      );
    }
  })

  const coachEndorsementRequestList = coachEndorsementRequest.map((item) => {
    if (item.status === 'pending') {
      return (
        <tr key={item.id}>
          <td>
            <FontAwesomeIcon
              icon={faEdit}
              color='#2222e785'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEditCoachEndorsementModal(true);
                getCoachEndorsementRequestData(item.id);
              }}
            />
          </td>
          <td>{item.from_endorsee.first_name} {item.from_endorsee.last_name}</td>
          <td>{item.coach_career_history.from_year} - {item.coach_career_history.to_year}</td>
          <td>{item.status}</td>
          <td>{item.comments}</td>
        </tr>
      );
    }
  })

  const agentEndorsementRequestList = agentEndorsementRequest.map((item) => {
    if (item.status === 'pending') {
      return (
        <tr key={item.id}>
          <td>
            <FontAwesomeIcon
              icon={faEdit}
              color='#2222e785'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowEditAgentEndorsementModal(true);
                getEndorsementRequestData(item.id);
              }}
            />
          </td>
          <td>{item.from_endorsee.first_name} {item.from_endorsee.last_name}</td>
          <td>{item.agent_players_coaches_under_me.agent_career_history.from_year} - {item.agent_players_coaches_under_me.agent_career_history.to_year}</td>
          <td>{item.agent_players_coaches_under_me.name}</td>
          <td>{item.agent_players_coaches_under_me.type}</td>
          <td>{item.status}</td>
          <td>{item.comments}</td>
        </tr>
      );
    }
  })

  return (
    <>

      <Modal show={showEditPlayerEndorsementModal} onHide={handleEditPlayerEndorsementModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Endorsement Request from Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedPlayerEndorsementRequest}>
            <Row className='mb-3'>
              <Form.Group as={Col} sm='3'>
                <Form.Label className='custom-label'>From Endorsee</Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  value={playerFromEndorser}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} sm='3'>
                <Form.Label className='custom-label'>Status
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  className='input'
                  size='lg'
                  value={playerStatus}
                  onChange={(e) => setPlayerStatus(e.target.value)}
                  required
                >
                  <option value=''>Open this select menu</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {playerStatusError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Card>
              <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Player Stats</Card.Header>
              <Card.Body>
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>From Year</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerFromYear}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>To Year</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerToYear}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>League Type</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={playerLeagueType}
                      disabled
                    />
                  </Form.Group>

                  {playerLeagueType === 'Domestic' &&
                    <Form.Group as={Col}>
                      <Form.Label className='custom-label'>Country Name</Form.Label>
                      <Form.Control
                        className='input'
                        type="text"
                        size='lg'
                        value={playerCountry}
                        disabled
                      />
                    </Form.Group>
                  }

                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Club Name</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={playerClub}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>League Name</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={playerLeagueName}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Games Played</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerGamesPlayed}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Goals</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerGoals}
                      disabled
                    />
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Assists</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerAssists}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Passes</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerPasses}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Goals Saved</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerGoalSaved}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Dribles completed</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerDribbles}
                      disabled
                    />
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Interceptions</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerInterceptions}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Tackles</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerTackels}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Shots on Goal</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerShotsOnGoal}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Clean Sheets</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerCleanSheets}
                      disabled
                    />
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Yellow Card</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerYellowCard}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>No. of Red Card</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={playerRedCard}
                      disabled
                    />
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Achievements</Form.Label>
                    <Form.Control
                      className='input'
                      value={playerAchievements}
                      as="textarea"
                      rows={5}
                      disabled
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>

            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Comments</Form.Label>
                <Form.Control
                  className='input'
                  value={playerComments}
                  as="textarea"
                  rows={5}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Remarks</Form.Label>
                <Form.Control
                  className='input'
                  placeholder="Give your remarks"
                  value={playerRemarks}
                  onChange={(e) => setPlayerRemarks(e.target.value)}
                  as="textarea"
                  rows={5}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditPlayerEndorsementModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdatePlayerEndorsementRequest}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditCoachEndorsementModal} onHide={handleEditCoachEndorsementModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Endorsement Request from Coach</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedCoachEndorsementRequest}>
            <Row className='mb-3'>
              <Form.Group as={Col} sm='3'>
                <Form.Label className='custom-label'>From Endorsee</Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  value={coachFromEndorser}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} sm='3'>
                <Form.Label className='custom-label'>Status
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  className='input'
                  size='lg'
                  value={coachStatus}
                  onChange={(e) => setCoachStatus(e.target.value)}
                  required
                >
                  <option value=''>Open this select menu</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {coachStatusError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Card>
              <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Coach Stats</Card.Header>
              <Card.Body>
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>From Year</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={coachFromYear}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>To Year</Form.Label>
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      value={coachToYear}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>League Type</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={coachLeagueType}
                      disabled
                    />
                  </Form.Group>

                  {coachLeagueType === 'Domestic' &&
                    <Form.Group as={Col}>
                      <Form.Label className='custom-label'>Country Name</Form.Label>
                      <Form.Control
                        className='input'
                        type="text"
                        size='lg'
                        value={coachCountry}
                        disabled
                      />
                    </Form.Group>
                  }

                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} sm='4'>
                    <Form.Label className='custom-label'>Club Name</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={coachClub}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm='4'>
                    <Form.Label className='custom-label'>League Name</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={coachLeagueName}
                      disabled
                    />
                  </Form.Group>

                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Achievements</Form.Label>
                    <Form.Control
                      className='input'
                      value={coachAchievements}
                      as="textarea"
                      rows={5}
                      disabled
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>

            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Comments</Form.Label>
                <Form.Control
                  className='input'
                  value={coachComments}
                  as="textarea"
                  rows={5}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Remarks</Form.Label>
                <Form.Control
                  className='input'
                  placeholder="Give your remarks"
                  value={coachRemarks}
                  onChange={(e) => setCoachRemarks(e.target.value)}
                  as="textarea"
                  rows={5}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditCoachEndorsementModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdateCoachEndorsementRequest}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditAgentEndorsementModal} onHide={handleEditAgentEndorsementModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Endorsement Request from Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedAgentEndorsementRequest}>
            <Row className='mb-3'>
              <Form.Group as={Col} sm="3">
                <Form.Label className='custom-label'>From Endorsee</Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  value={agentFromEndorser}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} sm="3">
                <Form.Label className='custom-label'>Status
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  className='input'
                  size='lg'
                  value={agentStatus}
                  onChange={(e) => setAgentStatus(e.target.value)}
                  required
                >
                  <option value=''>Open this select menu</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {agentStatusError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Card>
              <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Agent Stats</Card.Header>
              <Card.Body>
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>From Year</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={agentFromYear}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>To Year</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={agentToYear}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>User Name</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={agentUserName}
                      disabled
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>User Profile Type</Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      value={agentUserProfileType}
                      disabled
                    />
                  </Form.Group>

                </Row>
              </Card.Body>
            </Card>

            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Comments</Form.Label>
                <Form.Control
                  className='input'
                  value={agentComments}
                  onChange={(e) => setAgentComments(e.target.value)}
                  as="textarea"
                  rows={5}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Remarks</Form.Label>
                <Form.Control
                  className='input'
                  placeholder="Give your remarks"
                  value={agentRemarks}
                  onChange={(e) => setAgentRemarks(e.target.value)}
                  as="textarea"
                  rows={5}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditAgentEndorsementModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdateAgentEndorsementRequest}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} profileType={user.account_type} />
      {
        loading &&
        <div className='overlay'>
          <div className="container">
            <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
              <ul className='content-list' style={{ padding: 10 }}>
                {
                  searchData.length > 0 ?
                    searchData.slice(0, 3).map((item) => (
                      <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                        {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                      </li>
                    ))

                    : <li className='list-element'>No data found</li>
                }
                {searchData.length > 0 ?
                  <>
                    <hr />
                    <li className='see-all-btn'>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ width: 280 }}
                        onClick={() => navigateToSearchAllWithParams(searchData)}
                      >
                        See all
                      </button>
                    </li>
                  </> :
                  null}
              </ul>
            </div>
          </div>
        </div>
      }

      <Container>
        <Row>

          <EndorsementSideNavbar />

          <Col xs={9}>
            <Card className='mt-3'>
              <Card.Body>
                <h2 className='text-center'>Endorsement Request</h2>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                  justify
                  variant='underline'
                >
                  <Tab eventKey="player" title="PLAYER">
                    <Card>
                      <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Pending Request</Card.Header>
                      <Card.Body>
                        {spinnerLoading ?
                          <div className='text-center'>
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          </div> :
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>From Endorsee</th>
                                <th>Period</th>
                                <th>Status</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {playerEndorsementRequest.length > 0 ? playerEndorsementRequestList :
                                <tr>
                                  <td colSpan={5} className='text-center'>
                                    No pending request found
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        }
                      </Card.Body>
                    </Card>
                  </Tab>

                  <Tab eventKey="coach" title="COACH">
                    <Card>
                      <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Pending Request</Card.Header>
                      <Card.Body>
                        {spinnerLoading ?
                          <div className='text-center'>
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          </div>
                          :
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>From Endorsee</th>
                                <th>Period</th>
                                <th>Status</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {coachEndorsementRequest.length > 0 ? coachEndorsementRequestList :
                                <tr>
                                  <td colSpan={5} className='text-center'>
                                    No pending request found
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        }
                      </Card.Body>
                    </Card>
                  </Tab>


                  <Tab eventKey="agent" title="AGENT">
                    <Card>
                      <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Pending Request</Card.Header>
                      <Card.Body>
                        {spinnerLoading ?
                          <div className='text-center'>
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          </div>
                          :
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>From Endorsee</th>
                                <th>Period</th>
                                <th>User Name</th>
                                <th>User Profile Type</th>
                                <th>Status</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agentEndorsementRequest.length > 0 ? agentEndorsementRequestList :
                                <tr>
                                  <td colSpan={7} className='text-center'>
                                    No pending request found
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        }
                      </Card.Body>
                    </Card>
                  </Tab>

                </Tabs>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  )
}

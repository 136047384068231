import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../utils/Firebase";
import { CHANGEPRESENTADDRESS_URL, COMMENTS_URL, CONNECTREQUEST_URL, CREATENETWORKREQUEST_URL, INSTITUITIONHISTORY_URL, INSTITUITIONOFFICEBEARER_URL, INSTITUITION_URL, LIKES_URL, NETWORKCONNECTIONS_URL, NETWORKCONNECT_URL, NETWORKREQUEST_URL, NEWS_URL, POSTITEM_URL, USER_URL } from "./Constants";

export const handleNetworkConnectSubmit = async (item, currentUser) => {
    console.log('handleNetworkConnectSubmit');
    console.log('item:', item);
    let response = fetch(`${NETWORKREQUEST_URL}/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'from_user': currentUser.user_id,
            'to_user': item.id,
            'status': 'pending'
        })
    });
    console.log('response:', response)
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    console.log('status:', status)
    if (status === 201) {
        console.log("Status is true");
        handleNetworkConnectRequest(item, currentUser, data.id);
    }
};

export const handleNetworkConnectingSubmit = async (item, currentUser) => {
    console.log('handleNetworkConnectingSubmit');
    console.log('item:', item);
    // let response = fetch(`${NETWORKREQUEST_URL}/`, {
    let response = fetch(`${CREATENETWORKREQUEST_URL}/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'from_user': currentUser.user_id,
            'to_user': item.id,
            'status': 'pending'
        })
    });
    return response;
};

export const userPersonalInfo = async (id, authTokens) => {
    // e.preventDefault();
    console.log('user Personal Information');
    let response = fetch(`${USER_URL}/${id}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(authTokens.access)
        },
    })
    const data = (await response).json()
    console.log('data:', data)
    console.log('response:', response)
    const status = (await response).status
    if (status === 200) {
        const personalInfo = (await data)
        console.log('personalInfo: ', personalInfo)
        return personalInfo;
    } else {
        alert('something went wrong!')
    }
}

export const handleNetworkConnectRequestDelete = async (id) => {
    // alert(id)
    console.log('Handle Delete function called');
    let response = fetch(`${NETWORKREQUEST_URL}/${id}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    console.log('response:', response)
    // let status = (await response).status
    // if (status === 204) {
    //     alert('Successfully deleted the data!')
    // } else {
    //     alert('Something went wrong!')
    // }
}

export const handleNetworkConnectRequestAccept = async (item, currentUser) => {
    const requestId = item.id;
    console.log('handleNetworkConnectAccept');
    // console.log('item:', item);
    // console.log('item.from_user.id:', item.from_user.id);
    let response = fetch(`${NETWORKCONNECT_URL}/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([{
            'connect_to_user': item.from_user.id,
            'status': 'connected',
            'user_id': currentUser.user_id
        }, {
            'connect_to_user': currentUser.user_id,
            'status': 'connected',
            'user_id': item.from_user.id
        }])
    });
    console.log('response:', response)
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    console.log('status:', status)
    if (status === 201) {
        handleNetworkConnectUpdate(requestId);
        const user1Ref = doc(db, "users", currentUser.username);

        // Atomically add a new region to the "regions" array field.
        await updateDoc(user1Ref, {
            connected: arrayUnion(item.from_user.id)
        });

        const user2Ref = doc(db, "users", item.from_user.username);

        // Atomically add a new region to the "regions" array field.
        await updateDoc(user2Ref, {
            connected: arrayUnion(currentUser.user_id)
        });

        // firebase.firestore().collection('posts').doc(uid).set(
        //     { k: firebase.firestore.FieldValue.arrayUnion(...newArray) },
        //     { merge: true }
        // );
        alert('Data Submited Successfully');
    } else {
        alert('Something Went Wrong!')
    }
};

export const handleNetworkConnectUpdate = async (id) => {
    console.log('handleNetworkConnectUpdate');
    // console.log('item:', item);
    let response = fetch(`${NETWORKREQUEST_URL}/${id}/`, {
        method: "PATCH",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'status': 'accepted'
        })
    });
    console.log('response:', response)
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    console.log('status:', status)
};

export const handleNetworkConnectingUpdate = async (id, status) => {
    console.log('handleNetworkConnectingUpdate');
    // console.log('item:', item);
    let response = fetch(`${NETWORKREQUEST_URL}/${id}/`, {
        method: "PATCH",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'status': status
        })
    });
    return response;
};


export const handleNetworkConnectRequest = async (item, currentUser, networkRequestId) => {
    console.log('handleNetworkConnect');
    let response = fetch(`${NETWORKCONNECTIONS_URL}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([{
            'connect_to_user': item.id,
            'status': 'pending',
            'user_id': currentUser.user_id,
            'network_request_id': networkRequestId
        }, {
            'connect_to_user': currentUser.user_id,
            'status': 'pending',
            'user_id': item.id,
            'network_request_id': networkRequestId
        }])
    });
    console.log('response:', response)
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    console.log('status:', status)
};

export const handleNetworkConnectingRequest = async (item, currentUser, networkRequestId) => {
    console.log('handleNetworkConnectingRequest');
    let response = fetch(`${NETWORKCONNECTIONS_URL}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([{
            'connect_to_user': item.id,
            'status': 'pending',
            'user_id': currentUser.user_id,
            'network_request_id': networkRequestId
        }, {
            'connect_to_user': currentUser.user_id,
            'status': 'pending',
            'user_id': item.id,
            'network_request_id': networkRequestId
        }])
    });
    return response;
};

export const handleNetworkConnectingRequestAccept = async (id) => {
    console.log('handleNetworkConnectingRequestAccept');
    // console.log('item:', item);
    let response = fetch(`${CONNECTREQUEST_URL}/${id}/`, {
        method: "PATCH",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'status': 'connected'
        })
    });
    return response;
};

export const handlePost = async (formData) => {
    console.log('handlePost');
    // console.log('item:', item);
    // const formData = new FormData();
    // formData.append("user", currentUser.user_id);
    // formData.append("type", postType);
    // if (description !== '') {
    //     formData.append("description", description);
    // }
    // if (shareImage !== '') {
    //     formData.append("picture", shareImage);
    // }
    // if (videoLink !== '') {
    //     formData.append("video_link", videoLink);
    // }
    let response = fetch(`${POSTITEM_URL}`, {
        method: "POST",
        // headers: {
        //     'Content-Type': 'application/json',
        // },
        // body: JSON.stringify({
        //     'user': currentUser.user_id,
        //     'picture': shareImage,
        //     'description': description,
        //     'video_link': videoLink,
        //     'type': postType
        // })
        body: formData,
    });
    // console.log('response:', response)
    // let data = (await response).json()
    // console.log('data:', data)
    // let status = (await response).status
    // console.log('status:', status)
    return response;
};

export const handleSubmitComments = (event, CurrentUser, postId) => {
    event.preventDefault();
    // const data = new FormData(event.target);
    console.log('data: ', event.target.comment.value);
    console.log('user: ', CurrentUser.user_id);
    console.log('post id: ', postId);
    let response = fetch(`${COMMENTS_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'user': CurrentUser.user_id,
            'comment': event.target.comment.value,
            'post_id': postId
        })
    })
    return response;
}

export const handleSubmitLikes = (CurrentUser, postId) => {
    // event.preventDefault();
    // const data = new FormData(event.target);
    // console.log('data: ', event.target.comment.value);
    console.log('user: ', CurrentUser.user_id);
    console.log('post id: ', postId);
    let response = fetch(`${LIKES_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'user': CurrentUser.user_id,
            'post_id': postId
        })
    })
    return response;
}

export const handleDeleteLike = (postId) => {
    // event.preventDefault();
    // const data = new FormData(event.target);
    // console.log('data: ', event.target.comment.value);
    // console.log('user: ', CurrentUser.user_id);
    console.log('post id: ', postId);
    let response = fetch(`${LIKES_URL}${postId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return response;
}


export const handleEventPost = async (formData) => {
    console.log('handlePost');
    let response = fetch(`${NEWS_URL}`, {
        method: "POST",
        body: formData,
    });
    return response;
};

export const handleSportProfileClubSubmit = async (currentUser) => {
    console.log('handleSportProfileSubmit');
    // console.log('item:', item);
    const formData = new FormData();
    formData.append("user", currentUser.user_id);
    let response = fetch(`${INSTITUITION_URL}/`, {
        method: "POST",
        body: formData,
    });
    return response;
};

export const handleAddOfficeBearerSubmit = async (personName, personPosition, clubId) => {
    console.log('handleAddOfficeBearerSubmit');
    let response = fetch(`${INSTITUITIONOFFICEBEARER_URL}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'position': personPosition,
            'name': personName,
            'club_id': clubId
        })
    })
    return response;
}

export const handleOfficeBearerDelete = async (id) => {
    // alert(id)
    console.log('Handle Office Delete function called');
    let response = fetch(`${INSTITUITIONOFFICEBEARER_URL}/${id}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return response;
}

export const handleOfficeBearerUpdate = async (personName, personPosition, clubId) => {
    console.log('handleOfficeBearerUpdate');
    let response = fetch(`${INSTITUITIONOFFICEBEARER_URL}/${clubId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'position': personPosition,
            'name': personName
        })
    })
    return response;
};

export const handleAddClubCarreerHistorySubmit = (leagueName, period, gamesPlayed, gamesWon, gamesLost, gamesTied, points, position, clubId) => {
    console.log('handleAddClubCarreerHistorySubmit');
    let response = fetch(`${INSTITUITIONHISTORY_URL}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'period': period,
            'league_name': leagueName,
            'games_played': gamesPlayed,
            'games_won': gamesWon,
            'games_lost': gamesLost,
            'games_tied': gamesTied,
            'points': points,
            'position': position,
            'club_id': clubId
        })
    })
    return response;
}

export const handleClubCareerHistoryDelete = async (id) => {
    // alert(id)
    console.log('Handle Club History Delete function called');
    let response = fetch(`${INSTITUITIONHISTORY_URL}/${id}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return response;
}

export const handleClubCareerHistoryUpdate = async (leagueName, period, gamesPlayed, gamesWon, gamesLost, gamesTied, points, position, clubId) => {
    console.log('handleClubCareerHistoryUpdate');
    let response = fetch(`${INSTITUITIONHISTORY_URL}/${clubId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            'period': period,
            'league_name': leagueName,
            'games_played': gamesPlayed,
            'games_won': gamesWon,
            'games_lost': gamesLost,
            'games_tied': gamesTied,
            'points': points,
            'position': position
        })
    })
    return response;
};

export const handleChangePresentAddressSubmit = (presentAddressId, permanentAddressId, userId) => {
    console.log('handleChangePresentAddressSubmit');
    let response = fetch(`${CHANGEPRESENTADDRESS_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify([
            {
                "id": permanentAddressId,
                "permanent_user_id": null,
                "present_user_id": [userId]
            },
            {
                "id": presentAddressId,
                "permanent_user_id": userId
            }
        ])
    })
    return response;
}

export const handleSetPresentAddressSubmit = (presentAddressId, userId) => {
    console.log('handleSetPresentAddressSubmit');
    let response = fetch(`${CHANGEPRESENTADDRESS_URL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify([
            {
                "id": presentAddressId,
                "permanent_user_id": userId
            }
        ])
    })
    return response;
}

export const handleChangeAdminStatus = (userId, adminStatus, authTokens) => {
    console.log('handleChangeAdminStatus');
    let response = fetch(`${USER_URL}/${userId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + String(authTokens.access)
        },
        body: JSON.stringify(
            {
                "is_superuser": adminStatus
            }
        )
    })
    return response;
}
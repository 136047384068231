import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, Col, Row, Modal, Collapse, Button, Stack, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../components/Navbar'
import { MyPostCard } from '../components/CustomComponents';
import { handleDeleteLike, handlePost, handleSubmitComments, handleSubmitLikes, handleNetworkConnectSubmit, handleEventPost } from '../services/ApiServices';

export default function MyPost() {

    const navigate = useNavigate();

    let {
        currentUser,
        postData,
        getPostData,
        notifyError
    } = useContext(AuthContext)

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [showLikesModal, setShowLikesModal] = useState(false);
    const [likesData, setLikesData] = useState([]);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [commentsData, setCommentsData] = useState([]);
    const [openLoadMoreComments, setOpenLoadMoreComments] = useState(false);
    const [loadMoreCounter, setLoadMoreCounter] = useState(10);

    const clearFields = (event) => {
        // we have to convert event.target to array
        // we use from method to convert event.target to array
        // after that we will use forEach function to go through every input to clear it
        Array.from(event.target).forEach((e) => (e.value = ""));
    }

    const handleSubmit = async (event, postId, currentUser) => {
        event.preventDefault();
        console.log('data: ', event.target.comment.value);
        console.log('user: ', currentUser.user_id);
        console.log('post id: ', postId);
        const response = handleSubmitComments(event, currentUser, postId);
        console.log('response from handlePost:', response);
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 201) {
            getPostData();
            clearFields(event);
            // event.target.comment.reset();
            // reset();
        } else {
            notifyError();
        }
    }

    const handleLikes = async (postId, currentUser) => {
        // event.preventDefault();
        // console.log('data: ', event.target.comment.value);
        setButtonDisabled(true);
        console.log('user: ', currentUser.user_id);
        console.log('post id: ', postId);
        const response = handleSubmitLikes(currentUser, postId);
        console.log('response from handlePost:', response);
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 201) {
            getPostData();
            setTimeout(() => {
                setButtonDisabled(false)
            }, 6000);
        } else {
            notifyError();
            setTimeout(() => {
                setButtonDisabled(false)
            }, 4000);
        }
    }

    const handleDisLikes = async (postId) => {
        // event.preventDefault();
        // console.log('data: ', event.target.comment.value);
        // console.log('user: ', currentUser.user_id);
        setButtonDisabled(true);
        console.log('post id: ', postId);
        const response = handleDeleteLike(postId);
        console.log('response from handlePost:', response);
        // let data = (await response).json()
        // console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 204) {
            getPostData();
            setTimeout(() => {
                setButtonDisabled(false)
            }, 6000);
        } else {
            notifyError();
            setTimeout(() => {
                setButtonDisabled(false)
            }, 4000);
        }
    }



    const handleShowLikesModal = (likes) => {
        setShowLikesModal(true);
        setLikesData(likes);
    }

    const handleShowCommentsModal = (comments) => {
        setShowCommentsModal(true);
        setCommentsData(comments);
    }

    const navigateToSearchDataWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    const navigateToChatWithParams = (param1) => {
        navigate(`/messaging?param1=${param1}`);
    };

    const navigateToProfileWithParams = (item) => {
        console.log('navigateToProfileWithParams');
        console.log('Search Data:', item);
        // item.sport_profile_type.forEach((profile) => {
        //     if (profile.is_active) {
        //         if (profile.profile_type === 'Player') {
        //             console.log('player');
        //             navigate(`/player/searchplayer?param=${item.id}`);
        //         } else if (profile.profile_type === 'Coach') {
        //             console.log('coach');
        //             navigate(`/coach/searchcoach?param=${item.id}`);
        //         } else if (profile.profile_type === 'Agent') {
        //             console.log('agent');
        //             navigate(`/agent/searchagent?param=${item.id}`);
        //         } else if (profile.profile_type === 'Institution') {
        //             console.log('instituition');
        //             navigate(`/instituition/searchinstituition?param=${item.id}`);
        //         }
        //     } else {
        //         console.log('else part');
        //     }
        // })
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const postRequestList = postData.map((post) => {
        return (
            <MyPostCard
                post={post}
                currentUser={currentUser}
                isButtonDisabled={isButtonDisabled}
                handleSubmit={(event) => handleSubmit(event, post.id, currentUser)}
                handleLikes={() => handleLikes(post.id, currentUser)}
                handleDisLikes={(likeId) => handleDisLikes(likeId)}
                handleShowLikesModal={() => handleShowLikesModal(post.likes)}
                handleShowCommentsModal={() => handleShowCommentsModal(post.comments)}
                onClickMessage={() => navigateToChatWithParams(post.user.username)}
                onClickConnect={() => {
                    handleNetworkConnectSubmit(post.user, currentUser);
                    getPostData();
                }}
                onClickUser={() => navigateToProfileWithParams(post.user)}
            />
        )
    })

    const HandleLikesComponent = ({ postedLikes }) => {
        console.log('postedLikes: ', postedLikes);

        const likesPosted = postedLikes.map((postedLike) => {

            let currentProfile = '';
            let currentClub = '';

            postedLike.user.sport_profile_type.forEach((type) => {
                if (type.is_active) {
                    currentProfile = type.profile_type;
                    if (currentProfile.toLowerCase() === 'player') {
                        postedLike.user.player.forEach((player) => {
                            currentClub = 'at ' + player.current_club_inside_name;
                        });
                    } else if (currentProfile.toLowerCase() === 'coach') {
                        postedLike.user.coach.forEach((coach) => {
                            currentClub = 'at ' + coach.current_team;
                        });
                    }
                }
            });

            return (
                <Stack direction="horizontal" gap={2} className='mt-2'>
                    <Image src={postedLike.user.profile_photo} roundedCircle style={{ height: 35, width: 35 }} />
                    <div style={{ backgroundColor: 'whitesmoke', width: '100%', padding: 5, borderRadius: 5 }}>
                        <div className='justify-content-between d-flex' style={{ color: 'black' }}>
                            {postedLike.user.account_type === 'institute' ?
                                <span>{postedLike.user.club_name}</span> :
                                <span>{postedLike.user.first_name} {postedLike.user.last_name}</span>
                            }
                        </div>
                        <div className='mb-2 text-muted'>
                            <span>{currentProfile} {currentClub}</span>
                        </div>
                    </div>
                </Stack>
            );
        })

        return (
            <>
                {likesPosted}
            </>
        )
    }

    const HandleLoadMoreCommments = ({ postedComment, upperlimit, lowerlimit }) => {

        const commentsPosted = postedComment.slice(upperlimit, lowerlimit).map((postedComment) => {

            const isoDateString = postedComment.posted;
            const date = new Date(isoDateString);
            const localDateString = date.toLocaleDateString();
            const localTimeString = date.toLocaleTimeString();

            let currentProfile = '';
            let currentClub = '';

            postedComment.user.sport_profile_type.forEach((type) => {
                if (type.is_active) {
                    currentProfile = type.profile_type;
                    if (currentProfile.toLowerCase() === 'player') {
                        postedComment.user.player.forEach((player) => {
                            currentClub = 'at ' + player.current_club_inside_name;
                        });
                    } else if (currentProfile.toLowerCase() === 'coach') {
                        postedComment.user.coach.forEach((coach) => {
                            currentClub = 'at ' + coach.current_team;
                        });
                    }
                }
            });

            return (
                <Stack direction="horizontal" gap={2} className='mt-2'>
                    <Image src={postedComment.user.profile_photo} roundedCircle style={{ height: 35, width: 35 }} />
                    <div style={{ backgroundColor: 'whitesmoke', width: '100%', padding: 5, borderRadius: 5 }}>
                        <div className='justify-content-between d-flex' style={{ color: 'black' }}>
                            {postedComment.user.account_type === 'institute' ?
                                <span>{postedComment.user.club_name}</span> :
                                <span>{postedComment.user.first_name} {postedComment.user.last_name}</span>
                            }
                            <span>{localDateString} {localTimeString}</span>
                        </div>
                        <div className='mb-2 text-muted'>
                            {/* <span>Player at FC barcelona</span> */}
                            <span>{currentProfile} {currentClub}</span>
                        </div>
                        <span style={{ color: 'black' }}>{postedComment.comment}</span>
                    </div>
                </Stack>
            );
        })

        return (
            <>
                {commentsPosted}
            </>
        )
    }

    const handleCloseLikesModal = () => setShowLikesModal(false);

    const handleCloseCommentsModal = () => {
        setShowCommentsModal(false);
        setLoadMoreCounter(10);
    }

    useEffect(() => {
        getPostData();
    }, [])

    return (
        <>

            <Modal show={showLikesModal} onHide={handleCloseLikesModal} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Likes [{likesData.length}]
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HandleLikesComponent postedLikes={likesData} />
                </Modal.Body>
            </Modal>

            <Modal show={showCommentsModal} onHide={handleCloseCommentsModal} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Comments [{commentsData.length}]
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HandleLoadMoreCommments postedComment={commentsData} upperlimit={0} lowerlimit={10} />
                    <Collapse in={openLoadMoreComments}>
                        <HandleLoadMoreCommments postedComment={commentsData} upperlimit={10} lowerlimit={loadMoreCounter} />
                    </Collapse>
                    {commentsData.length > loadMoreCounter &&
                        <Button
                            variant="light"
                            className='mt-2'
                            onClick={() => { setOpenLoadMoreComments(!openLoadMoreComments); setLoadMoreCounter(loadMoreCounter + 10) }}
                        >
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            Load more...
                        </Button>
                    }
                </Modal.Body>
            </Modal>

            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} profileType={currentUser.account_type} />
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null}
                            </ul>
                        </div>
                    </div>
                </div>
            }

            <Container className='mt-3'>
                <Row className='justify-content-center'>
                    <Col md={6}>
                        {postRequestList}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

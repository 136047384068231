import React from 'react'
import { PendingRequest } from './PendingRequest'
import { NetworkConnect } from './NetworkConnect'

export const NetworkContent = () => {
    return (
        <div className='col-9'>
        <PendingRequest/>
        <NetworkConnect/>
        </div>
    )
}

import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { RESET_PASSWORD_CONFIRM_URL } from '../services/Constants';
import AuthContext from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ResetPassword() {

    let { notifyPasswordChangedSuccessfully, notifyResetPasswordTokenError, notifyError, notifyCustomPasswordError } = useContext(AuthContext);

    const navigate = useNavigate()

    const handleTokenPasswordSubmit = async (e) => {
        e.preventDefault();
        console.log('Form Submitted');
        // const formData = new FormData();
        // formData.append("token", e.target.token.value);
        // formData.append("password", e.target.password.value);
        let response = fetch(`${RESET_PASSWORD_CONFIRM_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'token': e.target.token.value, 'password': e.target.password.value })
        })
        let data = (await response).json()
        console.log('data', data)
        let status = (await response).status
        console.log('status', status)
        let passwordStatusResponse = [];
        if ((await data).password !== undefined) {
            console.log('response status password:', (await data).password)
            passwordStatusResponse = (await data).password;
            console.log('passwordStatusResponse:', passwordStatusResponse)
        }
        if (status === 200) {
            notifyPasswordChangedSuccessfully();
            // navigate('/login');
            setTimeout(() => {
                navigate('/login');
            }, 4000);
        } else if (status === 404) {
            notifyResetPasswordTokenError();
        } else if (status === 400) {
            if (passwordStatusResponse && passwordStatusResponse.length > 0) {
                // passwordStatusResponse.forEach((passwordStatus) => {
                //     notifyCustomPasswordError(passwordStatus);
                // })
                notifyCustomPasswordError(passwordStatusResponse[0]);
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 4000);
            }
        } else {
            notifyError();
        }
    }

    return (
        <>
            <Container>
                <div className="d-flex align-items-center justify-content-center vh-100">
                    <Card style={{ width: '30rem' }}>
                        <Card.Body>
                            <Form onSubmit={handleTokenPasswordSubmit}>
                                {/* <Row> */}
                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        id="floatingInputCustomToken"
                                        type="text"
                                        placeholder="Enter the token"
                                        name='token'
                                        required
                                    />
                                    <label htmlFor="floatingInputCustomToken">Token</label>
                                </Form.Floating>
                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        id="floatingInputCustomPassword"
                                        type="password"
                                        placeholder="Enter the new password"
                                        name='password'
                                        required
                                    />
                                    <label htmlFor="floatingInputCustomPassword">New Password</label>
                                    <ul style={{ lineHeight: "180%", listStyleType: "disc", color: 'green', fontSize:9 }}>
                                        <li>Can’t be similar to your personal info.</li>
                                        <li>Must contain at least 8 characters.</li>
                                        <li>Can’t be a commonly used password.</li>
                                        <li>Can’t be entirely numeric.</li>
                                    </ul>
                                </Form.Floating>
                                {/* <Form.Floating className="mb-3">
                                    <Form.Control
                                        id="floatingInputCustomPassword2"
                                        type="password"
                                        placeholder="Enter the confirm password"
                                        name='password'
                                        required
                                    />
                                    <label htmlFor="floatingInputCustomPassword">Confirm Password</label>
                                    <ul style={{ lineHeight: "180%", listStyleType: "disc", color: 'green' }}>
                                        <li>Enter the same password as before, for verification.</li>
                                    </ul>
                                </Form.Floating> */}
                                {/* </Row> */}
                                <Row className='mx-1'>
                                    <Button variant="primary" type='submit'>Submit</Button>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
            <ToastContainer />
        </>
    )
}

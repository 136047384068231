import React, { useState } from 'react'
import { Container, Card, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";

export default function ChooseProfile() {

    const [selectedOption, setSelectedOption] = useState('');

    const navigate = useNavigate()

    // const { flag } = useParams();

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'individual') {
            // navigate('/editprofile');
            const isFlag = false;
            navigate(`/editprofile?isFlag=${isFlag}`);
        }
        else{
            const isFlag = false;
            navigate(`/editprofileinstituition?isFlag=${isFlag}`);
        }
    };

    return (
        <Container>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <Card style={{ width: '40rem' }}>
                    <Card.Header style={{ backgroundColor: "#e3f2fd" }}><h5>Choose a profile</h5></Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    // inline
                                    label="Register as an Individual"
                                    name="profile"
                                    value="individual"
                                    type="radio"
                                    checked={selectedOption === 'individual'}
                                    onChange={handleChange}
                                    className="mb-3"
                                />
                                <Form.Check
                                    // inline
                                    label="Register as an Institution"
                                    name="profile"
                                    value="institution"
                                    type="radio"
                                    checked={selectedOption === 'institution'}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                    {/* <Card.Footer>
                        <Button variant="primary" className='float-right' onClick={()=>navigate('/editprofile')}>Continue as {selectedOption}</Button>
                    </Card.Footer> */}
                </Card>
            </div>
        </Container>
    )
}

import React, { useState, useContext, useEffect } from "react";
import './Style.css'
import { db } from "../utils/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  // onSnapshot
} from "firebase/firestore";
import AuthContext from '../context/AuthContext'
// import { useParams } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

const SearchContact = ({ param1 }) => {

  let {
    currentUser,
    userPersonalInfo,
    userPersonalData
  } = useContext(AuthContext)

  // const { param1 } = useParams();

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const param1 = params.get('param1');
  // console.log(param1)

  const [username, setUsername] = useState('');
  const [user, setUser] = useState(null);
  const [paramUser, setParamUser] = useState(null);
  const [err, setErr] = useState(false);
  // const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    console.log('searching', username);
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("name", "==", username), where("connected", "array-contains", currentUser.user_id));
    try {
      const querySnapshot = await getDocs(q);
      console.log('querySnapshot:', querySnapshot);
      querySnapshot.forEach((doc) => {
        setUser(doc.data());
        console.log(doc.id, " => ", doc.data());
      });
    } catch (err) {
      console.log('searching failed. No user found!');
      setErr(true);
    }
  }

  const handleKey = (e) => {
    console.log('enter pressed');
    e.code === 'Enter' && handleSearch();
  };

  const handleSelect = async () => {
    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      currentUser.username > user.uid
        ? currentUser.username + user.uid
        : user.uid + currentUser.username;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));
      // console.log('res', res.data());

      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        //create user chats
        await updateDoc(doc(db, "userChats", currentUser.username), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            name: user.name,
            photoURL: user.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.username,
            name: `${userPersonalData.first_name} ${userPersonalData.last_name}`,
            photoURL: userPersonalData.profile_photo_url,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
        // setUser(null);
        // setUsername("");
      }
      // else {
      //   // const res = await getDoc(doc(db, "userChats", combinedId));
      //   await onSnapshot(doc(db, "userChats", currentUser.username), (doc) => {
      //     // setChats(doc.data());
      //     console.log('doc', doc.data());
      //   });
      // }
    } catch (err) { }

    setUser(null);
    setUsername("")
  };

  const getUser = async (username) => {
    console.log('searching', username);
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("uid", "==", username), where("connected", "array-contains", currentUser.user_id));
    console.log('q:', q);
    try {
      console.log('getuser(), inside try');
      const querySnapshot = await getDocs(q);
      console.log('querySnapshot:', querySnapshot);
      querySnapshot.forEach((doc) => {
        setParamUser(doc.data());
        // console.log('doc.data():', doc.data());
        console.log(doc.id, " => ", doc.data());
        handleSelectDoc(doc.data());
      });
    } catch (err) {
      console.log('searching failed. No user found!');
      setErr(true);
    }
  }

  const handleSelectDoc = async (user) => {
    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      currentUser.username > user.uid
        ? currentUser.username + user.uid
        : user.uid + currentUser.username;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        //create user chats
        await updateDoc(doc(db, "userChats", currentUser.username), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            name: user.name,
            photoURL: user.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.username,
            name: `${userPersonalData.first_name} ${userPersonalData.last_name}`,
            photoURL: userPersonalData.profile_photo_url,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
      // setLoading(true);
    } catch (err) { }

    // setUser(null);
    // setUsername("");
  };

  useEffect(() => {
    userPersonalInfo();
    if (param1) {
      // setUsername(param1);
      getUser(param1);
      // handleSearch();
      console.log('user:', paramUser);
      // handleSelect();
    }
  }, [])

  return (
    <>
      <div id="search">
        <label htmlFor=""><i className="fa fa-search" aria-hidden="true"></i></label>
        <input
          type="text"
          placeholder="Search contacts..."
          onKeyDown={handleKey}
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
      </div>
      {err && <span>User not found!</span>}
      {user &&
        <div id="contacts">
          <ul>
            <li className="contact" onClick={handleSelect}>
              <div className="wrap">
                <span className="contact-status online"></span>
                <img src={user.photoURL} alt="" />
                <div className="meta">
                  <p className="name">{user.name}</p>
                  {/* <p className="preview">You just got LITT up, Mike.</p> */}
                </div>
              </div>
            </li>
          </ul>
        </div>
      }
    </>
  );
}

export default SearchContact;
import React, { useContext, useEffect } from 'react';
import { NetworkContext } from '../context/NetworkContext';
import AuthContext from '../context/AuthContext';
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './css/SideNetworkBar.css';
import { faCalendar, faHandshake, faUserGroup } from '@fortawesome/free-solid-svg-icons';

export const SideNetworkBar = () => {

    let {
        allNews,
        getallNews,
      } = useContext(AuthContext)

    let {
        getNetworkConnections,
        connectedNetworkRequest
    } = useContext(NetworkContext)

    // const [networkConnected, setNetworkConnected] = useState([]);

    useEffect(() => {
        getNetworkConnections();
        getallNews();
        // console.log(networkConnections);
        // if (networkConnections.length > 0) {
        //     const filteredData = networkConnections.filter(item => item.status === 'connected');
        //     setNetworkConnected(filteredData);
        // }
    }, [])

    return (
        // <div className='col-3'>
        //     <main className="d-flex flex-nowrap my-3">
        //         <h1 className="visually-hidden">Sidebars examples</h1>
        //         <div className="d-flex flex-column flex-shrink p-3 text-bg-dark" style={{ width: 250 }}>
        //             <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white">
        //                 <span className="fs-4">Manage my network</span>
        //             </div>
        //             <hr />
        //             <ul className="nav nav-pills flex-column mb-auto">
        //                 <li className="nav-item">
        //                     <NavLink to="/network/connect" className="nav-link text-white" aria-current="page">
        //                         <FontAwesomeIcon
        //                             icon={faHandshake}
        //                             style={{ cursor: 'pointer', marginRight: 10 }}
        //                         />
        //                         Connect
        //                     </NavLink>
        //                 </li>
        //                 <li className="nav-item">
        //                     <NavLink to="/network/connected" className="nav-link text-white" aria-current="page">
        //                         <FontAwesomeIcon
        //                             icon={faUserGroup}
        //                             style={{ cursor: 'pointer', marginRight: 10 }}
        //                         />
        //                         Connections [{connectedNetworkRequest.length}]
        //                     </NavLink>
        //                 </li>
        //                 <li className="nav-item">
        //                     <NavLink to="/network/events" className="nav-link text-white">
        //                         <FontAwesomeIcon
        //                             icon={faCalendar}
        //                             style={{ cursor: 'pointer', marginRight: 10 }}
        //                         />
        //                         Events
        //                     </NavLink>
        //                 </li>
        //             </ul>
        //             <hr />
        //         </div>
        //         <div className="b-example-divider b-example-vr"></div>
        //     </main>
        // </div>
        <div className='d-flex flex-column justify-content-between col bg-dark min-vh-100 my-3'>
            <div className='mt-4'>
                <Link className="text-white d-none d-sm-inline text-decoration-none d-flex align-items-center ms-4">
                    <span className="fs-5">Manage my network</span>
                </Link>
                <hr className='text-white d-none d-sm-block'/>
                <ul className="nav nav-pills flex-column mt-4 mt-sm-0">
                    <li className="nav-item my-sm-1 my-2">
                        <NavLink to="/network/connect" className="nav-link text-white text-center text-sm-start" aria-current="page">
                            <FontAwesomeIcon
                                icon={faHandshake}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            <span className='ms-2 d-none d-sm-inline'>Connect</span>
                        </NavLink>
                    </li>
                    <li className="nav-item my-sm-1 my-2">
                        <NavLink to="/network/connected" className="nav-link text-white text-center text-sm-start" aria-current="page">
                            <FontAwesomeIcon
                                icon={faUserGroup}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            <span className='ms-2 d-none d-sm-inline'>Connections [{connectedNetworkRequest.length}]</span>
                        </NavLink>
                    </li>
                    <li className="nav-item my-sm-1 my-2">
                        <NavLink to="/network/events" className="nav-link text-white text-center text-sm-start">
                            <FontAwesomeIcon
                                icon={faCalendar}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            <span className='ms-2 d-none d-sm-inline'>Events [{allNews.length}]</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div>
                <hr className='text-white'/>
            </div>
        </div>
    )
}

import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRouteAdmin = ({ children }) => {
    // console.log('Private Route Works');
    // const authenticated = false;
    let { user } = useContext(AuthContext)
    return user ? children : <Navigate to="/admin/login" />;
}

export default PrivateRouteAdmin;
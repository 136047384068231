import React, { useContext, useEffect, useState } from 'react';
import { NetworkContext } from '../context/NetworkContext';
import { UserConnections } from './CustomComponents';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

export const Connected = () => {

    let {
        getNetworkConnections,
        connectedNetworkRequest
    } = useContext(NetworkContext)

    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [searchVal, setSearchVal] = useState('');
    const [sortVal, setSortVal] = useState('Recently added');

    const navigate = useNavigate();

    const navigateToProfileWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const renderConnectedCard = connectedNetworkRequest.filter((item) => {
        return searchVal.toLowerCase() === ''
            ? item
            : item.connect_to_user.first_name.toLowerCase().includes(searchVal);
    }).map((item) => {
        return (
            <UserConnections
                item={item}
                onClickMessage={() => navigateToChatWithParams(item.connect_to_user.username)}
                onClickUser={() => navigateToProfileWithParams(item.connect_to_user)}
            />
        );
    })

    const renderSortedCard = connectedNetworkRequest.sort((a, b) => {
        return sortVal === 'First Name'
            ? a.connect_to_user.first_name > b.connect_to_user.first_name ? 1 : -1
            : sortVal === 'Last Name'
                ? a.connect_to_user.last_name > b.connect_to_user.last_name ? 1 : -1
                : a.id < b.id ? 1 : -1;
    }).filter((item) => {
        return searchVal.toLowerCase() === ''
            ? item
            : item.connect_to_user.first_name.toLowerCase().includes(searchVal);
    }).map((item) => {
        return (
            <UserConnections
                item={item}
                onClickMessage={() => navigateToChatWithParams(item.connect_to_user.username)}
            />
        );
    })

    const navigateToChatWithParams = (param1) => {
        navigate(`/messaging?param1=${param1}`);
    };

    useEffect(() => {
        getNetworkConnections();
        setSpinnerLoading(false);
    }, [])

    return (
        <div className='col-9'>
            <div className="card my-3">
                <div className="card-header" style={{ backgroundColor: '#2222e785', fontWeight: 600, color: '#fff', fontSize: 15, }}>
                    <div className="row mb-3">
                        <div className="col">
                            {connectedNetworkRequest.length} connected
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex">
                            {/* <div className="row"> */}
                            <span>Sort by : </span>
                            <form>
                                <select
                                    class="form-select form-select-sm mx-1"
                                    aria-label="Small select example"
                                    value={sortVal}
                                    onChange={(e) => setSortVal(e.target.value)}
                                >
                                    <option value="Recently added" selected>Recently added</option>
                                    <option value="First Name">First Name</option>
                                    <option value="Last Name">Last Name</option>
                                </select>
                            </form>
                            {/* </div> */}
                        </div>
                        <div className="col">
                            <form>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="search contacts"
                                    aria-label="search"
                                    // value={searchVal}
                                    onChange={e => setSearchVal(e.target.value)} />
                            </form>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {spinnerLoading ?
                        <div className='text-center'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                        :
                        <div className="row g-4">
                            {
                                sortVal === 'Recently added'
                                    ? renderConnectedCard
                                    : renderSortedCard
                            }
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

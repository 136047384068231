import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import './css/SideNetworkBar.css';
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';

export default function AdminNavigationPanel({currentUserName}) {
    return (
        <div className='d-flex flex-column justify-content-between col bg-dark min-vh-100 my-1'>
            <div className='mt-4'>
                <Link className="text-white d-none d-sm-inline text-decoration-none d-flex align-items-center ms-4">
                    <span className="fs-5">Welcome {currentUserName},</span>
                </Link>
                <hr className='text-white d-none d-sm-block' />
                <ul className="nav nav-pills flex-column mt-4 mt-sm-0">
                    <li className="nav-item my-sm-1 my-2">
                        <NavLink to="/admin/user" className="nav-link text-white text-center text-sm-start" aria-current="page">
                            <FontAwesomeIcon
                                icon={faUser}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            <span className='ms-2 d-none d-sm-inline'>Users</span>
                        </NavLink>
                    </li>
                    <li className="nav-item my-sm-1 my-2">
                        <NavLink to="/admin/institute" className="nav-link text-white text-center text-sm-start">
                            <FontAwesomeIcon
                                icon={faBuilding}
                                style={{ cursor: 'pointer', marginRight: 10 }}
                            />
                            <span className='ms-2 d-none d-sm-inline'>Institutions</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div>
                <hr className='text-white' />
            </div>
        </div>
    )
}

import React, { useContext, useState } from 'react';
import { SideNetworkBar } from './SideNetworkBar';
import Navbar from './Navbar';
import { Connected } from './Connected';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

export default function NetworkConnected() {

  let {
    currentUser
  } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const navigate = useNavigate();

  const navigateToSearchDataWithParams = (item) => {
    if (item.account_type === 'user')
      navigate(`/user/viewprofile?user_id=${item.id}`);
    else
      navigate(`/institute/viewprofile?institute_id=${item.id}`);
  };

  const navigateToSearchAllWithParams = (item) => {
    console.log('navigateToSearchAllWithParams');
    console.log('Search Data:', item);
    navigate("/searchall", { state: { item } });
  };

  return (
    <>
      <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} profileType={currentUser.account_type} />
      {
        loading &&
        <div className='overlay'>
          <div className="container">
            <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
              <ul className='content-list' style={{ padding: 10 }}>
                {
                  searchData.length > 0 ?
                    searchData.slice(0, 3).map((item) => (
                      <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                        {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                      </li>
                    ))

                    : <li className='list-element'>No data found</li>
                }
                {searchData.length > 0 ?
                  <>
                    <hr />
                    <li className='see-all-btn'>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ width: 280 }}
                        onClick={() => navigateToSearchAllWithParams(searchData)}
                      >
                        See all
                      </button>
                    </li>
                  </> :
                  null}
              </ul>
            </div>
          </div>
        </div>
      }

      <div className='container'>
        <div className='row'>
          {/* <main className="d-flex flex-nowrap my-3"> */}
          <SideNetworkBar />
          <Connected />
          {/* </main> */}
        </div>
      </div>
    </>
  )
}

import React from 'react'

export default function InstituteProfile({
    website,
    clubHistoryList
}) {
    return (
        <div>
            <h3 className='mx-3 my-3'><b>Institute</b></h3>

            <div className='mx-3 my-3'>
                <div>
                    <b>Website:</b> {website}
                </div>
            </div>

            <div className='ClubHistory mx-3 my-3'>
                <h4><b>Statistics</b></h4>
                <div className='table-responsive'>
                    <table className="table table-sm table-light table-striped table-hover table-bordered mt-3">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">Period</th>
                                <th scope="col">League</th>
                                <th scope="col">Games Played</th>
                                <th scope="col">Games Won</th>
                                <th scope="col">Games Tied</th>
                                <th scope="col">Games Lost</th>
                                <th scope="col">Tournament</th>
                                <th scope="col">Points</th>
                                <th scope="col">Position</th>
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {clubHistoryList}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

import React, { useState, useContext, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext'
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal } from 'react-bootstrap';
import AdminNavigationPanel from '../../components/AdminNavigationPanel';
import { SEARCHALLUSER_URL, SEARCHINSTITUITION_URL, USER_URL } from '../../services/Constants';
import { faEdit, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavbarAdmin from '../../components/NavbarAdmin';

export default function AdminInstitute() {

    let {
        user,
        authTokens,
        userList,
        getUserList,
        notifyError,
        notifyAuthenticateError,
        notifyRegistrationPersonalInfo
    } = useContext(AuthContext)

    const navigate = useNavigate();

    // declaration of state varibles

    const [key, setKey] = useState('basic');
    const [keyBasic, setKeyBasic] = useState('general');
    const [keyStatitic, setKeyStatitic] = useState('general');

    // State variable of User address

    const [permanentAddress, setPermanentAddress] = useState('');
    // const [pmAddressId, setPmAddressId] = useState(null);
    const [presentAddress, setPresentAddress] = useState([]);

    // State variable used to manage behaviour of Modal

    const [showUserInfoModal, setShowUserInfoModal] = useState(false);

    // State variable used for loading of page

    const [spinnerLoading, setSpinnerLoading] = useState(false);

    // State variable of Institute Information

    const [instituteData, setInstituteData] = useState([]);
    const [institutePersonalInfo, setInstitutePersonalInfo] = useState('');
    const [searchUserInfo, setSearchUserInfo] = useState([]);

    // State variable for managing club info

    const [clubInfo, setClubInfo] = useState('')
    const [clubLicense, setClubLicense] = useState([])
    const [clubOfficials, setClubOfficials] = useState([])
    const [clubHistory, setClubHistory] = useState([])

    // function responsible for handling navigation with params

    const navigateToSearchDataWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    // handle on click update 

    // handle update function

    const handleOnSubmit = async () => {
        // event.preventDefault();
        // setValidatedAboutMe(true)
        // setIsLoading(true)
        // const formData = new FormData();
        let response = fetch(`${USER_URL}/${institutePersonalInfo.id}/`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: JSON.stringify(
                {
                    "is_flag": true
                }
            )
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            notifyRegistrationPersonalInfo()
            setShowUserInfoModal(false)
            spinnerLoading(true)
        } else {
            notifyError()
        }
    }

    // handle modal close

    const handleUserInfoModalClose = () => {
        setShowUserInfoModal(false);
    }

    // on change function

    const handleOnClickSearchUserInfo = async () => {
        // setSearchUserInfo(e.target.value);
        setSpinnerLoading(true);
        let COMPLETE_URL;
        let apiEndPoint;
        if (searchUserInfo !== '') {
            apiEndPoint = 'user__full_name=' + searchUserInfo;
            COMPLETE_URL = SEARCHINSTITUITION_URL + apiEndPoint;
            let response = fetch(`${COMPLETE_URL}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            // console.log('response:', response)
            const data = (await response).json()
            // console.log('data:', data)
            const status = (await response).status
            // console.log('status:', status)
            if (status === 200) {
                const filteredData = await data;
                console.log('list of filtered user:', filteredData);
                setInstituteData(filteredData);
            } else {
                setInstituteData([]);
            }
        } else {
            setInstituteData([]);
            // getUserList();
            setSpinnerLoading(false);
        }
    }

    // Get user personal info

    const getInstituteInfo = async (id) => {
        // e.preventDefault();
        console.log('user Personal Information');
        let response = fetch(`${USER_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json()
        console.log('data:', data)
        console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const personalInfo = (await data)
            console.log('personalInfo: ', personalInfo)
            setInstitutePersonalInfo(personalInfo)
            // setClubName(personalInfo.club_name)
            // setFounded(personalInfo.dob)
            // setContactNo(personalInfo.contact_no)
            if (await personalInfo.permanent_address.length > 0) {
                const pmAddress = (await personalInfo.permanent_address[0])
                console.log('Profile, permenent address: ', pmAddress)
                if (pmAddress !== '') {
                    setPermanentAddress(pmAddress)
                    // setPmAddressId(pmAddress.id)
                } else {
                    setPermanentAddress('')
                }
            } else {
                console.log('Profile, permenent address: ', await personalInfo.permanent_address)
                setPermanentAddress('')
                // setPmAddressId(null)
            }
            const psAddress = (await personalInfo.present_address)
            setPresentAddress(psAddress)
            // const sportProfileType = (await personalInfo.sport_profile_type)
            // sportProfileType.map(item => {
            //     if (item.profile_type === 'Institution') {
            //         if (item.is_active === true)
            //             setSelectedSwitchOption(true)
            //         else
            //             setSelectedSwitchOption(false)
            //     }
            // })
            const club = (await personalInfo.club)
            console.log('club info: ', club)
            if (club.length > 0) {
                setClubInfo(club[0])
                console.log('club details: ', club[0])
                const license = (await club[0].verification_document)
                console.log('license: ', license)
                setClubLicense(license)
                const officials = (await club[0].office_bearer)
                console.log('officials: ', officials)
                setClubOfficials(officials)
                const history = (await club[0].club_history)
                console.log('club history: ', history)
                setClubHistory(history)
                // getClubLicense()
            }
        } else if (status === 401) {
            notifyAuthenticateError();
        } else {
            notifyError();
        }
        // setIsAddressChanged(false);
    }

    // on page load

    useEffect(() => {
        getUserList();
    }, [spinnerLoading]);

    const allInstituteList = userList.map((item) => {
        if (item.account_type === 'institute' && item.is_flag === false)
            return (
                <tr key={item.id}>
                    <td>
                        <FontAwesomeIcon
                            icon={faEdit}
                            color='#2222e785'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                getInstituteInfo(item.id);
                                setShowUserInfoModal(true);
                            }}
                        />
                    </td>
                    <td onClick={() => navigateToSearchDataWithParams(item)} className='table-item'>
                        {item.club_name}
                    </td>
                    <td>{item.email}</td>
                    <td>{item.contact_no}</td>
                    <td>{item.dob}</td>
                    <td>{'Pending'}</td>
                </tr>
            );
    })

    const filteredInstituteList = instituteData.map((item) => {
        return (
            <tr key={item.id}>
                <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getInstituteInfo(item.user.id);
                            setShowUserInfoModal(true);
                        }}
                    />
                </td>
                <td onClick={() => navigateToSearchDataWithParams(item.user)} className='table-item'>
                    {item.user.club_name}
                </td>
                <td>{item.user.email}</td>
                <td>{item.user.contact_no}</td>
                <td>{item.user.dob}</td>
                <td>{item.user.is_flag ? 'Approved' : 'Pending'}</td>
            </tr>
        );
    })

    const presentAddressList = presentAddress.map((address) => {
        return (
            <tr key={address.id}>
                {/* <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getPresentAddressInfo(address.id);
                            setShowEditAddressModal(true);
                        }}
                    />
                </td> */}
                <td>{address.address_lane}</td>
                <td>{address.landmark}</td>
                <td>{address.city}</td>
                <td>{address.pin}</td>
                <td>{address.state}</td>
                <td>{address.country}</td>
                <td>{address.address_type}</td>
                {/* <td>
                    <Form>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            // checked={addressStatus}
                            onChange={() => handleChangePresentAddress(address.id)}
                        />
                    </Form>
                </td> */}
                {/* <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(address.id)}
                    />
                </td> */}
            </tr>
        );
    })

    const clubLicenseList = clubLicense.map((license) => {
        const words = license.document_file.split("/");
        return (
            <tr key={license.id}>
                {/* <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getClubLicenseInfo(license.id);
                            setShowEditLicenselModal(true);
                        }}
                    />
                </td> */}
                <td>{license.document_name}</td>
                {/* <td>
                    {license.document_file !== null ?
                        <img src={license.document_file} alt={license.document_name} style={{ maxWidth: '50px' }} />
                        : null
                    }
                </td> */}
                <td>
                    <a href={license.document_file} download="downloadedFile.pdf">
                        {words[5]}
                    </a>
                </td>
                {/* <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClubDocumentDelete(license.id)}
                    />
                </td> */}
            </tr>
        );
    })

    const clubOfficialsList = clubOfficials.map((official) => {
        return (
            <tr key={official.id}>
                {/* <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getClubOfficialInfo(official.id);
                            setShowEditOfficialsModal(true);
                        }}
                    />
                </td> */}
                <td>{official.name}</td>
                <td>{official.position}</td>
                <td>{official.department}</td>
                <td>{official.email}</td>
                <td>{official.phone_number}</td>
                {/* <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClubOfficialDelete(official.id)}
                    />
                </td> */}
            </tr>
        );
    })

    const clubCareerHistoryList = clubHistory.map((history) => {
        return (
            <tr key={history.id}>
                {/* <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getClubCareerHistoryInfo(history.id);
                            setShowEditCareerHistoryModal(true);
                        }}
                    />
                </td> */}
                <td>{history.from_year}</td>
                <td>{history.to_year}</td>
                <td>{history.league_name}</td>
                <td>{history.games_played}</td>
                <td>{history.points}</td>
                <td>{history.position}</td>
                {/* <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClubHistoryDelete(history.id)}
                    />
                </td> */}
            </tr>
        );
    })

    const personalAchievementList = clubHistory.map((club) => {
        const parsedData = club.achievement !== null && club.achievement !== '' ? club.achievement.split('\n') : null;
        // console.log('parsedData', parsedData);
        if (club.achievement !== null && club.achievement !== '')
            return (
                <tr key={club.id}>
                    <td>{club.from_year}</td>
                    <td>{club.to_year}</td>
                    {/* <td>{club.achievement}</td> */}
                    <td>
                        {parsedData && parsedData.length > 0 &&
                            <ul>
                                {parsedData.map((item, index) => (
                                    <li key={index} className='card-text'>{item}</li>
                                ))}
                            </ul>
                        }
                    </td>
                </tr>
            );
    })

    return (
        <>

            <Modal show={showUserInfoModal} onHide={handleUserInfoModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Instituite's Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='mt-3'>
                        <Card.Body>
                            {/* <h2 className='text-center'>Personal Information</h2> */}
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"
                            >
                                <Tab eventKey="basic" title="BASIC INFORMATION">
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={keyBasic}
                                        onSelect={(k) => setKeyBasic(k)}
                                        className="mb-3"
                                        fill
                                        variant='underline'
                                    >
                                        <Tab eventKey="general" title="GENERAL">
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Institute Information</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                {/* <th>Edit</th> */}
                                                                <th>Club Name</th>
                                                                <th>Email</th>
                                                                <th>Contact No</th>
                                                                <th>Founded</th>
                                                                <th>Profile Photo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {/* <td>
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        color='#2222e785'
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getUserPersonalInfo(userPersonalData.id);
                                                                            setShowEditPersonalModal(true);
                                                                        }}
                                                                    />
                                                                </td> */}
                                                                <td>{institutePersonalInfo.club_name}</td>
                                                                <td>{institutePersonalInfo.email}</td>
                                                                <td>{institutePersonalInfo.contact_no}</td>
                                                                <td>{institutePersonalInfo.dob}</td>
                                                                <td>
                                                                    {institutePersonalInfo.profile_photo !== null ?
                                                                        <img src={institutePersonalInfo.profile_photo} alt={institutePersonalInfo.club_name} style={{ maxWidth: '50px' }} />
                                                                        : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab>

                                        <Tab eventKey="aboutme" title="ABOUT ME">
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Summary</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                {/* <th>Edit</th> */}
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {/* <td>
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        color='#2222e785'
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getUserPersonalInfo(userPersonalData.id);
                                                                            setShowEditProfileDescriptionModal(true);
                                                                        }}
                                                                    />
                                                                </td> */}
                                                                {institutePersonalInfo.bio !== null ?
                                                                    <td>{institutePersonalInfo.bio}</td> :
                                                                    <td className='text-center' colSpan={1}>
                                                                        No data found
                                                                    </td>
                                                                }
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab>

                                        <Tab eventKey="addresses" title="ADDRESSES">
                                            <div style={{ height: '350px', overflowY: 'scroll' }}>
                                                <Card>
                                                    <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Permanent Address</Card.Header>
                                                    <Card.Body>
                                                        <Table striped responsive bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>Edit</th> */}
                                                                    <th>Address Lane</th>
                                                                    <th>Landmark</th>
                                                                    <th>City</th>
                                                                    <th>Zip Code</th>
                                                                    <th>State</th>
                                                                    <th>Country</th>
                                                                    <th>Address Type</th>
                                                                    {/* <th>Delete</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {permanentAddress !== '' ?
                                                                    <tr>
                                                                        {/* <td>
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                color='#2222e785'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    getAddressInfo(permanentAddress.id);
                                                                                    setShowEditAddressModal(true);
                                                                                }}
                                                                            />
                                                                        </td> */}
                                                                        <td>{permanentAddress.address_lane}</td>
                                                                        <td>{permanentAddress.landmark}</td>
                                                                        <td>{permanentAddress.city}</td>
                                                                        <td>{permanentAddress.pin}</td>
                                                                        <td>{permanentAddress.state}</td>
                                                                        <td>{permanentAddress.country}</td>
                                                                        <td>{permanentAddress.address_type}</td>
                                                                        {/* <td>
                                                                            <FontAwesomeIcon
                                                                                icon={faTrashCan}
                                                                                color='red'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleUpdatePermanentAddress(permanentAddress.id)}
                                                                            />
                                                                        </td> */}
                                                                    </tr>
                                                                    : <tr>
                                                                        <td className='text-center' colSpan={9}>
                                                                            No data found
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>

                                                <Card>
                                                    <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Other Addresses</Card.Header>
                                                    <Card.Body>
                                                        <Table striped responsive bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>Edit</th> */}
                                                                    <th>Address Lane</th>
                                                                    <th>Landmark</th>
                                                                    <th>City</th>
                                                                    <th>Zip Code</th>
                                                                    <th>State</th>
                                                                    <th>Country</th>
                                                                    <th>Address Type</th>
                                                                    {/* <th>Mark as Permanent</th> */}
                                                                    {/* <th>Delete</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    presentAddress && presentAddress.length > 0 ?
                                                                        presentAddressList
                                                                        :
                                                                        <tr>
                                                                            <td className='text-center' colSpan={7}>
                                                                                No data found
                                                                            </td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Tab>
                                <Tab eventKey="statistic" title="STATISTICAL INFORMATION">
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={keyStatitic}
                                        onSelect={(k) => setKeyStatitic(k)}
                                        className="mb-3"
                                        justify
                                        variant='underline'
                                    >
                                        <Tab eventKey="general" title="GENERAL">
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Institute Information</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                {/* <th>Edit</th> */}
                                                                <th>Institution Name</th>
                                                                <th>Website</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clubInfo !== '' ?
                                                                <tr>
                                                                    {/* <td>
                                                                        <FontAwesomeIcon
                                                                            icon={faEdit}
                                                                            color='#2222e785'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                getClubInfo(clubInfo.id);
                                                                                setShowEditGeneralModal(true);
                                                                            }}
                                                                        />
                                                                    </td> */}
                                                                    <td>{institutePersonalInfo.club_name}</td>
                                                                    <td>{clubInfo.website}</td>
                                                                </tr>
                                                                :
                                                                <tr>
                                                                    <th colSpan={3} className='text-center'>
                                                                        No rows found
                                                                    </th>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Tab>

                                        <Tab eventKey="license" title="VERIFICATION DOCUMENT">
                                            <div style={{ height: '350px', overflowY: 'scroll' }}>
                                                <Card>
                                                    <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Documents</Card.Header>
                                                    <Card.Body>
                                                        <Table striped responsive bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>Edit</th> */}
                                                                    <th>Document Name</th>
                                                                    <th>Certificate</th>
                                                                    {/* <th>Delete</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {clubLicense && clubLicense.length > 0 ? clubLicenseList :
                                                                    <tr>
                                                                        <th colSpan={5} className='text-center'>
                                                                            No rows found
                                                                        </th>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="officials" title="OFFICIALS">
                                            <div style={{ height: '350px', overflowY: 'scroll' }}>
                                                <Card>
                                                    <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Institute Officials</Card.Header>
                                                    <Card.Body>
                                                        <Table striped responsive bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>Edit</th> */}
                                                                    <th>Person Name</th>
                                                                    <th>Position</th>
                                                                    <th>Department</th>
                                                                    <th>Email</th>
                                                                    <th>Contact Number</th>
                                                                    {/* <th>Delete</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {clubOfficials && clubOfficials.length > 0 ? clubOfficialsList :
                                                                    <tr>
                                                                        <th colSpan={7} className='text-center'>
                                                                            No rows found
                                                                        </th>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="careerhistory" title="CAREER HISTORY">
                                            <div style={{ height: '350px', overflowY: 'scroll' }}>
                                                <Card>
                                                    <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Career Histories</Card.Header>
                                                    <Card.Body>
                                                        <Table striped responsive bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>Edit</th> */}
                                                                    <th>From Year</th>
                                                                    <th>To Year</th>
                                                                    <th>League Name</th>
                                                                    <th>Games Played</th>
                                                                    <th>Points</th>
                                                                    <th>Position</th>
                                                                    {/* <th>Delete</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {clubHistory && clubHistory.length > 0 ? clubCareerHistoryList :
                                                                    <tr>
                                                                        <th colSpan={8} className='text-center'>
                                                                            No rows found
                                                                        </th>
                                                                    </tr>}
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>

                                                <Card>
                                                    <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Achievements</Card.Header>
                                                    <Card.Body>
                                                        <Table striped responsive bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>From Year</th>
                                                                    <th>To Year</th>
                                                                    <th>Achievement Name</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {clubHistory && clubHistory.length > 0 ? personalAchievementList :
                                                                    <tr>
                                                                        <th colSpan={3} className='text-center'>
                                                                            No rows found
                                                                        </th>
                                                                    </tr>}
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUserInfoModalClose}>Close</Button>
                    {!institutePersonalInfo.is_flag &&
                        <Button variant="primary" onClick={handleOnSubmit}>Approve</Button>
                    }
                </Modal.Footer>
            </Modal>

            <NavbarAdmin />

            <Container>
                <Row>
                    <AdminNavigationPanel currentUserName={user.first_name + ' ' + user.last_name} />

                    <Col xs={9}>
                        <Card className='mt-1'>
                            <Card.Body>
                                <h2 className='text-center'>Institutes</h2>
                                {/* <div style={{ height: '400px', overflowY: 'scroll' }}> */}
                                <Card>
                                    <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                List of Institutes
                                            </div>
                                            <div>
                                                <Form.Group as={Col}>
                                                    <Stack direction="horizontal" gap={2}>
                                                        <Form.Control
                                                            className='input'
                                                            type="text"
                                                            size='lg'
                                                            placeholder="Enter the institute's name or email"
                                                            value={searchUserInfo}
                                                            // onChange={handleOnChangeSearchUserInfo}
                                                            onChange={(e) => setSearchUserInfo(e.target.value)}
                                                        />
                                                        <Button variant="primary" size="lg" onClick={handleOnClickSearchUserInfo}>
                                                            <FontAwesomeIcon
                                                                icon={faMagnifyingGlass}
                                                                // color='green'
                                                                // size = '2x'
                                                                style={{ cursor: 'pointer', marginRight: 5 }}
                                                            />
                                                        </Button>
                                                    </Stack>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body style={{ height: '360px', overflowY: 'scroll' }}>
                                        {/* <div style={{ height: '400px', overflowY: 'scroll' }}> */}
                                        <Table striped responsive bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Edit</th>
                                                    <th>Club Name</th>
                                                    <th>Email</th>
                                                    <th>Contact No.</th>
                                                    <th>Established Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!spinnerLoading ?
                                                    allInstituteList :
                                                    filteredInstituteList
                                                }
                                            </tbody>
                                        </Table>
                                        {/* </div> */}
                                    </Card.Body>
                                </Card>
                                {/* </div> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer autoClose={2000} />
        </>
    )
}

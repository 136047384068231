import React, { useContext, useState } from 'react'
import { UserSearch } from '../components/CustomComponents';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../context/AuthContext'
import { handleNetworkConnectSubmit } from '../services/ApiServices';
import Navbar from '../components/Navbar';

export default function SearchAll() {

    let { currentUser } = useContext(AuthContext)

    const navigate = useNavigate();

    const location = useLocation();

    const myArray = location.state.item;

    // State variable of search user profile in Navbar

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    // function responsible of search data with navigation

    const navigateToSearchDataWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    // const navigateToProfileWithParams = (item) => {
    //     console.log('navigateToProfileWithParams');
    //     console.log('Search Data:', item);
    //     navigate(`/user/viewprofile?user_id=${item.id}`);
    // };

    const navigateToChatWithParams = (param1) => {
        // console.log('Hello')
        // navigate(`/messaging?${param1}`);
        navigate(`/messaging?param1=${param1}`);
        // navigate(`/messaging`);
    };

    return (
        <>
            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} profileType={currentUser.account_type} />
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null}
                            </ul>
                        </div>
                    </div>
                </div>
            }

            <div className='container'>
                <h5 className='my-2'>{myArray.length} people found</h5>
                {/* <div className="card my-3"> */}
                    {/* <div className="card-header" style={{ backgroundColor: '#2222e785', fontWeight: 600, color: '#fff', fontSize: 15, }}>
                    <div className="row">
                        <div className="col">
                            {myArray.length} people found
                        </div>
                    </div>
                </div> */}
                    {/* <div className="card-body p-4"> */}
                        <div className="row">
                            {myArray.map((item) => (
                                <UserSearch
                                    key={item.id}
                                    item={item}
                                    currentUser={currentUser}
                                    onClickUser={() => navigateToSearchDataWithParams(item)}
                                    onClickMessage={() => navigateToChatWithParams(item.username)}
                                    onClickConnect={() => handleNetworkConnectSubmit(item, currentUser)}
                                />
                            ))}
                        </div>
                    {/* </div> */}
                {/* </div> */}
            </div>
        </>
    )
}

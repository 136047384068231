import React, { useContext, useEffect, useState } from 'react'
import "./css/style.css"
import AuthContext from '../../context/AuthContext';
import { Spinner } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

export default function AdminLogin() {

    let { loginAdmin } = useContext(AuthContext);

    const navigate = useNavigate()

    const [spinnerLoading, setSpinnerLoading] = useState(false);

    const onLoginUser = (e) => {
        setSpinnerLoading(true);
        loginAdmin(e);
        setTimeout(() => {
            setSpinnerLoading(false);
        }, 1000);
    }

    useEffect(() => {
        const token = localStorage.getItem('authTokens');
        if (token)
          navigate('/admin/user');
        else
          navigate('/admin/login');
      }, [])

    return (
        <>
            <div className='d-flex justify-content-around align-items-center'>
                <div className="login-box">
                    {spinnerLoading &&
                        <div className='text-center mt-3'>
                            <Spinner animation="border" />
                        </div>
                    }
                    <form onSubmit={onLoginUser}>
                        <div className="login-header">
                            <header>Login</header>
                        </div>
                        <div className="input-box">
                            <input
                                type="text"
                                className="input-field"
                                placeholder="Email"
                                autocomplete="off"
                                name="email"
                                required
                            />
                        </div>
                        <div className="input-box">
                            <input
                                type="password"
                                className="input-field"
                                placeholder="Password"
                                autocomplete="off"
                                name="password"
                                required
                            />
                        </div>
                        <div className="forgot">
                            {/* <section>
                    <input type="checkbox" id="check"/>
                        <label for="check">Remember me</label>
                </section> */}
                            <section>
                                <link to="#" />Forgot password
                            </section>
                        </div>
                        <div className="input-submit">
                            <button className="submit-btn" id="submit" type='submit' disabled={spinnerLoading}></button>
                            <label for="submit">Sign In</label>
                        </div>
                        {/* <div className="sign-up-link">
                <p>Don't have account? <a href="#">Sign Up</a></p>
            </div> */}
                    </form>
                </div>
                <div className="admin-box">
                    <img src="/image/admin-icon.png" alt='admin'/>
                </div>
            </div>
            <ToastContainer autoClose={2000} />
        </>
    )
}
